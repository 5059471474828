import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import CompanyImage from '../../../assets/images/Company.png'
import CustomCard from 'components/atoms/Card'

const ModelCard = () => {
  return (
    <CustomCard>
      <Box display='flex' gap={1} justifyContent='space-between'>
        <Box display='flex' flexDirection='column' gap={1}>
          <Typography variant='h6' style={{ color: '#747474' }}>
            Ather 450X
          </Typography>
          <Typography variant='subtitle2'>3.9k Wh Battery</Typography>
        </Box>
        <img
          style={{ height: '30px', paddingRight: '25px', paddingTop: '15px' }}
          src={CompanyImage}
          alt='Company Logo'
        />
      </Box>
      <Box my={3} display='flex' justifyContent='space-between'>
        <Typography variant='subtitle2'>on road price in Banglore</Typography>
        <Typography variant='subtitle2'>₹ 1 61 065</Typography>
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Link variant='subtitle2'>View Spec</Link>
        <Link variant='subtitle2'>Select</Link>
      </Box>
    </CustomCard>
  )
}

export default ModelCard
