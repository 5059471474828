import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import useNotEligible from 'containers/loanNotEligible/useNotEligible'

export default function ReferAFriend() {
  const { mobile } = useNotEligible()

  return (
    <Box
      maxWidth={mobile ? '100%' : '35rem'}
      flexDirection={mobile ? 'column' : 'row'}
      margin={mobile ? '1rem' : '0'}
      display='flex'
      justifyContent='space-between'
      gap={6.25}
      color='#FFF'
      p={4}
      sx={{
        background: 'linear-gradient(37deg, #00A99D 3.89%, #B0D456 99.09%)',
        borderRadius: 2.5,
      }}
    >
      <Typography variant='h2'>Share EV Loan Power</Typography>
      <Box>
        <Typography>Refer a friend and both of you win with exclusive benefits.</Typography>
        <Button
          sx={{
            background: '#FFF',
            mt: '1.5rem',
            px: 2,
            color: '#00655E',
            fontFamily: "'IBM Plex Sans', sans-serif",
          }}
        >
          Refer a friend
        </Button>
      </Box>
    </Box>
  )
}
