import React from 'react'
import { routes } from 'constants/routes'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from 'assets/images/Logo.svg'
import useMobileDevice from 'hooks/useMobileDevice'

export default function AppLogo() {
  const location = useLocation()
  const fixedLogoPages = [routes.HOME, '/apply/test-ride-confirmation/:id']
  const mobile = useMobileDevice()
  const navigate = useNavigate()

  function getPosition(): 'absolute' | 'fixed' {
    if(location.pathname === routes.HOME) {
      return 'absolute'
    } else if(location.pathname.includes('test-ride-confirmation')) {
      return 'absolute'
    } else {
      return 'fixed'
    }
  }


  return (
    <>
      {window.location.pathname === '/something-went-wrong' ? null : (
        <img
          src={Logo}
          alt='details'
          style={{
            position: mobile ? 'absolute' : getPosition(),
            top: mobile ? '3rem' : '4rem',
            left: mobile ? '50%' : '7rem',
            transform: 'translate(-50%, -50%)',
            width: '4rem',
            height: '4rem',
            zIndex: 200,
            cursor: 'pointer'
          }}
          onClick={() => navigate(routes.HOME)}
        />
      )}
    </>
  )
}