import React from 'react'
import { Box, Card, Typography } from '@mui/material'
import CheckSvg from 'assets/images/check.svg'
import EditSvg from 'assets/images/edit.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { theme } from 'muiTheme/customTheme'

interface Props {
  state: number
  data: {
    address: string
    dealerId: number
    dealerName: string
  }
  setIsEditable?: any
  setCheckedDealer: any
}

const DealerCard = ({ state, data, setCheckedDealer, setIsEditable }: Props) => {
  const ipad = useMediaQuery(theme.breakpoints.down('md'))
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  //  State = 0 -> Default, State = 1 -> Checked, State 2 - Editable
  return (
    <Card
      sx={{
        padding: '20px 25px',
        border: state == 2 ? '0' : `1px solid ${state == 0 ? '#DADADA' : '#00A99D'}`,
        width: mobile ? '83%' : ipad ? '92%' : '320px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        background: state == 2 ? '#E5F6F5' : '#FFFFFF',
        borderBottom:
          state == 2 ? '1px solid #00A99D' : `1px solid ${state == 0 ? '#DADADA' : '#00A99D'}`,
        cursor: state == 0 ? 'pointer' : 'auto',
        boxShadow: 'none',
        mb: 1
      }}
      onClick={() => (state == 0 ? setCheckedDealer(data?.dealerId) : {})}
    >
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='bodyStrong' color={state != 0 ? 'primary' : 'text'}>
          {data?.dealerName}
        </Typography>
        {state != 0 && (
          <img
            src={state == 1 ? CheckSvg : EditSvg}
            onClick={() => (state == 1 ? {} : setIsEditable(true))}
            alt='selected'
            style={{
              width: '14px',
              height: '14px',
              cursor: state == 2 ? 'pointer' : 'auto',
            }}
          />
        )}
      </Box>
      <Typography
        mt={'6px'}
        color={state != 0 ? 'primary' : 'text'}
        variant='caption'
        sx={{ opacity: 0.8 }}
        component='span'
      >
        {data?.address}
      </Typography>
    </Card>
  )
}

export default DealerCard
