import React from 'react'
import { Box, Dialog, Drawer, Card, Typography } from '@mui/material'
import useMobileDevice from 'hooks/useMobileDevice'
import closeIcon from 'assets/images/close icon.svg'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  children: JSX.Element
  padding: number
}

const boxStyle = {
  position: 'absolute',
  top: '8%',
  left: '61.5%',
  zIndex: -1,
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '25px',
  bgcolor: 'background.paper',
  width: 360,
  boxShadow: '0px 10px 24px 0px rgba(0, 169, 157, 0.10)',
  border: '1px solid rgba(81, 179, 167, 0.20)',
  maxHeight: '70vh',
  overflowY: 'auto'
}

const mobileStyle = {
  borderRadius: '25px',
  bgcolor: 'background.paper',
  //width: 300,
  boxShadow: '0px 10px 24px 0px rgba(0, 169, 157, 0.10)',
  border: '1px solid rgba(81, 179, 167, 0.20)',
  marginTop: '20px',
  //p: 4,
  m: 2,
}

const CustomModal = ({ open, setOpen, children, padding }: Props) => {
  const mobile = useMobileDevice()
  return (
    <>
      {!mobile ? (
        <div style={{ position: 'relative' }}>
          <Dialog
            open={open}
            PaperProps={{
              sx: {
                maxWidth: '400px',
                maxHeight: '80vh',
                borderRadius: 5,
                overflow: 'visible'
              }
            }}
          >              
            <Box p={padding} sx={{maxHeight: '100vh', overflowY: 'auto'}}>
              <Box position='absolute' top='-3.25rem' right='1rem'>
                <img
                  src={closeIcon}
                  alt='close icon'
                  onClick={() => setOpen(false)}
                  style={{
                    width: '2rem',
                    height: '2rem',
                    marginBottom: '10%',
                    borderBottom: 'none',
                    cursor: 'pointer',
                  }}
                />
              </Box>
              {children}
            </Box>
          </Dialog>
        </div>
      ) : (
        <Drawer
          open={open}
          anchor={mobile ? 'bottom' : 'right'}
          onClose={() => setOpen(false)}
          sx={{
            mt: mobile ? 14.25 : 0,
          }}
          PaperProps={{
            sx: {
              background: 'linear-gradient(155deg, #082C29 -11.66%, #000 52.77%)',
              backdropFilter: 'blur(30px)',
              borderRadius: mobile ? '1.25rem 1.25rem 0 0' : '1.25rem 0 0 1.25rem',
              width: mobile ? '100%' : '450px',
            },
          }}
        >
          <Box display='flex' justifyContent='end' alignItems='center'>
            <img
              src={closeIcon}
              alt='close icon'
              onClick={() => setOpen(false)}
              style={{
                width: '2rem',
                height: '2rem',
                marginRight: mobile ? '1rem' : '2rem',
                marginTop: '1.5rem',
                cursor: 'pointer',
              }}
            />
          </Box>
          <Box sx={mobileStyle}>{children}</Box>
        </Drawer>
      )}
    </>
  )
}

export default CustomModal
