import axios from 'axios'
import { API_TIMEOUT } from '../constants/config'
import { ERROR_SOMETHING_WENT_WRONG } from '../constants/constants'
import { errorToast, successToast } from './toast'
import { routes } from 'constants/routes'
import { apiRoutes } from 'constants/apiRedirectConstant'

const axiosConfig = {
  // eslint-disable-next-line prettier/prettier
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: API_TIMEOUT,
}

/**
 * Create an Axios Client for Auth Module
 */
const request = axios.create(axiosConfig)

request.interceptors.request.use(
  async (apiConfig: any) => {
    const tokenData = localStorage.getItem('token')
    const guestToken = localStorage.getItem('guestToken')
    const config = apiConfig ? apiConfig : {}

    const cancelController = new AbortController()
    if (!config.signal) {
      config.signal = cancelController.signal
    }

    try {
      config['headers']['Content-Type'] = 'application/json'
      if (tokenData) {
        config['headers']['Authorization'] = tokenData
      } else if (guestToken) {
        config['headers']['Authorization'] = guestToken
      }
    } catch (error) {
      cancelController.abort()
      return Promise.reject(error)
    }
    return config
  },
  (error) => Promise.reject(error),
)

request.interceptors.response.use(
  function (response) {
    //successToast(response?.data?.message)
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response
  },
  function (error) {
    if (
      error.response?.data?.status == 401 ||
      error.response?.data?.code == 401
      // && error.response.data.errorCode == 11
    ) {
      window.location.href = routes.HOME
      localStorage.clear()
      errorToast(error.response?.data?.message)
    } else if (
      error.response?.data?.status === 500 ||
      error.response?.data?.status === 503 ||
      error.response?.data?.code === 422
    ) {
      window.location.href = error.response?.data?.data?.screenName
        ? apiRoutes[error.response?.data?.data?.screenName]
        : routes.SOMETHING_WRONG
      errorToast(error.response?.data?.error)
    } else {
      errorToast(error.response?.data?.message)
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(error.response?.data || ERROR_SOMETHING_WENT_WRONG)
  },
)

export default request
