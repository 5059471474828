import React, { Fragment } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import usePlanOption from './usePlanOption'
import Slider from 'react-slick'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import Footer from 'components/organisms/footer'
import ReactGA from 'react-ga4'
import EditIcon from 'assets/images/edit.svg'
import EditLoanDrawer from 'components/organisms/editLoan/editLoanDrawer'
import UpgradeOffersModal from './UpgradeOffersModal'
import LeftSection from 'components/molecules/LeftSection'
import Heading from 'components/atoms/Heading'
import SmartFinDetails from 'containers/plan-details'
import CheckedIcon from 'assets/images/checked.svg'
import UncheckedIcon from 'assets/images/unchecked.svg'

export default function PlanOption() {
  const {
    mobile,
    emiOptions,
    activeOffer,
    otherOffer,
    offersData,
    currentSlide,
    selectedEmi,
    isOtherOfferAvailable,
    isUpgradeOffersModalOpen,
    planDetails,
    handlePlanDetails,
    setPlanDetails,
    setIsUpgradeOffersModalOpen,
    handleClaimOffer,
    handleGetOtherPlans,
    handleOfferChange,
    getPriceFormat,
    setCurrentSlide,
    navigate,
    handleEmiOptionChange,
    handleOtherEmiOptionChange,
    openEdit,
    setOpenEdit,
    loadData,
    handleModalClose,
  } = usePlanOption()

  const settings = {
    className: 'center',
    arrows: false,
    dots: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    initialSlide: 0,
    infinite: false,
    centerPadding: '20px',
    beforeChange: (prev, next) => {
      setCurrentSlide(next)
    },
    appendDots: (dots) => {
      return (
        <div style={{ marginTop: '20px' }}>
          <ul style={{ padding: '0' }}>
            {dots.map((item, index) => {
              return (
                <li
                  key={index}
                  style={{
                    margin: '0px',
                    marginRight: index === currentSlide ? '35px' : '0px',
                  }}
                >
                  {item.props.children}
                </li>
              )
            })}
          </ul>
        </div>
      )
    },
    customPaging: (i) => (
      <div
        style={{
          width: i === currentSlide ? '50px' : '14px',
          height: '14px',
          // borderRight: i === currentSlide ? '30px solid #DCDCDC' : '0',
          marginTop: '1rem',
          borderRadius: '10px',
          backgroundColor: i === currentSlide ? '#DCDCDC' : '#00A99D',
          opacity: i === currentSlide ? '1' : '0.6',
          transition: 'all 0.3s ease-in-out',
        }}
      ></div>
    ),
  }

  return (
    <>
      <Heading />
      <LeftSection>
        <Grid item xs={mobile ? 12 : 7} maxHeight='100vh'>
          <Card
            sx={{
              mx: mobile ? 2 : 13.25,
              py: 3,
              mt: mobile ? 1 : 5.5,
              borderRadius: 2.5,
              px: 3,
            }}
          >
            <Typography variant='body' fontFamily='Archivo expanded'>
              Your selected loan amount
            </Typography>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography className='gradientText' variant='h2' fontWeight='700'>
                {`₹ ${getPriceFormat(Math.round(activeOffer?.eligibleAmount).toString())}`}
              </Typography>
              <img
                src={EditIcon}
                onClick={() => {
                  setOpenEdit(true)
                  ReactGA.event({
                    category: 'Plan Option Page',
                    action: 'Loan amount update',
                    label: 'Loan amount edit',
                  })
                  ReactGA.set({ timestamp: new Date().toISOString() })
                }}
                alt='edit'
                style={{ marginLeft: '5px', fontWeight: 'bold', cursor: 'pointer' }}
              />
            </Box>
          </Card>
          {activeOffer?.loanProduct.bestAvailablePlan && (
            <Typography
              component={Typography}
              fontFamily='Archivo Expanded'
              color='#DCDCDC'
              variant='caption'
              sx={{ opacity: 0.8 }}
              my={2}
              textAlign='center'
            >
              You are on the best available plan
            </Typography>
          )}
          <Card sx={{ borderRadius: 2.5, mx: mobile ? 2 : 13.25, mt: 3 }}>
            <Card sx={{ borderRadius: 2.5, background: '#00221F', p: mobile ? 3 : 5.5 }}>
              {activeOffer?.loanProduct.promoTags.length > 0 && (
                <Box display='flex' alignItems='center' mb={1}>
                  {activeOffer?.loanProduct.promoTags.map((tag, index) => (
                    <Box
                      key={index}
                      bgcolor='rgba(166, 213, 58, 0.10)'
                      px={1.5}
                      py={0.5}
                      borderRadius={5}
                      display='flex'
                      alignItems='center'
                    >
                      <Typography variant='caption' color='#A6D53A'>
                        {tag}
                      </Typography>
                      <StarOutlineIcon sx={{ width: '0.75rem', color: '#A6D53A', ml: 0.5 }} />
                    </Box>
                  ))}
                </Box>
              )}
              <Box display={mobile ? 'block' : 'flex'} width='100%' justifyContent='space-between'>
                <Box maxWidth={mobile ? '100%' : '12.4rem'}>
                  <Typography variant='h2' color='#FFF' className='gradientTextWhite' mb={1.25}>
                    {activeOffer?.loanProduct.productName}
                  </Typography>
                  <Typography className='gradientText' variant='body'>
                    {activeOffer?.loanProduct.description}
                  </Typography>
                </Box>
                {activeOffer?.loanProduct.keyBenefits.length > 0 && (
                  <Box maxWidth={mobile ? '100%' : '12.4rem'} mt={mobile ? 3 : 0}>
                    <Typography variant='body' fontSize='14px' color='#DCDCDC'>
                      Features & Benefits
                    </Typography>
                    <ul
                      style={{
                        color: '#E5F6F5',
                        fontFamily: "'IBM Plex Sans', sans-serif",
                        fontSize: '12px',
                        padding: '0 1.25rem',
                        paddingTop: '6px',
                        lineHeight: '1.25rem',
                      }}
                    >
                      {activeOffer.loanProduct.keyBenefits.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))}
                    </ul>
                    <Typography
                      variant='caption'
                      textTransform='uppercase'
                      color='#51B3A7'
                      mt={1.25}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        handlePlanDetails(activeOffer.loanProduct?.productId, false)
                        ReactGA.event({
                          category: 'Plan Option Page',
                          action: 'Plan details',
                          label: 'Learn more',
                        })
                        ReactGA.set({ timestamp: new Date().toISOString() })
                      }}
                    >
                      +Learn more
                    </Typography>
                  </Box>
                )}
              </Box>
              {mobile && <Box height='1px' width='100%' bgcolor='#00655E' my={2}></Box>}
              <Typography mt={3} color='#FFF' variant='caption'>
                Pick your EMI
              </Typography>
              <Box mt={2}>
                {emiOptions.map((option, index) => (
                  <Box
                    key={index}
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    bgcolor={option.isSelected ? '#014943' : 'rgba(0, 0, 0, 0.20)'}
                    borderRadius='5px'
                    px={2.5}
                    py={2}
                    mb={1}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleEmiOptionChange(index)}
                  >
                    <Box display='flex' alignItems='center'>
                      {option.isSelected ? (
                        <img src={CheckedIcon} style={{ marginRight: '1rem' }} />
                      ) : (
                        <img src={UncheckedIcon} style={{ marginRight: '1rem' }} />
                      )}
                      <Box display='flex' flexDirection='column'>
                        <Typography
                          variant='caption'
                          color={option.isSelected ? '#fff' : '#777'}
                          fontWeight='500'
                        >{`₹ ${option.emi} x ${option.tenure} Months`}</Typography>
                        <Box display='flex' gap={0.5}>
                          <Typography
                            variant='caption'
                            color={option.isSelected ? '#fff' : '#777'}
                            fontWeight='500'
                          >
                            ROI
                          </Typography>
                          <Typography variant='caption' color={option.isSelected ? '#fff' : '#777'}>
                            {option.roi}%
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {option.isPreferred && option.preferredTenureTag && (
                      <Typography
                        variant='caption'
                        borderRadius={2.5}
                        color={option.isSelected ? '#B0D456' : '#ffffff80'}
                        bgcolor={option.isSelected ? '#a6d53a1a' : '#374948'}
                        px={1.5}
                        py={0.5}
                        mx={1}
                      >
                        {option.preferredTenureTag}
                      </Typography>
                    )}
                    {option.upgradeOptions > 0 && (
                      <Typography
                        variant='caption'
                        borderRadius={2.5}
                        color={option.isSelected ? '#B0D456' : '#ffffff80'}
                        bgcolor={option.isSelected ? '#a6d53a1a' : '#374948'}
                        px={1.5}
                        py={0.5}
                        mx={1}
                        onClick={() => setIsUpgradeOffersModalOpen(true)}
                      >
                        {option.upgradeOptions} {option.upgradeOptions > 1 ? 'upgrades' : 'upgrade'}{' '}
                        available
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </Card>
            <Box display='flex' justifyContent='center'>
              <Button
                sx={{ my: 2, px: 5 }}
                disabled={!selectedEmi?.tenure}
                onClick={() => {
                  handleClaimOffer()
                  ReactGA.event({
                    category: 'Plan Option Page',
                    action: 'Claim plan offer',
                    label: 'Claim offer',
                  })
                  ReactGA.set({ timestamp: new Date().toISOString() })
                }}
              >
                Claim Your Eligibility Card
              </Button>
            </Box>
          </Card>
          {isOtherOfferAvailable && (
            <Card
              sx={{
                background: 'linear-gradient(344deg, #00221F -2.74%, #282828 93.54%)',
                borderRadius: 2.5,
                px: 3,
                py: 4,
                mx: mobile ? 2 : 13.25,
                mt: 4,
              }}
            >
              <Grid container columnGap={7.5} rowGap={3}>
                <Grid item xs={10} md={6}>
                  <Typography variant='h2' color='#00A99D' fontSize='1.25rem'>
                    Looking for a more flexible loan?
                  </Typography>
                </Grid>
                <Grid item xs={4} display='flex' alignItems='center'>
                  <Button
                    sx={{
                      background: 'linear-gradient(37deg, #00A99D 3.89%, #00655E 99.09%)',
                      fontFamily: 'IBM Plex Sans',
                      textTransform: 'capitalize',
                      height: '36px !important',
                      px: 2,
                    }}
                    onClick={() => handleGetOtherPlans()}
                  >
                    Other Plans
                  </Button>
                </Grid>
              </Grid>
            </Card>
          )}
          {otherOffer?.loanProducts && (
            <Typography
              component={Typography}
              fontFamily='Archivo Expanded'
              color='#DCDCDC'
              variant='caption'
              sx={{ opacity: 0.8 }}
              my={2}
              textAlign='center'
            >
              Other plans
            </Typography>
          )}
          {otherOffer?.loanProducts.map((product, productIndex) => (
            <Fragment key={productIndex}>
              <Card sx={{ borderRadius: 2.5, mx: mobile ? 2 : 13.25, mt: 3 }}>
                <Card sx={{ borderRadius: 2.5, background: '#00221F', p: mobile ? 3 : 5.5 }}>
                  {product.promoTags.length > 0 && (
                    <Box display='flex' alignItems='center' mb={1}>
                      {product.promoTags.map((tag, index) => (
                        <Box
                          key={index}
                          bgcolor='rgba(166, 213, 58, 0.10)'
                          px={1.5}
                          py={0.5}
                          borderRadius={5}
                          display='flex'
                          alignItems='center'
                        >
                          <Typography variant='caption' color='#A6D53A'>
                            {tag}
                          </Typography>
                          <StarOutlineIcon sx={{ width: '0.75rem', color: '#A6D53A', ml: 0.5 }} />
                        </Box>
                      ))}
                    </Box>
                  )}
                  <Box
                    display={mobile ? 'block' : 'flex'}
                    width='100%'
                    justifyContent='space-between'
                  >
                    <Box maxWidth={mobile ? '100%' : '12.4rem'}>
                      <Typography variant='h2' color='#FFF' className='gradientTextWhite' mb={1.25}>
                        {product.productName}
                      </Typography>
                      <Typography className='gradientText' variant='body'>
                        {activeOffer?.loanProduct.description}
                      </Typography>
                    </Box>
                    {product.keyBenefits.length > 0 && (
                      <Box maxWidth={mobile ? '100%' : '12.4rem'} mt={mobile ? 3 : 0}>
                        <Typography variant='body' fontSize='14px' color='#DCDCDC'>
                          Features & Benefits
                        </Typography>
                        <ul
                          style={{
                            color: '#E5F6F5',
                            fontFamily: "'IBM Plex Sans', sans-serif",
                            fontSize: '12px',
                            padding: '0 1.25rem',
                            paddingTop: '6px',
                            lineHeight: '1.25rem',
                          }}
                        >
                          {product.keyBenefits.map((benefit, index) => (
                            <li key={index}>{benefit}</li>
                          ))}
                        </ul>
                        <Typography
                          variant='caption'
                          textTransform='uppercase'
                          color='#51B3A7'
                          mt={1.25}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            handlePlanDetails(product?.productId, true)
                            ReactGA.event({
                              category: 'Plan Option Page',
                              action: 'Plan details',
                              label: 'Learn more',
                            })
                            ReactGA.set({ timestamp: new Date().toISOString() })
                          }}
                        >
                          +Learn more
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  {mobile && <Box height='1px' width='100%' bgcolor='#00655E' my={2}></Box>}
                  <Typography mt={3} color='#FFF' variant='caption'>
                    Pick your EMI
                  </Typography>
                  <Box mt={2}>
                    {product.emiOptions.map((option, emiIndex) => (
                      <Box
                        key={emiIndex}
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        bgcolor={option.isSelected ? '#014943' : 'rgba(0, 0, 0, 0.20)'}
                        borderRadius='5px'
                        px={2.5}
                        py={2}
                        mb={1}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleOtherEmiOptionChange(productIndex, emiIndex)}
                      >
                        <Box display='flex' alignItems='center'>
                          {option.isSelected ? (
                            <img src={CheckedIcon} style={{ marginRight: '1rem' }} />
                          ) : (
                            <img src={UncheckedIcon} style={{ marginRight: '1rem' }} />
                          )}
                          <Box display='flex' flexDirection='column'>
                            <Typography
                              variant='caption'
                              color={option.isSelected ? '#fff' : '#777'}
                              fontWeight='500'
                            >{`₹ ${option.emi} x ${option.tenure} Months`}</Typography>
                            <Box display='flex' gap={0.5}>
                              <Typography
                                variant='caption'
                                color={option.isSelected ? '#fff' : '#777'}
                                fontWeight='500'
                              >
                                ROI
                              </Typography>
                              <Typography
                                variant='caption'
                                color={option.isSelected ? '#fff' : '#777'}
                              >
                                {option.roi}%
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {option.isPreferred && option.preferredTenureTag && (
                          <Typography
                            variant='caption'
                            borderRadius={2.5}
                            color={option.isSelected ? '#B0D456' : '#ffffff80'}
                            bgcolor={option.isSelected ? '#a6d53a1a' : '#374948'}
                            px={1.5}
                            py={0.5}
                            mx={1}
                          >
                            {option.preferredTenureTag}
                          </Typography>
                        )}
                        {option.upgradeOptions > 0 && (
                          <Typography
                            variant='caption'
                            borderRadius={2.5}
                            color={option.isSelected ? '#B0D456' : '#ffffff80'}
                            bgcolor={option.isSelected ? '#a6d53a1a' : '#374948'}
                            px={1.5}
                            py={0.5}
                            mx={1}
                          >
                            {option.upgradeOptions}{' '}
                            {option.upgradeOptions > 1 ? 'upgrades' : 'upgrade'} available
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Card>
                <Box display='flex' justifyContent='center'>
                  <Button
                    sx={{ my: 2, px: 5 }}
                    onClick={() => {
                      handleOfferChange(product.productId)
                      ReactGA.event({
                        category: 'Plan Option Page',
                        action: 'Select Plan',
                        label: 'Plan update',
                      })
                      ReactGA.set({ timestamp: new Date().toISOString() })
                    }}
                  >
                    Select Plan
                  </Button>
                </Box>
              </Card>
            </Fragment>
          ))}
          {mobile && activeOffer?.upsellProduct.length > 0 && (
            <Box
              py={6.25}
              bgcolor='#00655E'
              mt={5.5}
              sx={{ borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem' }}
              position='relative'
            >
              <Box px={5} pb={2}>
                <Typography
                  variant='h2'
                  fontSize='1.25rem'
                  fontFamily='Archivo Expanded'
                  display='inline'
                  color='#FFF'
                >
                  {`WOW! `}
                </Typography>
                <Typography
                  variant='h2'
                  fontSize='1.25rem'
                  fontFamily='Archivo Expanded'
                  display='inline'
                  color='#FAFAFA'
                  sx={{ opacity: 0.5 }}
                >
                  Enhance your plan and enjoy even better benefits
                </Typography>
              </Box>
              <Box
                position='absolute'
                top='-1.25rem'
                left='1rem'
                pl={2}
                pr={5}
                py={1}
                display='flex'
                justifyContent='center'
                alignItems='center'
                color='#00655E'
                bgcolor='#E5F6F5'
                borderRadius={5}
              >
                {activeOffer?.upsellProduct.length}{' '}
                {activeOffer?.upsellProduct.length > 0 ? 'Offers' : 'Offer'} available
              </Box>
              <Slider {...settings} style={{ paddingBottom: '1rem' }}>
                {activeOffer?.upsellProduct.map((offer, index) => (
                  <Card
                    key={index}
                    sx={{
                      background: '#00221F',
                      borderRadius: 2.5,
                      border: '1px solid rgba(81, 179, 167, 0.20)',
                      backdropFilter: 'blur(20px)',
                      color: '#fff',
                      px: 5,
                      py: 4,
                      maxWidth:
                        activeOffer.upsellProduct.length > 1
                          ? 'calc(100vw - 150px)'
                          : 'calc(100vw - 120px)',
                    }}
                  >
                    <Typography variant='body'>{offer.upsellText}</Typography>
                    <Typography
                      onClick={() => {
                        handlePlanDetails(activeOffer.loanProduct?.productId, true)
                        ReactGA.event({
                          category: 'Plan Option Page',
                          action: 'Plan details',
                          label: 'Learn more',
                        })
                        ReactGA.set({ timestamp: new Date().toISOString() })
                      }}
                      sx={{ marginLeft: '10px', cursor: 'pointer' }}
                      variant='body'
                      color='#00A99D'
                    >
                      Learn more
                    </Typography>
                    <Box display='flex' alignItems='center' justifyContent='space-between' mt={2.5}>
                      <Button
                        sx={{
                          background: 'linear-gradient(37deg, #00A99D 3.89%, #00655E 99.09%)',
                          fontFamily: 'IBM Plex Sans',
                          textTransform: 'capitalize',
                          height: '36px !important',
                          px: 2,
                        }}
                        onClick={() => {
                          handleOfferChange(offer.productId)
                          ReactGA.event({
                            category: 'Plan Option Page',
                            action: 'Select Plan',
                            label: 'Plan update',
                          })
                          ReactGA.set({ timestamp: new Date().toISOString() })
                        }}
                      >
                        Select Plan
                      </Button>
                      <Typography>{offer.productName}</Typography>
                    </Box>
                  </Card>
                ))}
              </Slider>
            </Box>
          )}
          <Footer mobile={mobile} sx={{ marginTop: '5.5rem' }} />
        </Grid>
      </LeftSection>
      <UpgradeOffersModal
        open={isUpgradeOffersModalOpen}
        setOpen={setIsUpgradeOffersModalOpen}
        upgradeOffers={offersData}
        handleOfferChange={handleOfferChange}
        mobile={mobile}
      />
      <EditLoanDrawer
        open={openEdit}
        setOpen={setOpenEdit}
        handleClose={handleModalClose}
        loadData={loadData}
      />
      <SmartFinDetails
        open={planDetails.isOpen}
        setOpen={setPlanDetails}
        planId={planDetails.planId}
        showButton={planDetails.showButton}
      />
    </>
  )
}
