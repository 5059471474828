import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { SxProps, Theme } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import useMobileDevice from 'hooks/useMobileDevice'
import closeIcon from 'assets/images/close icon.svg'
import DoneIcon from '@mui/icons-material/Done'

interface OptionsInterface {
  value: string | number
  primaryText: string | number
  secondaryText?: string | number
  subText?: string | number
}

interface PropsInterface {
  label: string
  sx?: SxProps<Theme>
  drawerTitle: string
  buttonName: string
  options: OptionsInterface[]
  value?: OptionsInterface
  disabled?: boolean
  mode?: 'light' | 'dark'
  onChange: (value: OptionsInterface) => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose?: () => void
  handleClick?: () => void
}

export default function CustomSelect({
  label,
  sx,
  drawerTitle,
  buttonName,
  options,
  value,
  disabled = false,
  open,
  mode = 'light',
  setOpen,
  handleClose,
  handleClick,
  onChange,
}: PropsInterface) {

  const [selectedOption, setSelectedOption] = useState<OptionsInterface>({
    primaryText: '',
    value: '',
  })
  const mobile = useMobileDevice()

  useEffect(() => {
    if (value?.value !== undefined) {
      setSelectedOption(value)
    }
  }, [value])

  function handleOpen() {
    if (!disabled) {
      setOpen(true)
    }
  }

  return (
    <>
      <FormControl sx={{ my: 1.5 }} disabled={disabled} className={`${mode === 'dark' ? 'textFieldDark' : ''} ${disabled ? 'disabled' : ''}`}>
        <InputLabel
        >{label}</InputLabel>
        <Select
          value={selectedOption?.value}
          label={label}
          open={false}
          onClick={handleOpen}
          sx={{
            ...(selectedOption?.value && {
              '& .MuiOutlinedInput-notchedOutline': {
                  border: '2px solid #51B3A7',
              },
            }),
            ...sx
          }}
        >
          <MenuItem value={selectedOption?.value}>{selectedOption?.primaryText}</MenuItem>
        </Select>
      </FormControl>
      <Drawer
        open={open}
        anchor={mobile ? 'bottom' : 'right'}
        onClose={handleClose}
        sx={{
          mt: mobile ? 16.25 : 0,
          zIndex: 1400,
        }}
        PaperProps={{
          sx: {
            background: 'linear-gradient(155deg, #082C29 -11.66%, #000 52.77%)',
            backdropFilter: 'blur(30px)',
            borderRadius: mobile ? '1.25rem 1.25rem 0 0' : '1.25rem 0 0 1.25rem',
            width: mobile ? '100%' : '450px',
          },
        }}
      >
        <Box display='flex' justifyContent='end' alignItems='center'>
          <img
            src={closeIcon}
            alt='close icon'
            onClick={handleClose}
            style={{
              width: '2rem',
              height: '2rem',
              marginRight: mobile ? '1rem' : '2rem',
              marginTop: '1.5rem',
              cursor: 'pointer',
            }}
          />
        </Box>
        <Box
          padding='1px'
          sx={{
            background: 'linear-gradient(333deg, #51B3A7 64.26%, rgba(81, 179, 167, 0.00) 109.59%)',
            mx: mobile ? 2 : 4,
            mt: mobile ? 1 : 3,
            mb: 5,
            borderRadius: '1.25rem',
          }}
        >
          <Card
            sx={{
              borderRadius: '1.25rem',
              // border: '0.5px solid #51B3A7',
              background: 'linear-gradient(191deg, #282828 -3.55%, #082C29 110.82%)',
            }}
          >
            <Typography variant='h2' color='#fff' py={4} px={4} textAlign='center'>
              {drawerTitle}
            </Typography>
            <Box
              className='custom-scrollbar'
              maxHeight={mobile ? 'calc(100vh - 27rem)' : 'calc(100vh - 21rem)'}
              sx={{ overflowX: 'auto' }}
            >
              {options.map((option) => (
                <React.Fragment key={option.value}>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    key={option.value}
                    onClick={() => {
                      setSelectedOption(option)
                      onChange(option)
                    }}
                    height={'3rem'}
                    mx={mobile ? 4 : 8}
                    mb={2}
                    borderBottom={
                      option.value === selectedOption.value
                        ? '1px solid #51B3A7'
                        : '1px solid rgba(218, 218, 218, 0.60)'
                    }
                    borderRadius={1}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      sx={{ paddingLeft: '1rem' }}
                      color={option.value === selectedOption.value ? '#51B3A7' : '#DADADA'}
                    >
                      <Typography textTransform='uppercase' fontWeight='600' variant='body'>
                        {option.primaryText}
                      </Typography>
                      {option?.subText ? (
                        <Typography fontSize='10px' variant='caption' sx={{ paddingRight: '1rem' }}>
                          {option.subText}
                        </Typography>
                      ) : null}
                    </Box>
                    {option.secondaryText && option.value !== selectedOption.value && (
                      <Typography color='#DADADA' variant='caption' sx={{ paddingRight: '1rem' }}>
                        {option.secondaryText}
                      </Typography>
                    )}

                    {option.value === selectedOption.value && <DoneIcon color='primary' />}
                  </Box>
                </React.Fragment>
              ))}
            </Box>

            <Box display='flex' justifyContent='center' my={5} mx={4}>
              <Button
                disabled={selectedOption.value == ''}
                fullWidth
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  handleClose()
                  handleClick()
                }}
              >
                {buttonName}
              </Button>
            </Box>
          </Card>
        </Box>
      </Drawer>
    </>
  )
}
