import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material'
import { theme } from './muiTheme/customTheme'
import { Toaster } from 'react-hot-toast'
import AppRoutes from 'Routing/AppRoutes'
import Logo from 'assets/images/Logo.svg'
import useMobileDevice from 'hooks/useMobileDevice'
import ReactGA from 'react-ga4'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function App() {
  const mobile = useMobileDevice()

  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_KEY}`)
  }, [])
  
  return (
    <ThemeProvider theme={theme}>
      <Toaster />
      <AppRoutes />
    </ThemeProvider>
  )
}

export default App
