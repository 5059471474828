import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import EditIcon from 'assets/images/edit.svg'
import GlobeGif from 'assets/images/globe.gif'
import { Controller } from 'react-hook-form'
import { CircularProgress, TextField } from '@mui/material'
import CustomSelect from 'components/molecules/CustomSelect'
import CustomModal from 'components/molecules/Modal'
import useFinance from 'containers/finance/useFinance'
import SuccessModal from './successModal'
import usePincode from './usePincode'
import PincodeModal from 'components/molecules/PincodeModal'
import MuiLink from '@mui/material/Link'

export default function UnavailableModal({ name, setModalOpen, setSuccessModalOpen }) {
  const flag = true
  const {
    addressData,
    isLoading,
    mobile,
    open,
    brandOptions,
    modelOptions,
    showBrands,
    showModels,
    selectedBrand,
    setOpen,
    setShowBrands,
    setShowModels,
    handleBrandChange,
    handleModelChange,
    handleSelectVariant,
    handleVariantSelected,
    onVehicleSubmit,
    setName,
    userName,
    selectedModel,
    canEditCity,
  } = useFinance({ name, flag, setModalOpen, setSuccessModalOpen })

  const { onPincodeSubmit, isLoading: isPincodeLoading } = usePincode(setOpen)

  return (
    <>
      <Box
        sx={{
          borderRadius: 5,
          px: 4,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          overflowY: 'auto',
        }}
      >
        <img
          src={GlobeGif}
          alt='scooter'
          style={{
            margin: mobile ? 'auto' : ' 0 -10px',
            width: '4rem',
            height: '4rem',
            marginTop: '1.4rem',
          }}
        />

        <Typography
          variant='h2'
          textAlign={mobile ? 'center' : 'left'}
          sx={{ my: 2, pt: mobile ? 0 : 2 }}
        >
          {`We're not live here yet, but we can help!`}
        </Typography>
        <Typography
          textAlign={mobile ? 'center' : 'left'}
          color='text.secondary'
          sx={{ mb: mobile ? 3 : 7 }}
        >
          {`Leave your contact details and we'll get back to you.`}
        </Typography>

        <TextField
          onChange={(e) => setName(e.target.value)}
          value={userName}
          fullWidth
          label='Your Name'
          sx={{ my: 1.5 }}
        />

        <CustomSelect
          label='Brand'
          drawerTitle='Pick a brand'
          buttonName='Select Model'
          options={brandOptions}
          open={showBrands}
          setOpen={setShowBrands}
          handleClose={() => setShowBrands(false)}
          handleClick={handleSelectVariant}
          onChange={(option) => handleBrandChange(option)}
        />
        <CustomSelect
          label='Variant'
          drawerTitle='Select a variant'
          disabled={!selectedBrand}
          buttonName='Done'
          value={selectedModel}
          open={showModels}
          setOpen={setShowModels}
          handleClose={() => setShowModels(false)}
          handleClick={handleVariantSelected}
          options={modelOptions}
          onChange={(option) => handleModelChange(option)}
        />
        <Button
          disabled={
            typeof selectedModel.losVehicleId != 'number' ||
            typeof selectedModel.vehicleId != 'number' ||
            userName == null ||
            userName == ''
          }
          sx={{ my: 3 }}
          onClick={onVehicleSubmit}
        >
          {isLoading ? (
            <Box mx='auto' mt={1}>
              <CircularProgress size={20} color='success' />
            </Box>
          ) : (
            'Next'
          )}
        </Button>
        <Box
          display='flex'
          alignItems='center'
          m={mobile ? '8px 0px 20px 0px' : '10px 0px'}
          justifyContent={'center'}
          style={{ cursor: 'pointer' }}
        >
          Location
          <Typography ml={1} textAlign='center' color='text.secondary' fontWeight='600'>
            {addressData?.city}
          </Typography>
          {canEditCity && (
            <img
              onClick={() => setOpen(true)}
              src={EditIcon}
              alt='edit'
              style={{ marginLeft: '5px', fontWeight: 'bold' }}
            />
          )}
        </Box>
        <Typography my={2} variant='caption' align={mobile ? 'center' : 'left'}>
          By continuing to use our website, you agree to our{' '}
          <Typography
            component={MuiLink}
            variant='caption'
            href='https://evfin.co/privacy.php'
            target='_blank'
            whiteSpace='nowrap'
          >
            Privacy Policy
          </Typography>{' '}
          and
          <Typography
            component={MuiLink}
            variant='caption'
            href='https://evfin.co/tnc.php'
            target='_blank'
            sx={{ marginLeft: '4px' }}
            whiteSpace='nowrap'
          >
            Terms & Conditions
          </Typography>
        </Typography>
      </Box>

      <CustomModal padding={4} open={open} setOpen={setOpen}>
        <PincodeModal isLoading={isPincodeLoading} onSubmit={onPincodeSubmit} />
      </CustomModal>
    </>
  )
}
