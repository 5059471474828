export const SEND_OTP = `/auth-service/public/sendOTP`
export const VERIFY_OTP = `/auth-service/public/verifyOTP`
export const RESEND_OTP = `/auth-service/public/resendOTP`
export const SIGN_UP = `/auth-service/public/signUp`
export const SEND_PINCODE = `/backend-service/postal/public/fetch-city/`
export const GET_VEHICLE = `/backend-service/vehicle`
export const SAVE_VEHICLE = `/backend-service/vehicle/save`
export const SAVE_PROFILE = `/backend-service/profile/save`
export const GET_PROFILE = `/backend-service/profile/`
export const GET_LOAN_PREFERENCE = `backend-service/loan/preference`
export const GET_LOAN_SANCTION = `backend-service/offer/sanctioned-card`
export const SAVE_LOAN_PREFERENCE = `backend-service/loan/save-preference`
export const VEHICLE_OFFERS = `backend-service/vehicle/upsell/product`
export const GENERATE_OFFERS = `backend-service/offer/generate`
export const ACTIVE_OFFER = `backend-service/offer/active`
export const OTHER_OFFER = `backend-service/offer/other`
export const UPDATE_PRODUCT = `backend-service/offer/update-product`
export const CLAIM_OFFER = `backend-service/offer/claim`
export const SAVE_DEALER = `backend-service/vehicle/save-dealer`
export const ELIGIBLE_DEALER = `backend-service/vehicle/recommended-vehicles`
export const GET_VEHICLE_PRICING = `backend-service/vehicle/cust-vehicle-pricing`
export const GET_OFFER_PREFERENCE = `backend-service/offer/preference`
export const UPDATE_PREFERENCE = `backend-service/offer/update-preference`
export const CREATE_NEW_APPLICATION  = `backend-service/loan/new-application`
export const REDIRECT_PAGE = `backend-service/profile/check-status`
export const PLAN_DETAILS = `backend-service/product/details`
export const TEST_RIDE_DETAILS = `backend-service/test-ride/info`
export const TEST_RIDE_DEALERS = `backend-service/test-ride/dealer`
export const SAVE_TEST_RIDE = `backend-service/test-ride/save`
export const RECOMMENDED_VEHICLES = `backend-service/home/`
export const GUEST_TOKEN =  `auth-service/public/guest-token`
export const TEST_RIDE_TIMINGS = `backend-service/test-ride/settings`
export const GUEST_OTP = `backend-service/profile/validate-session-user`