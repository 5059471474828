import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SanctionGif from 'assets/images/sanction.gif'
import DownSvg from 'assets/images/down.svg'
import RightSvg from 'assets/images/right.svg'
import LoanCard from './loanCard'
import { routes } from 'constants/routes'
import { useNavigate } from 'react-router-dom'

interface Props {
  mobile: boolean
  loanDetails: any
}

export default function RollSection({ mobile, loanDetails }: Props) {
  const navigate = useNavigate()
  return (
    <Box mx={6} width='320px' display='flex' flexDirection='column' pt={mobile ? 3 : 10}>
      <img
        src={SanctionGif}
        alt='details'
        style={{ margin: '0 auto', width: '4rem', height: '4rem', marginTop: '1.4rem' }}
      />
      <Typography variant='h2' textAlign='center' sx={{ my: 1.5 }}>
        {`Ready To Roll!`}
      </Typography>
      <Typography variant='body' textAlign='center' sx={{ mb: 7 }} color='text.secondary'>
        {`Meet us at your chosen ${loanDetails?.vehiclePricingDetails?.brand} dealer, we'll scan this
        QR to speed things up for you.`}
      </Typography>
      {loanDetails && (
        <>
          <LoanCard loanDetails={loanDetails} />
        </>
      )}
    </Box>
  )
}
