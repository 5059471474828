import React from 'react'
import BackgroundImage from 'assets/images/background.png'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import VehicleCard from 'components/molecules/Cards/VehicleCard'
import PreferenceGif from 'assets/images/preference.gif'
import CircularProgress from '@mui/material/CircularProgress'
import Slider from '@mui/material/Slider'
import useLoanPreference from './useLoanPreference'
import Stepper from 'components/molecules/Stepper'
import Footer from 'components/organisms/footer'
import Heading from 'components/atoms/Heading'
import LeftSection from 'components/molecules/LeftSection'

export default function Preference() {
  const {
    isPageLoading,
    loanData,
    payment,
    setPayment,
    tenure,
    setTenure,
    emi,
    mobile,
    onContinue,
    isLoading,
  }: any = useLoanPreference()

  const paymentMarks = [
    {
      value: parseInt(loanData?.minDownPayment),
      label: `₹ ${parseInt(loanData?.minDownPayment)}`,
    },

    {
      value: parseInt(loanData?.maxDownPayment),
      label: `₹ ${parseInt(loanData?.maxDownPayment)}`,
    },
  ]

  const tenureMarks = [
    {
      value: loanData?.minTenure / 12,
      label: `${loanData?.minTenure / 12} years`,
    },

    {
      value: loanData?.maxTenure / 12,
      label: `${loanData?.maxTenure / 12} years`,
    },
  ]

  return (
    <>
      <Heading />
      {mobile ? (
        <Box>
          <img
            src={BackgroundImage}
            width='100%'
            style={{ objectFit: 'contain', position: 'fixed', top: '0', zIndex: '-1' }}
          />
          <Stepper
            currentStepper={2}
            totalNoOfSteppers={3}
            sx={{ position: 'absolute', top: '80px' }}
          />
          {isPageLoading ? null : <VehicleCard />}
          {isPageLoading ? (
            <Box
              mt={10}
              sx={{
                background: 'white',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color='success' />
            </Box>
          ) : (
            <Box sx={{ background: 'white', borderRadius: '1.25rem 1.25rem 0 0' }}>
              <Box mt={3} sx={{ background: '#E5F6F5', borderRadius: '1.25rem' }}>
                <Card
                  variant='outlined'
                  sx={{
                    borderRadius: '1.25rem',
                    px: 6.5,
                    display: 'flex',
                    flexDirection: 'column',
                    border: '0px',
                    mx: '1px',
                    pb: '1.5rem',
                  }}
                >
                  <img
                    src={PreferenceGif}
                    alt='scooter'
                    style={{ margin: 'auto', width: '4rem', height: '4rem', marginTop: '1.4rem' }}
                  />
                  <Typography variant='h2' textAlign='center' sx={{ my: 1.5 }}>
                    Customised financing option
                  </Typography>
                  <Box display='flex' mb='1.75rem' justifyContent='center'>
                    <Typography textAlign='center' color='text.secondary'>
                      Own your dream vehicle with tailor-made solutions that suit your needs.
                    </Typography>
                  </Box>
                  <Box sx={{ width: mobile ? '100%' : 300 }}>
                    <Box display='flex' justifyContent='space-between'>
                      <Typography gutterBottom variant='bodyStrong' color='text.secondary'>
                        Upfront payment
                      </Typography>
                      <Typography gutterBottom variant='bodyStrong' color='#00A99D'>
                        {`₹ ${payment}`}
                      </Typography>
                    </Box>

                    <Slider
                      sx={{ maxWidth: '300px' }}
                      aria-label='Always visible'
                      //getAriaValueText={valuetext}
                      step={1000}
                      value={payment}
                      onChange={(e: any) => setPayment(e.target.value)}
                      min={parseInt(loanData?.minDownPayment, 10)}
                      max={parseInt(loanData?.maxDownPayment, 10)}
                      marks={paymentMarks}
                      valueLabelDisplay='off'
                    />
                  </Box>
                  <Box sx={{ width: mobile ? '100%' : 300 }} pt='32px'>
                    <Box display='flex' justifyContent='space-between'>
                      <Typography gutterBottom variant='bodyStrong' color='text.secondary'>
                        Loan duration
                      </Typography>
                      <Typography gutterBottom variant='bodyStrong' color='#00A99D'>
                        {`${tenure} years (${tenure * 12} months)`}
                      </Typography>
                    </Box>
                    <Slider
                      sx={{ maxWidth: '300px' }}
                      aria-label='Always visible'
                      //getAriaValueText={valuetext}
                      value={tenure}
                      onChange={(e: any) => setTenure(e.target.value)}
                      step={1}
                      min={loanData?.minTenure / 12}
                      max={loanData?.maxTenure / 12}
                      marks={tenureMarks}
                      valueLabelDisplay='off'
                    />
                  </Box>
                </Card>
                <Box display='flex' justifyContent='space-around' py={4}>
                  <Box>
                    <Typography variant='subtitle1' color='text.secondary'>
                      {`₹ ${emi}*`}
                    </Typography>
                  </Box>
                  <Box display='flex' flexDirection='column'>
                    <Typography variant='bodyStrong' color='text.secondary'>
                      {`EMI for ${tenure * 12} Months`}
                    </Typography>
                    <Typography fontSize='10px' color='text.secondary'>
                      *on choosing {loanData?.schemeName}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                display='flex'
                justifyContent='center'
                sx={{ background: 'white' }}
                onClick={onContinue}
              >
                <Button sx={{ mt: 2, mb: 5, width: '90%' }}>
                  {isLoading ? (
                    <Box mx='auto' mt={1}>
                      <CircularProgress size={20} color='success' />
                    </Box>
                  ) : (
                    'Continue'
                  )}
                </Button>
              </Box>
              <Footer mobile={mobile} />
            </Box>
          )}
        </Box>
      ) : (
        <LeftSection>
          <Grid
            item
            xs={7}
            width='100%'
            height='100%'
            display='flex'
            justifyContent='center'
            flexDirection='column'
            alignItems='center'
          >
            <Stepper currentStepper={2} totalNoOfSteppers={3} />
            {!isPageLoading ? (
              <Box width='100%' bgcolor='#FFF'>
                <Box
                  width='320px'
                  minHeight='100vh'
                  display='flex'
                  flexDirection='column'
                  paddingTop='100px'
                  margin='auto'
                >
                  <img
                    src={PreferenceGif}
                    alt='scooter'
                    style={{ margin: '0 auto', width: '4rem', height: '4rem', marginTop: '1.4rem' }}
                  />
                  <Typography variant='h2' textAlign='center' sx={{ my: 1.5 }}>
                    Customised financing option
                  </Typography>
                  <Box display='flex' mb='1.75rem' justifyContent='center'>
                    <Typography textAlign='center' variant='body' color='text.secondary'>
                      Own your dream vehicle with tailor-made solutions that suit your needs.
                    </Typography>
                  </Box>
                  <Box sx={{ width: 300 }}>
                    <Box display='flex' justifyContent='space-between'>
                      <Typography gutterBottom variant='bodyStrong' color='text.secondary'>
                        Upfront payment
                      </Typography>
                      <Typography gutterBottom variant='bodyStrong' color='#00A99D'>
                        {`₹ ${payment}`}
                      </Typography>
                    </Box>
                    <Slider
                      sx={{ maxWidth: '300px' }}
                      aria-label='Always visible'
                      //getAriaValueText={valuetext}
                      step={1000}
                      min={parseInt(loanData?.minDownPayment, 10)}
                      max={parseInt(loanData?.maxDownPayment, 10)}
                      value={payment}
                      onChange={(e: any) => setPayment(e.target.value)}
                      marks={paymentMarks}
                      valueLabelDisplay='off'
                    />
                  </Box>
                  <Box sx={{ width: 300 }} pt='32px'>
                    <Box display='flex' justifyContent='space-between'>
                      <Typography gutterBottom variant='bodyStrong' color='text.secondary'>
                        Loan duration
                      </Typography>
                      <Typography gutterBottom variant='bodyStrong' color='#00A99D'>
                        {`${tenure} years (${tenure * 12} months)`}
                      </Typography>
                    </Box>

                    <Slider
                      sx={{ maxWidth: '300px' }}
                      aria-label='Always visible'
                      //getAriaValueText={valuetext}
                      step={1}
                      value={tenure}
                      onChange={(e: any) => setTenure(e.target.value)}
                      min={loanData?.minTenure / 12}
                      max={loanData?.maxTenure / 12}
                      marks={tenureMarks}
                      valueLabelDisplay='off'
                    />
                  </Box>

                  <Card
                    sx={{
                      borderRadius: '10px',
                      background: '#E5F6F5',
                      width: '320px',
                      border: 'none',
                      height: '85px',
                      boderBottom: 'none',
                      marginTop: '20px',
                      boxShadow: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Box>
                      <Typography variant='subtitle1' color='text.secondary'>
                        {`₹ ${emi}*`}
                      </Typography>
                    </Box>
                    <Box display='flex' flexDirection='column'>
                      <Typography variant='bodyStrong' color='text.secondary'>
                        {`EMI for ${tenure * 12} Months`}
                      </Typography>
                      <Typography fontSize='10px' color='text.secondary'>
                        *on choosing {loanData?.schemeName}
                      </Typography>
                    </Box>
                  </Card>

                  <Button sx={{ mt: 2, mb: 5 }} onClick={onContinue}>
                    {isLoading ? (
                      <Box mx='auto' mt={1}>
                        <CircularProgress size={20} color='success' />
                      </Box>
                    ) : (
                      'Continue'
                    )}
                  </Button>
                </Box>
                <Footer mobile={mobile} />
              </Box>
            ) : (
              <Box
                width='320px'
                minHeight='100vh'
                display='flex'
                flexDirection='column'
                paddingTop='100px'
                justifyContent='center'
                alignItems='center'
              >
                <CircularProgress color='success' />
              </Box>
            )}
            <Box
              position='fixed'
              bottom='2rem'
              left='5rem'
              maxWidth='500px'
            >
              <VehicleCard />
            </Box>
          </Grid>
        </LeftSection>
      )}
    </>
  )
}
