import React from 'react'
import { Box, Typography, Divider } from '@mui/material'

const SpecModal = () => {
  return (
    <>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' flexDirection='column'>
          <Typography variant='body2'>Ather 4505</Typography>
          <Typography variant='caption' pt={1}>
            2.9 kwh Battery
          </Typography>
        </Box>
        <Box display='flex' pt={2} justifyContent='space-between'>
          <Typography variant='caption'>on road price in banglore</Typography>
          <Typography variant='caption'>161065</Typography>
        </Box>
      </Box>
      <Box mt={2} mb={3}>
        <Divider />
      </Box>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='caption'>0-40 km/h</Typography>
          <Typography variant='caption'>3.3s</Typography>
        </Box>
        <Box display='flex' pt={1} justifyContent='space-between'>
          <Typography variant='caption'>True Range</Typography>
          <Typography variant='caption'>110 km</Typography>
        </Box>
      </Box>
    </>
  )
}

export default SpecModal
