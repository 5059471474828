import React from 'react'
import Box from '@mui/material/Box'
import Success from 'assets/images/success.gif'
import { Typography } from '@mui/material'

export default function SuccessModal() {
  return (
    <Box borderRadius={'20px'} bgcolor={'rgba(81, 179, 167, 0.20)'}>
      <Box
        borderRadius={'20px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent='center'
        bgcolor={'white'}
        py={5}
        px={7}
        flexDirection='column'
      >
        <img src={Success} alt='Success' style={{ width: '4rem', height: '4rem' }} />
        <Typography variant='h2' mt={3}>
          Thank you!
        </Typography>
        <Typography my={2} align='center'>
          {`As soon as we are ready in your city, you'll receive the earliest notification.`}
        </Typography>
      </Box>
      <Box
        py={5}
        px={7}
        display={'flex'}
        flexDirection='column'
        gap={2}
        alignItems={'center'}
        justifyContent='center'
      >
        <Typography align='center' variant='bodyStrong'>
          Looking for quick help?
        </Typography>
        <Typography variant='body' align='center'>
          {`You can reach us out at 1800-00-000 or drop us a message to contact@evfin.co`}
        </Typography>
      </Box>
    </Box>
  )
}
