import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CustomAccordian from 'components/molecules/CustomAccordian'
import useMobileDevice from 'hooks/useMobileDevice'

export default function AccordianSection() {
  const mobile = useMobileDevice()
  const data = [
    {
      summary: 'Now that I have a Sanction Card, how do I proceed?',
      details:
        'With your Sanction card, you can visit the nearest dealership and our Sales Officer will guide you with the next steps.',
    },
    {
      summary: 'Can I change the 2-wheeler /scheme type after getting a Sanction card?',
      details:
        'Yes, you can visit the nearest dealership and modify the 2-wheeler model and financing scheme.',
    },
    {
      summary: 'What is the validity of the Sanction Card?',
      details: '30 Days from the date of issue.',
    },
  ]
  return (
    <Box width='100%' bgcolor='#00221F' borderRadius='20px 20px 0px 0px'>
      <Box
        flexWrap={mobile ? 'wrap' : 'unset'}
        flexDirection='row'
        gap={mobile ? 3 : 12}
        py={7}
        px={mobile ? 6 : 12}
        display='flex'
        justifyContent='space-between'
      >
        <Typography
          variant='h3'
          width={mobile ? '100%' : '400px'}
          mt={3}
          textAlign={mobile ? 'center' : 'left'}
          fontSize='20px'
          color='white'
        >
          What is Sanction Card, and how does it work?
        </Typography>
        <CustomAccordian data={data} />
      </Box>
    </Box>
  )
}
