import React, { useEffect } from 'react'
import { Box, Card, Typography } from '@mui/material'
import useMobileDevice from '../../../../src/hooks/useMobileDevice'
import Card1 from '../../../assets/images/card1.svg'
import Card2 from '../../../assets/images/card2.svg'
import Cards5 from 'assets/images/cards5.svg'

interface PropsInterface {
  children?: JSX.Element[]
  description: string | JSX.Element
  title: string | JSX.Element
  icon: string
}

const Layout = ({ children, description, title, icon }: PropsInterface) => {
  const mobile = useMobileDevice()
  const onBoardingCard = {
    width: mobile ? '70%' : 'auto',
    height: mobile ? 'auto' : '580px',
    top: mobile ? '0%' : '50%',
    position: 'absolute',
    borderRadius: '20px',
    left: '50%',
    transform: mobile ? 'translate(-50%)' : 'translate(-50%, -50%)',
    padding: mobile ? '3rem 1rem' : '0',
    display: 'flex',
    boxShadow: '0px 20px 104px 0px rgba(0, 169, 157, 0.40)',
    background: mobile ? 'white' : 'transparent',
    overflow: 'visible',
    marginTop: mobile ? 14 : 0,
  }

  useEffect(() => {
    window.addEventListener('mousemove', parallax)

    const elem1: HTMLDivElement = document.querySelector('.card1')
    const elem2: HTMLDivElement = document.querySelector(".card2");
    // Magic happens here
    function parallax(e) {
      const _w = window.innerWidth / 2
      const _h = window.innerHeight / 2
      const _mouseX = e.clientX
      const _mouseY = e.clientY
      const _depth1 = `${(_mouseX - _w) * 0.01}% ${(_mouseY - _h) * 0.01}%`
      const _depth2 = `${(_mouseX - _w) * 0.02}% ${(_mouseY - _h) * 0.02}%`
      const _depth3 = `${(_mouseX - _w) * 0.03}% ${(_mouseY - _h) * 0.03}%`
      const x = `${_depth3}, ${_depth2}, ${_depth1}`
      const y = `${_depth3}, ${_depth2}, ${_depth1}`
      elem1.style.backgroundPosition = x
      elem2.style.backgroundPosition = y;
    }

    return () => window.removeEventListener('mousemove', parallax)
  }, [])

  return (
    <Box maxHeight='100vh' display='flex' sx={{overflowY: 'hidden'}}>
      <Box
        width='90%'
        height='50vh'
        sx={{
          transform: 'rotate(90deg)',
          background: mobile
            ? 'linear-gradient(270deg, #51B3A7 19.31%, #000 58.35%)'
            : 'transparent',
          filter: 'blur(37px)',
        }}
      />
      <Card sx={onBoardingCard}>
        <Box
          bgcolor='white'
          display={mobile ? 'block' : 'flex'}
          alignItems={mobile ? 'center' : 'start'}
          flexDirection='column'
          justifyContent='center'
          width={mobile ? '100%' : '460px'}
          borderRadius='20px 0 0 20px'
        >
          <Box borderRadius='20px' px={mobile ? 2 : 10}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems={mobile ? 'center' : 'start'}
              justifyContent='center'
              pr={mobile ? 0 : 7}
            >
              <img
                style={{ width: '59px', marginLeft: mobile ? 0 : icon.includes('otp') ? '-6px' : '-12px' }}
                src={icon}
                alt='Login Image'
              />
              <Typography
                textAlign={mobile ? 'center' : 'start'}
                mb={3}
                mt={mobile ? 3 : 2}
                variant='h2'
              >
                {title}
              </Typography>
              <Typography textAlign={mobile ? 'center' : 'start'} variant='body'>
                {description}
              </Typography>
            </Box>
            <Box
              display='flex'
              width={'100%'}
              flexDirection='column'
              gap={4}
              mt={mobile ? 4 : 6}
              mb={2}
            >
              {children}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: 'linear-gradient(180deg, #003430 29.14%, #000 85.05%)',
          }}
          flexDirection='column'
          display={mobile ? 'none' : 'flex'}
          width='460px'
          overflow='visible'
          borderRadius='0 20px 20px 0'
          position='relative'
        >
          {/* <img
            style={{ width: '90%', marginTop: '80px', zIndex: '1' }}
            src={Card1}
            alt='Smart Card 1'
          />
          <img
            style={{ width: '118%', marginTop: '-236px', marginLeft: '68px' }}
            src={Card2}
            alt='Smart Card 2'
          /> */}
          <Box
            height='200px'
            width='400px'
            p='50px'
            top='70px'
            className='card1'
            position='absolute'
            sx={{
              backgroundImage: `url(${Card1})`,
              backgroundRepeat: 'no-repeat',
              backgroundOrigin: 'content-box',
              backgroundSize: '350px'
            }}
          ></Box>
          <Box
            height='380px'
            width='400px'
            top='140px'
            left='100px'
            p='70px'
            className='card2'
            position='absolute'
            sx={{
              backgroundImage: `url(${Card2})`,
              backgroundRepeat: 'no-repeat',
              backgroundOrigin: 'content-box',
              backgroundSize: '350px'
            }}
          ></Box>
          
          
        </Box>
      </Card>
    </Box>
  )
}
export default Layout
