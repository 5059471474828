import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import BannerGroup from 'assets/images/BannerGroup.svg'
import BannerGroupMobile from 'assets/images/BannerGroupMobile.svg'
import Line1 from 'assets/images/Lines1.svg'
import Line2 from 'assets/images/Lines2.svg'
import Line3 from 'assets/images/Line3.png'
import Line4 from 'assets/images/Line4.svg'
import Line5 from 'assets/images/Line5.svg'
import Line6 from 'assets/images/Line6.svg'
import Person from 'assets/images/Person.png'
import BrandImage from 'assets/images/Brand.png'
import VariantImage from 'assets/images/Variant.png'
import BikeImage from 'assets/images/Bike.png'
import PreferenceImage from 'assets/images/Preference.png'
import CongratsImage from 'assets/images/Congrats.png'
import CustomAccordian from 'components/molecules/CustomAccordian'
import { SMARTFIN } from 'constants/faqConstants'
import useHomePage from './useHomePage'
import CustomModal from 'components/molecules/Modal'
import PincodeModal from 'components/molecules/PincodeModal'
import useMobileDevice from 'hooks/useMobileDevice'
import UnavailablePincodeModal from 'components/molecules/UnavailablePincodeModal'
import SuccessModal from 'containers/pincodePage/successModal'
import Footer from 'components/organisms/footer'
import Heading from 'components/atoms/Heading'

export default function HomePage() {
  const {
    recommendedVehicles,
    isLoading,
    isPincodeModalOpen,
    details,
    isUnavailabilityPincodeOpen,
    isSuccessModalOpen,
    setIsSuccessModalOpen,
    setIsUnavailabilityPincodeOpen,
    handleTestRide,
    setIsPincodeModalOpen,
    onPincodeSubmit,
    handleResumePage,
    handleCheckEligibility,
  } = useHomePage()

  const mobile = useMobileDevice()

  const evfinData = [
    {
      title: '100% EV Focused NBFC',
      description: 'EV-exclusively financing options from NBFCs',
      image: Line4,
    },
    {
      title: 'Customised Financing',
      description: 'Financing solutions that are tailored to your needs.',
      image: Line5,
    },
    {
      title: 'Innovative Services',
      description: 'Our services support every step of your EV ownership journey.',
      image: Line6,
    },
  ]

  return (
    <>
      <Heading />
      <Box mx={mobile ? 2 : 7}>
        <Grid
          height={mobile ? '22.5rem' : '90vh'}
          container
          boxShadow='0px 20px 104px 0px rgba(0, 169, 157, 0.40)'
          borderRadius='0 0 1.25rem 1.25rem'
        >
          <Grid item xs={mobile ? 12 : 5}>
            <Box
              position='relative'
              width='100%'
              height={mobile ? '22rem' : '100%'}
              display={mobile ? 'flex' : 'auto'}
              justifyContent='center'
            >
              <img
                src={mobile ? BannerGroupMobile : BannerGroup}
                alt='logo'
                style={{
                  position: 'absolute',
                  bottom: mobile ? '-5.35rem' : '-4.5rem',
                  left: mobile ? 'auto' : '2.5rem',
                  width: mobile ? '18rem' : 'auto',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={7} display={mobile ? 'none' : 'flex'} alignItems='center'>
            <Box mt={10} ml={20}>
              <Typography variant='h1' color='#FFF' fontSize='3rem' lineHeight='normal'>
                Electric
              </Typography>
              <Typography
                variant='h1'
                maxWidth='23rem'
                fontSize='3rem'
                lineHeight='normal'
                sx={{
                  background: 'linear-gradient(91deg, #fff 42.36%, rgba(255, 255, 255, 0) 108.67%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                For Everyone
              </Typography>
              <Typography
                variant='body'
                component={Typography}
                className='gradientText'
                lineHeight='normal'
                mt={3}
                fontSize='1.25rem'
                maxWidth='400px'
              >
                At evfin, we democratize electric mobility, helping you make the shift to
                sustainable transportation.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box display={mobile ? 'block' : 'none'} mt={8} mx={2} maxWidth='70%'>
          <Typography variant='h1' color='#FFF' fontSize='2rem' lineHeight='normal'>
            Electric
          </Typography>
          <Typography
            variant='h1'
            maxWidth='23rem'
            fontSize='2rem'
            lineHeight='normal'
            sx={{
              background: 'linear-gradient(91deg, #fff 42.36%, rgba(255, 255, 255, 0) 108.67%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            For Everyone
          </Typography>
          <Typography
            variant='caption'
            component={Typography}
            className='gradientText'
            lineHeight='normal'
            mt={3}
            fontSize='14px'
          >
            At evfin, we democratize electric mobility, helping you make the shift to sustainable
            transportation.
          </Typography>
        </Box>
        {details.resume_journey || details.display_sanction_card ? (
          <Box
            height='5.5rem'
            borderRadius='1.25rem'
            mt={7}
            px={mobile ? 3 : 7}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              backgroundImage: `url(${Line3}), linear-gradient(37deg, #00A99D 3.89%, #00655E 99.09%)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}
          >
            <Typography
              color='#E5F6F5'
              variant='body'
              fontWeight={mobile ? '500' : ''}
              fontSize={mobile ? '0.75rem' : '1.25rem'}
              component={Typography}
              maxWidth={mobile ? '12rem' : 'none'}
            >
              {details.display_sanction_card
                ? '🎉 Congratulations on completing your loan eligibility! 🎉'
                : 'Resume your journey from where you dropped?' }
            </Typography>
            <Button
              sx={{
                background: '#FFF',
                color: '#000',
                px: 3,
                '&:hover': { background: '#FFF' },
                fontSize: mobile ? '10px !important' : '',
                fontFamily: 'IBM Plex Sans',
                height: mobile ? '2rem !important' : '',
                width: details.display_sanction_card ? mobile ? '8.75rem'  : 'auto' : 'auto',
              }}
              onClick={handleResumePage}
            >
              {details.display_sanction_card ? 'View Sanction Card': 'Continue'}
            </Button>
          </Box>
        ) : (
          <></>
        )}
        <Box mt={mobile ? 7 : 9}>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography
              variant='body2'
              fontFamily='Archivo Expanded'
              fontSize={mobile ? '0.75rem' : '1.25rem'}
              color='#DCDCDC'
              ml={mobile ? '1rem' : '2.25rem'}
              textAlign='left'
            >
              Test ride your favourite EV
            </Typography>
            <Button sx={{ px: mobile ? 3 : 4 }} onClick={handleTestRide}>
              Test ride
            </Button>
          </Box>
          <Box
            my={4}
            display='flex'
            className={`${mobile ? 'hide-scrollbar' : ''}`}
            sx={{ overflowX: mobile ? 'auto' : 'visible' }}
          >
            {recommendedVehicles.map((data) => (
              <Box key={data.displayName} width={mobile ? 'auto' : '25%'}>
                <Card
                  style={{
                    padding: '24px 20px',
                    borderRadius: '10px',
                    margin: '8px',
                    boxShadow: 'none',
                    minWidth: '200px',
                  }}
                >
                  <Typography
                    variant='h3'
                    style={{
                      overflow: 'hidden',
                      // whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      height: '45px',
                      fontSize: '14px',
                    }}
                    color='#505D68'
                    textAlign='center'
                    fontWeight={500}
                  >
                    {data?.displayName}
                  </Typography>
                  <img
                    src={data?.image}
                    alt='Scooter'
                    style={{
                      width: '128px',
                      height: '128px',
                      marginTop: '15px',
                      marginBottom: '25px',
                      border: '1px solid rgba(8, 44, 41, 0.10)',
                      borderRadius: '90px',
                      boxShadow: ' 0px 8px 24px 0px rgba(8, 44, 41, 0.05)',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      display: 'block',
                    }}
                  />
                  <Box display='flex' justifyContent='space-between' alignItems='center' mx={4}>
                    <Box>
                      <Typography component={Typography} variant='caption' fontSize='10px'>
                        Top Speed
                      </Typography>
                      <Typography
                        component={Typography}
                        variant='caption'
                        mt={1}
                        mb={2}
                        color='#00221F'
                        fontWeight={500}
                      >
                        {data.displaySubText.split(' | ')[1].split('top speed')[0].split(' kph')[0]}{' '}
                        km/h
                      </Typography>
                    </Box>
                    <Box>
                      <Typography component={Typography} variant='caption' fontSize='10px'>
                        True Range
                      </Typography>
                      <Typography
                        component={Typography}
                        variant='caption'
                        mt={1}
                        mb={2}
                        color='#00221F'
                        fontWeight={500}
                      >
                        {data.displaySubText.split(' | ')[0].split('range')[0]}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          height='300px'
          borderRadius='1.25rem'
          sx={{ background: 'linear-gradient(12deg, #00A99D 3.89%, #B0D456 99.09%)' }}
          position='relative'
          display='flex'
          my={mobile ? 7 : 9}
        >
          <img
            src={Line1}
            style={{ top: 0, left: 0, position: 'absolute', display: mobile ? 'none' : 'block' }}
          />
          <img
            src={Line2}
            style={{
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: mobile ? 'none' : 'block',
            }}
          />
          <img
            src={Person}
            style={{
              bottom: 0,
              left: mobile ? 'auto' : '50%',
              right: mobile ? '0' : 'auto',
              transform: mobile ? 'none' : 'translate(-50%)',
              margin: '0 auto',
              position: 'absolute',
            }}
          />
          <Grid
            container
            p={mobile ? '2rem 1.5rem' : 0}
            alignItems={mobile ? 'flex-start' : 'center'}
            zIndex='5'
          >
            <Grid item xs={mobile ? 12 : 8}>
              <Typography
                variant='h1'
                lineHeight='normal'
                color='#FFF'
                fontSize={mobile ? '1.25rem' : '2rem'}
                ml={mobile ? 0 : 10}
                maxWidth={mobile ? '60%' : '300px'}
              >
                Get your new EV finance with a few clicks
              </Typography>
            </Grid>
            <Grid item xs={mobile ? 12 : 4}>
              <Typography
                variant={mobile ? 'caption' : 'body1'}
                component={Typography}
                fontSize={mobile ? '14px' : '1.5rem'}
                color='#FFF'
                maxWidth={mobile ? '60%' : '300px'}
                lineHeight='normal'
              >
                Why you should start your electric journey with evfin.
              </Typography>
              <Button
                onClick={handleCheckEligibility}
                sx={{
                  color: '#00655E',
                  background: '#FFF',
                  px: 2,
                  mt: 4,
                  '&:hover': { background: '#FFF' },
                }}
              >
                Check Eligibility
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography
            variant='body2'
            fontFamily='Archivo Expanded'
            fontSize={mobile ? '0.75rem' : '1.25rem'}
            color='#DCDCDC'
            ml={mobile ? 0 : '2.25rem'}
            textAlign={mobile ? 'center' : 'left'}
          >
            Why evfin?
          </Typography>
          <Box
            display='flex'
            className={`${mobile ? 'hide-scrollbar' : ''}`}
            sx={{ overflowX: mobile ? 'auto' : 'visible' }}
            mt={4}
            gap={2}
          >
            {evfinData.map((data, index) => (
              <Box key={index} width={mobile ? 'auto' : '33.3333336%'}>
                <Box bgcolor='#00221F' borderRadius='0.75rem' height='100%' position='relative'>
                  <Box p='2.75rem 2rem 4rem 2rem' minHeight='200px' minWidth='200px'>
                    <Typography
                      variant='h1'
                      fontSize='2rem'
                      className='gradientText1'
                      maxWidth='300px'
                    >
                      {data.title}
                    </Typography>
                    <Typography
                      component={Typography}
                      variant='body1'
                      mt={mobile ? 1.5 : 5}
                      lineHeight='normal'
                      fontSize={mobile ? '14px' : '1rem'}
                      color='#DCDCDC'
                      maxWidth={mobile ? '11rem' : '14.5rem'}
                    >
                      {data.description}
                    </Typography>
                  </Box>
                  <img
                    src={data.image}
                    style={{ bottom: 0, position: 'absolute', width: '100%' }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box position='relative' mt={mobile ? 7 : 9}>
          <Typography
            variant='body2'
            fontFamily='Archivo Expanded'
            fontSize={mobile ? '0.75rem' : '1.25rem'}
            color='#DCDCDC'
            ml={mobile ? 0 : '2.25rem'}
            textAlign={mobile ? 'center' : 'left'}
          >
            How it works
          </Typography>
          <Grid
            container
            width='100%'
            height={mobile ? '12.5rem' : '39rem'}
            mt={4}
            borderRadius={5}
            bgcolor='#E5F6F5'
            position='relative'
          >
            <Box
              position='absolute'
              left={mobile ? '15%' : '50%'}
              top='-24px'
              width='3rem'
              height='3rem'
              border='1px solid #05B7A5'
              borderRadius='50%'
              bgcolor='#00221F'
              color='#E5F6F5'
              display='flex'
              alignItems='center'
              justifyContent='center'
              fontFamily='Archivo expanded'
              sx={{
                transform: 'translate(-50%)',
              }}
            >
              1
            </Box>
            <Grid
              item
              xs={mobile ? 12 : 5}
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
            >
              <Box display={mobile ? 'flex' : 'block'} px={mobile ? '1.5rem' : '0'} gap={2}>
                <Typography
                  variant='h1'
                  fontSize={mobile ? '1.25rem' : '36px'}
                  lineHeight='normal'
                  maxWidth='12.5rem'
                  color='#00221F'
                  width={mobile ? '50%' : 'auto'}
                >
                  Choose Your Ride
                </Typography>
                <Typography
                  variant='caption'
                  maxWidth='14rem'
                  color='#282828CC'
                  fontSize={mobile ? '14px' : '1.25rem'}
                  lineHeight='normal'
                  component={Typography}
                  mt={mobile ? 0 : 2}
                  width={mobile ? '50%' : 'auto'}
                >
                  Tell us about the EV that you want the most.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={7} display={mobile ? 'none' : 'block'}>
              <Box
                height='85%'
                display='flex'
                alignItems='flex-end'
                justifyContent='center'
                position='relative'
              >
                <Box
                  sx={{
                    opacity: '0.3',
                    background: '#00655E',
                    mixBlendMode: 'darken',
                    filter: 'blur(77px)',
                    position: 'absolute',
                    bottom: '40px',
                    left: '50%',
                    transform: 'translate(-50%)',
                    width: '70%',
                    height: '50%',
                    zIndex: '1',
                  }}
                ></Box>
                <img
                  src={BrandImage}
                  alt='image that showing brands'
                  style={{ maxHeight: '440px', marginRight: '-40px', zIndex: '2' }}
                />
                <img
                  src={VariantImage}
                  alt='image that showing bike varinats'
                  style={{ maxHeight: '280px', marginRight: '-40px', zIndex: '2' }}
                />
                <img
                  src={BikeImage}
                  alt='image that showing bike varinats'
                  style={{ maxHeight: '380px', zIndex: '2' }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            width='100%'
            height={mobile ? '12.5rem' : '39rem'}
            mt={1}
            borderRadius={5}
            bgcolor='#FFF'
            position='relative'
          >
            <Box
              position='absolute'
              left={mobile ? '15%' : '50%'}
              top='-24px'
              width='3rem'
              height='3rem'
              border='1px solid #05B7A5'
              borderRadius='50%'
              bgcolor='#00221F'
              color='#E5F6F5'
              display='flex'
              alignItems='center'
              justifyContent='center'
              fontFamily='Archivo expanded'
              sx={{
                transform: 'translate(-50%)',
              }}
            >
              2
            </Box>
            <Grid
              item
              xs={6}
              display={mobile ? 'none' : 'flex'}
              alignItems='center'
              justifyContent='center'
            >
              <Box
                height='85%'
                display='flex'
                alignItems='flex-end'
                justifyContent='center'
                position='relative'
              >
                <Box
                  sx={{
                    opacity: '0.3',
                    background: '#00655E',
                    mixBlendMode: 'darken',
                    filter: 'blur(77px)',
                    position: 'absolute',
                    bottom: '40px',
                    left: '50%',
                    transform: 'translate(-50%)',
                    width: '100%',
                    height: '50%',
                    zIndex: '1',
                  }}
                ></Box>
                <img
                  src={PreferenceImage}
                  alt='image that showing user to select loan amount'
                  style={{ maxHeight: '420px', zIndex: '2' }}
                />{' '}
              </Box>
            </Grid>
            <Grid
              item
              xs={mobile ? 12 : 6}
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
            >
              <Box display={mobile ? 'flex' : 'block'} px={mobile ? '1.5rem' : '0'} gap={2}>
                <Typography
                  variant='h1'
                  maxWidth='21rem'
                  color='#00221F'
                  fontSize={mobile ? '1.25rem' : '36px'}
                  lineHeight='normal'
                  width={mobile ? '50%' : 'auto'}
                >
                  Choose Payment Terms
                </Typography>
                <Typography
                  variant='caption'
                  maxWidth='14rem'
                  color='#282828CC'
                  fontSize={mobile ? '14px' : '1.25rem'}
                  lineHeight='normal'
                  component={Typography}
                  mt={mobile ? 0 : 2}
                  width={mobile ? '50%' : 'auto'}
                >
                  Decide the terms of your repayment.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            width='100%'
            height={mobile ? '12.5rem' : '39rem'}
            mt={1}
            borderRadius={5}
            bgcolor='#E5F6F5'
            position='relative'
          >
            <Box
              position='absolute'
              left={mobile ? '15%' : '50%'}
              top='-24px'
              width='3rem'
              height='3rem'
              border='1px solid #05B7A5'
              borderRadius='50%'
              bgcolor='#00221F'
              color='#E5F6F5'
              display='flex'
              alignItems='center'
              justifyContent='center'
              fontFamily='Archivo expanded'
              sx={{
                transform: 'translate(-50%)',
              }}
            >
              3
            </Box>
            <Grid
              item
              xs={mobile ? 12 : 6}
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
            >
              <Box display={mobile ? 'flex' : 'block'} px={mobile ? '1.5rem' : '0'} gap={2}>
                <Typography
                  variant='h1'
                  maxWidth='18rem'
                  color='#00221F'
                  fontSize={mobile ? '1.25rem' : '36px'}
                  lineHeight='normal'
                  width={mobile ? '50%' : 'auto'}
                >
                  Calculate your Eligibility
                </Typography>
                <Typography
                  variant='caption'
                  maxWidth='18rem'
                  color='#282828CC'
                  fontSize={mobile ? '14px' : '1.25rem'}
                  lineHeight='normal'
                  component={Typography}
                  mt={mobile ? 0 : 2}
                  width={mobile ? '50%' : 'auto'}
                >
                  Instantly calculate the loan amount that you qualify for.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} display={mobile ? 'none' : 'block'}>
              <Box
                height='85%'
                display='flex'
                alignItems='flex-end'
                justifyContent='center'
                position='relative'
              >
                <Box
                  sx={{
                    opacity: '0.3',
                    background: '#00655E',
                    mixBlendMode: 'darken',
                    filter: 'blur(77px)',
                    position: 'absolute',
                    bottom: '40px',
                    left: '50%',
                    transform: 'translate(-50%)',
                    width: '70%',
                    height: '50%',
                    zIndex: '1',
                  }}
                ></Box>
                <img
                  src={CongratsImage}
                  alt='image that showing brands'
                  style={{ maxHeight: '420px', marginRight: '-40px', zIndex: 2 }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        bgcolor='#00221F'
        py={10}
        borderRadius='1.25rem 1.25rem 0 0'
        display='flex'
        justifyContent='space-around'
        flexDirection={mobile ? 'column' : 'row'}
        mt={9}
        px={mobile ? '2rem' : '12.5rem'}
      >
        <Box maxWidth={mobile ? '100%' : '40%'} position={mobile ? 'static' : 'sticky'} top='40px' height='100%'>
          <Typography
            variant='h2'
            mt={2}
            fontSize='1.25rem'
            color='#FFF'
            textAlign={mobile ? 'center' : 'left'}
          >
            What is EVfin
          </Typography>
          <Typography
            variant='h2'
            fontSize='1.25rem'
            color='#FFF'
            textAlign={mobile ? 'center' : 'left'}
          >
            and how does it work?
          </Typography>
        </Box>
        <Box maxWidth={mobile ? '100%' : '40%'}>
          <CustomAccordian data={SMARTFIN} />
        </Box>
      </Box>
      <CustomModal padding={4} open={isPincodeModalOpen} setOpen={setIsPincodeModalOpen}>
        <PincodeModal
          isLoading={isLoading}
          onSubmit={onPincodeSubmit}
          title={'Pincode / City'}
          description={
            'Provide your pincode / city where you are planning to purchase your vehicle'
          }
        />
      </CustomModal>

      <CustomModal
        padding={4}
        open={isUnavailabilityPincodeOpen}
        setOpen={setIsUnavailabilityPincodeOpen}
      >
        <UnavailablePincodeModal
          setOpen={setIsUnavailabilityPincodeOpen}
          setSuccessModalOpen={setIsSuccessModalOpen}
          isPincodeLoading={isLoading}
          onPincodeSubmit={onPincodeSubmit}
          title={'Sorry, currently we are not in your city.'}
          description={`Explore nearby city pincodes or leave your details, we'll notify you upon launch.`}
          addressLocation='top'
        />
      </CustomModal>

      <CustomModal padding={0} open={isSuccessModalOpen} setOpen={setIsSuccessModalOpen}>
        <SuccessModal />
      </CustomModal>
      <Footer mobile={mobile} />
    </>
  )
}
