import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Card } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { theme } from 'muiTheme/customTheme'
import SanctionCard from 'components/molecules/Cards/SanctionCard'

interface Props {
  mobile: boolean
  isExpired?: boolean
}
export default function VehicleChoiceSection({ mobile, isExpired }: Props) {
  const mob = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      width='100%'
      flexWrap={mobile ? 'wrap' : 'unset'}
      sx={{ display: isExpired ? 'flex' : mobile ? 'flex' : 'none' }}
      my={2}
      gap={3}
      justifyContent='space-between'
      position='relative'
    >
      <Typography
        variant='h2'
        fontSize={mobile ? '12px' : '20px'}
        color='#00655E'
        textAlign={mobile ? 'center' : 'left'}
        width={mobile ? '100%' : '-webkit-fill-available'}
      >
        Your Vehicle choice
      </Typography>

      <Card
        sx={{
          padding: '20px 25px',
          width: mob ? '87%' : mobile ? '93%' : '700px',
          borderRadius: '10px',
          background: isExpired ? '#f7f7f7' : 'white',
        }}
      >
        <SanctionCard isExpired={isExpired} />
      </Card>
    </Box>
  )
}
