import useMobileDevice from 'hooks/useMobileDevice'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import vehicleService from 'service/vechicleService'
import ReactGA from 'react-ga4'

interface ModelInterface {
  vehicleId: string
  losVehicleId: string
  variant: string
  displayName: string
  displaySubText: string
  brandLogo: string
  image: string
  primaryText: string
  value: string
}

interface ModelsInterface {
  [brandName: string]: ModelInterface[]
}

interface FinanceInterface {
  name: string
  flag: boolean
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  setSuccessModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const useFinance = ({ name, flag, setModalOpen, setSuccessModalOpen }: FinanceInterface) => {
  const addressData = JSON.parse(localStorage.getItem('addressData'))
  const canEditCity = JSON.parse(localStorage.getItem('canEditCity'))
  const [models, setModels] = useState<ModelsInterface>({})
  const [selectedBrand, setSelectedBrand] = useState<string>('')

  const [selectedModel, setSelectedModel] = useState<ModelInterface>({
    vehicleId: '',
    losVehicleId: '',
    variant: '',
    displayName: '',
    displaySubText: '',
    brandLogo: '',
    image: '',
    primaryText: '',
    value: '',
  })
  const [brandOptions, setBrandOptions] = useState([])
  const [modelOptions, setModelOptions] = useState([])
  const [showModels, setShowModels] = useState(false)
  const [showBrands, setShowBrands] = useState(false)
  const [showVechicleSelection, setShowVechicleSelection] = useState(false)
  const [selectedVechicle, setSelectedVechicle] = useState({})
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [userName, setName] = useState(name)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const mobile = useMobileDevice()
  const {
    control,
    formState: { errors },
  } = useForm({ mode: 'onChange' })

  useEffect(() => {
    vehicleService
      .getVehicleDetails(addressData)
      .then((res) => {
        const data = res.data.data.vehicleMap
        const newOptions = []
        const modifiedModels = {}
        for (const brand in data) {
          modifiedModels[brand] = []
          for (const model in data[brand]) {
            for (const variant in data[brand][model]) {
              modifiedModels[brand].push({
                vehicleId: data[brand][model][variant].vehicleId,
                losVehicleId: data[brand][model][variant].losVehicleId,
                variant: brand + ' ' + model + ' ' + data[brand][model][variant].variant,
                displayName: data[brand][model][variant].displayName,
                displaySubText: data[brand][model][variant].displaySubText,
                brandLogo: data[brand][model][variant].brandLogo,
                image: data[brand][model][variant].image,
              })
            }
          }
          newOptions.push({
            primaryText: brand,
            value: brand,
            secondaryText:
              modifiedModels[brand].length > 1
                ? `${modifiedModels[brand].length} variants available`
                : `${modifiedModels[brand].length} variant available`,
          })
        }
        setBrandOptions([...newOptions])
        setModels(modifiedModels)
        setIsPageLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [addressData?.city])

  useEffect(() => {
    const newModelOptions = []
    models[selectedBrand]?.forEach((model) => {
      newModelOptions.push({
        primaryText: model.displayName,
        subText: model.displaySubText,
        value: model.vehicleId,
        vehicleId: model.vehicleId,
        losVehicleId: model.losVehicleId,
      })
    })
    setModelOptions([...newModelOptions])
  }, [selectedBrand])

  function handleBrandChange(option) {
    setSelectedBrand(option.primaryText)
    setSelectedModel({
      vehicleId: '',
      losVehicleId: '',
      variant: '',
      displayName: '',
      displaySubText: '',
      brandLogo: '',
      image: '',
      primaryText: '',
      value: '',
    })
  }

  function handleSelectVariant() {
    setShowBrands(false)
    setShowModels(true)
  }

  function handleModelChange(option) {
    setSelectedModel(option)
  }

  function handleVariantSelected() {
    setShowModels(false)
  }

  const onVehicleSubmit = async () => {
    ReactGA.event({
      category: 'Scooter Selection',
      action: 'Brand and varient form',
      label: 'Brand and varient submitted',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    const data = {
      availability: !flag ? true : false,
      city: addressData.city,
      customerName: userName || null,
      loanApplicationId: null,
      losVehicleId: selectedModel.losVehicleId,
      pinCode: addressData.postalCode,
      selectedFor: 'LOAN_APP',
      vehicleId: selectedModel.vehicleId,
    }
    setIsLoading(true)
    try {
      const response = await vehicleService.saveVehicleDetails(data)
      setIsLoading(false)
      setShowVechicleSelection(true)

      setSelectedVechicle(response.data.data.vehicle_pricing_details)
      if (flag) {
        setModalOpen(false)
        setSuccessModalOpen(true)
      }
      // navigate(routes.VERIFYOTP)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  return {
    mobile,
    isLoading,
    addressData,
    open,
    brandOptions,
    modelOptions,
    showVechicleSelection,
    showBrands,
    showModels,
    selectedVechicle,
    selectedBrand,
    setOpen,
    setShowBrands,
    setShowModels,
    setShowVechicleSelection,
    handleBrandChange,
    handleModelChange,
    onVehicleSubmit,
    handleSelectVariant,
    handleVariantSelected,
    control,
    errors,
    setName,
    userName,
    selectedModel,
    isPageLoading,
    canEditCity,
  }
}

export default useFinance
