import React, { useState } from 'react'
import useVechicleSelection from './useVechicleSelection'
import closeIcon from 'assets/images/close icon.svg'
import { Box, Button, Card, Drawer, Typography } from '@mui/material'
import PiggyBank from 'assets/images/piggyBank.gif'
import OffersModel from './OffersModel'
import Slider from 'react-slick'
import NoImageAvailable from 'assets/images/noImageAvailable.png'
import ReactGA from 'react-ga4'

interface VechicleSelectionInterface {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedVechicle: any
}

export default function VechicleSelection({
  open,
  setOpen,
  selectedVechicle,
}: VechicleSelectionInterface) {
  const {
    mobile,
    offersData,
    addressData,
    isOffersModelOpen,
    currentSlide,
    setIsOffersModelOpen,
    setCurrentSlide,
    getPriceFormat,
    onContinue,
  } = useVechicleSelection(selectedVechicle)

  const settings = {
    className: 'center',
    arrows: false,
    dots: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    initialSlide: 0,
    infinite: false,
    centerPadding: '20px',
    beforeChange: (prev, next) => {
      setCurrentSlide(next)
    },
    appendDots: (dots) => {
      return (
        <div style={{ marginTop: '20px' }}>
          <ul style={{ padding: '0' }}>
            {dots.map((item, index) => {
              return (
                <li
                  key={index}
                  style={{
                    marginRight: index === currentSlide ? '50px' : '10px',
                  }}
                >
                  {item.props.children}
                </li>
              )
            })}
          </ul>
        </div>
      )
    },
    customPaging: (i) => (
      <div
        style={{
          width: i === currentSlide ? '60px' : '20px',
          height: '20px',
          // borderRight: i === currentSlide ? '30px solid #DCDCDC' : '0',
          marginTop: '1rem',
          borderRadius: '10px',
          backgroundColor: i === currentSlide ? '#DCDCDC' : '#00A99D',
          opacity: i === currentSlide ? '1' : '0.6',
          transition: 'all 0.3s ease-in-out',
        }}
      ></div>
    ),
  }

  return (
    <>
      <Drawer
        anchor={mobile ? 'bottom' : 'right'}
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            width: mobile ? '100%' : '530px',
            background: mobile
              ? 'linear-gradient(0deg, rgba(8, 44, 41, 0.90) 8.82%, rgba(0, 0, 0, 0.90) 97.72%)'
              : 'linear-gradient(109deg, #00221F -0.11%, #000 86.23%)',
            backdropFilter: mobile ? 'blur(5px)' : 'blur(30px)',
            // overflowY: 'auto',
            height: '100%',
          },
        }}
      >
        <Box display='flex' justifyContent='end' alignItems='center'>
          <img
            src={closeIcon}
            alt='close icon'
            onClick={() => setOpen(false)}
            style={{
              width: '2rem',
              height: '2rem',
              marginRight: mobile ? '1rem' : '2rem',
              marginTop: '1.5rem',
              cursor: 'pointer',
            }}
          />
        </Box>
        <Card
          sx={{
            borderRadius: '1.25rem',
            mt: mobile ? 1 : 3,
            mb: 5,
            mx: mobile ? 2 : 4,
            overflow: 'unset',
          }}
        >
          <Typography variant='h2' textAlign='center' px={mobile ? 11 : 16} py={4}>
            {selectedVechicle.brand} is a great choice
          </Typography>
          <Box
            borderRadius={4}
            border='1px solid rgba(81, 179, 167, 0.20)'
            boxShadow='0px 10px 24px 0px rgba(0, 169, 157, 0.10)'
            width='100%'
            color='rgba(0, 73, 67, 0.90)'
          >
            <Box pt={3} display='flex' justifyContent='space-between' alignItems='center' px={6}>
              <Box textAlign='left'>
                <Typography textAlign='left' variant='h3' color='rgba(0, 73, 67, 0.90)'>
                  {selectedVechicle.displayName}
                </Typography>
                <Typography textAlign='left' variant='caption'>
                  {selectedVechicle.displaySubText || ''}
                </Typography>
              </Box>
              <Box>
                <img src={selectedVechicle.brandLogo} alt='logo' style={{ width: '5.5rem' }} />
              </Box>
            </Box>
            <Box my={4} mx='auto' height='9rem' display='flex'>
              <img
                src={selectedVechicle.image ? selectedVechicle.image : NoImageAvailable}
                alt='bike image'
                style={{
                  margin: 'auto',
                  width: '9rem',
                  height: '9rem',
                  borderRadius: '50%',
                  border: '1px solid rgba(8, 44, 41, 0.10)',
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Typography variant='caption' display='flex' justifyContent='center'>
              Avg on road price in {addressData?.city}
            </Typography>
            <Typography variant='h2' mt={1} textAlign='center' color='rgba(0, 73, 67, 0.90)'>
              {`₹ ${getPriceFormat(selectedVechicle?.onRoadPrice)}`}
            </Typography>
            <Box display='flex' justifyContent='center' onClick={onContinue}>
              <Button
                onClick={() => {
                  ReactGA.event({
                    category: 'Scooter Modal',
                    action: 'View selected scooter',
                    label: 'Continue',
                  })
                  ReactGA.set({ timestamp: new Date().toISOString() })
                }}
                sx={{ my: 5, width: '16.25rem' }}
              >
                Looks good
              </Button>
            </Box>
          </Box>
        </Card>
        {mobile && offersData.length > 0 && (
          <Box py={6.25} bgcolor='#00655E' borderRadius={1.25} position='relative'>
            <Box
              position='absolute'
              top='-1.25rem'
              left='1rem'
              pl={2}
              pr={5}
              py={1}
              display='flex'
              justifyContent='center'
              alignItems='center'
              color='#00655E'
              bgcolor='#E5F6F5'
              borderRadius={5}
            >
              {offersData.length} {offersData.length > 1 ? 'offers' : 'offer'} available
            </Box>
            <Slider {...settings} style={{ paddingBottom: '1rem' }}>
              {offersData.map((offer, index) => (
                <Card
                  key={index}
                  sx={{
                    background: '#00221F',
                    borderRadius: 2.5,
                    border: '1px solid rgba(81, 179, 167, 0.20)',
                    backdropFilter: 'blur(20px)',
                    color: '#fff',
                    px: 5,
                    py: 4,
                    maxWidth: offersData.length > 1 ? 'calc(100vw - 150px)' : 'calc(100vw - 120px)',
                  }}
                >
                  <Typography variant='body'>{offer.upsellText}</Typography>
                  {/* <Typography variant='body' color='#00A99D'>
                    Learn more
                  </Typography> */}
                  <Box display='flex' alignItems='center' justifyContent='start' mt={2.5}>
                    {/* <Button
                      sx={{
                        fontFamily: "'IBM Plex Sans', sans-serif",
                        height: '2.3rem !important',
                        px: 1.25,
                      }}
                    >
                      Check Eligibility
                    </Button> */}
                    <Typography fontWeight='500'>{offer.productName}</Typography>
                  </Box>
                </Card>
              ))}
            </Slider>
          </Box>
        )}
      </Drawer>
      {!mobile && open && offersData.length > 0 && (
        <Box
          position='fixed'
          bottom='30px'
          left='30px'
          // width='267px'
          // height='97px'
          borderRadius={4}
          bgcolor='#E5F6F5'
          px={3.75}
          py={2.25}
          display='flex'
          justifyContent='center'
          alignItems='center'
          zIndex='1300'
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setIsOffersModelOpen(true)
            ReactGA.event({
              category: 'Offers Modal',
              action: 'View Offers',
              label: 'Offers Details',
            })
            ReactGA.set({ timestamp: new Date().toISOString() })
          }}
        >
          <Box bgcolor='#fff' display='flex' borderRadius='50%' mr={2}>
            <img
              src={PiggyBank}
              alt='piggy bank'
              style={{ margin: 'auto', width: '1.5rem', height: '1.5rem', padding: '0.875rem' }}
            />
          </Box>
          <Typography color='#00655E' variant='body' fontFamily='Archivo Expanded'>
            {offersData.length} {offersData.length > 1 ? 'offers' : 'offer'} available
          </Typography>
        </Box>
      )}
      <OffersModel open={isOffersModelOpen} setOpen={setIsOffersModelOpen} data={offersData} />
    </>
  )
}
