import React from 'react'
import SpecModal from './SpecModal'
import CustomModal from 'components/molecules/Modal'
import Button from '@mui/material/Button'

const PerfectScooter = () => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <Button onClick={() => setOpen(true)}>open</Button>
      <CustomModal padding={4} open={open} setOpen={setOpen}>
        <SpecModal />
      </CustomModal>
    </>
  )
}

export default PerfectScooter
