import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import useNotEligible from './useNotEligible'
import SadFace from 'assets/images/sadFace.gif'
import Footer from 'components/organisms/footer'
import Heading from 'components/atoms/Heading'
import LeftSection from 'components/molecules/LeftSection'
import ReferAFriend from 'components/molecules/ReferAFriend'

export default function LoanNotEligible() {
  const { mobile } = useNotEligible()
  return (
    <>
      <Heading />
      <LeftSection>
        <Grid
          item
          bgcolor={mobile ? '' : '#fff'}
          justifyContent='center'
          xs={mobile ? 12 : 7}
          maxHeight='100vh'
          sx={{ overflowY: 'auto' }}
        >
          <Box
            textAlign='center'
            borderRadius={mobile ? 5 : 0}
            bgcolor={mobile ? '#fff' : ''}
            maxWidth={mobile ? '100%' : '20rem'}
            mx='auto'
            py={mobile ? 4.5 : 6.5}
          >
            <img
              src={SadFace}
              alt='sorry'
              style={{ width: '3rem', height: '3rem', margin: 'auto', paddingBottom: '1rem' }}
            />
            <Typography variant='h2' color='#082f2f'>
              Oops! Not Eligible.
            </Typography>
            <Typography variant='body' mt={1} color='rgba(0, 73, 67, 0.90)' sx={{ opacity: '0.8' }}>
              We regret to inform you that we couldnt approve your loan at this time. We
              understand this might be disappointing, so we want to provide you with some clarity.
            </Typography>
            <Typography variant='h2' mb={2} mt={5.5} color='#00a99de6'>
              Credit Score
            </Typography>
            <Typography variant='body' color='#00655e99'>
              Your credit score did not meet the required threshold, indicating potential concerns
              about your ability to repay the loan.
            </Typography>
            <Typography variant='h2' mb={2} mt={5} color='#00a99de6'>
              Income Stability
            </Typography>
            <Typography variant='body' color='#00655e99'>
              The stability of your income sources might not have fully satisfied the loan criteria,
              raising doubts about your capacity to manage repayments
            </Typography>
            <Typography variant='h2' mb={2} mt={5} color='#00a99de6'>
              Debt-to-Income Ratio
            </Typography>
            <Typography variant='body' color='#00655e99'>
              The ratio between your existing debts and your income may have been too high,
              suggesting a higher risk in managing additional loan obligations.
            </Typography>
          </Box>
          <Card
            sx={{
              background: mobile ? 'transparent' : '#00221F',
              borderRadius: mobile ? '' : '1.25rem 1.25rem 0 0',
              padding: mobile ? 0 : '3.375rem 4.5rem',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <ReferAFriend />
          </Card>
          <Footer mobile={mobile} />
        </Grid>
      </LeftSection>
    </>
  )
}
