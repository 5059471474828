import { useEffect, useState } from 'react'
import { getPriceFormat } from 'utils/function'
import useMobileDevice from 'hooks/useMobileDevice'
import offerService from 'service/offerService'
import { toast } from 'react-hot-toast'
import vehicleService from 'service/vechicleService'
import { useNavigate } from 'react-router-dom'
import { routes } from 'constants/routes'

interface EmiOptionsInterface {
  tenure: number
  emi: number
  roi: number
  isPreferred: boolean
  preferredTenureTag: string
  isSelected: boolean
  upgradeOptions: number
}

interface LoanProductInterface {
  productKey: string
  productName: string
  description: string
  isDefault: boolean
  bestAvailablePlan: boolean
  promoTags: string[]
  keyBenefits: string[]
  productId: number
  emiOptions: EmiOptionsInterface[]
}

interface UpsellProductInterface {
  productId: number
  productKey: string
  productName: string
  upsellOrder: number
  upsellText: string
}

interface ActiveOfferInterface {
  productPreferenceId: number
  eligibleAmount: number
  offerId: number
  loanProduct: LoanProductInterface
  upsellProduct: UpsellProductInterface[]
}

interface OtherOfferInterface {
  productPreferenceId: number
  eligibleAmount: number
  offerId: number
  loanProducts: LoanProductInterface[]
}

interface OffersDataInterface {
  productId: number
  productKey: string
  productName: string
  upsellOrder: number
  upsellText: string
}

interface SelectedEmiInterface {
  tenure: number
  emi: number
}

const usePlanOption = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [activeOffer, setActiveOffer] = useState<ActiveOfferInterface>()
  const [otherOffer, setOtherOffer] = useState<OtherOfferInterface>()
  const [emiOptions, setEmiOptions] = useState<EmiOptionsInterface[]>([])
  const [offersData, setOffersData] = useState<OffersDataInterface[]>([])
  const [isOtherOfferAvailable, setIsOtherOfferAvailable] = useState(false)
  const [selectedEmi, setSelectedEmi] = useState<SelectedEmiInterface>()
  const [openEdit, setOpenEdit] = useState(false)
  const [isUpgradeOffersModalOpen, setIsUpgradeOffersModalOpen] = useState(false)
  const [planDetails, setPlanDetails] = useState({
    planId: '',
    showButton: '',
    isOpen: false
  })

  const mobile = useMobileDevice()
  const navigate = useNavigate()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setIsLoading(true)
    offerService
      .getActiveOffer()
      .then((res) => {
        // const data = res.data.data
        // data.loanProduct.isDefault = false
        setActiveOffer(res.data.data)
        setEmiOptions(res.data.data.loanProduct.emiOptions)
        const emiOptions = res.data.data.loanProduct.emiOptions
        emiOptions.forEach((option) => {
          if (option.isSelected) {
            setSelectedEmi(option)
          }
        })
        if (res.data.data.loanProduct.isDefault === false) {
          offerService.getOtherOffer().then((res) => {
            if (res.data.data?.loanProducts?.length) {
              setIsOtherOfferAvailable(true)
            }
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })

    vehicleService
      .getVehicleOffers()
      .then((res) => {
        setOffersData(res.data.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleModalClose = () => {
    setOpenEdit(false)
  }

  function handleEmiOptionChange(index) {
    const modifiedEmiOptions = [...emiOptions]
    modifiedEmiOptions.forEach((option, i) => {
      if (i === index) {
        option.isSelected = true
      } else {
        option.isSelected = false
      }
    })
    modifiedEmiOptions[index].isSelected = true
    setSelectedEmi(modifiedEmiOptions[index])
    setEmiOptions([...modifiedEmiOptions])
  }

  function handleOtherEmiOptionChange(productIndex, emiIndex) {
    setSelectedEmi(otherOffer.loanProducts[productIndex].emiOptions[emiIndex])
    const modifiedOffer = { ...otherOffer }
    modifiedOffer.loanProducts.forEach((product) => {
      product.emiOptions.map((option) => {
        option.isSelected = false
      })
    })
    modifiedOffer.loanProducts[productIndex].emiOptions[emiIndex].isSelected = true
    setOtherOffer({ ...modifiedOffer })
  }

  function handleOfferChange(productId) {
    setIsLoading(true)
    offerService
      .getUpdateProduct(productId)
      .then((res) => {
        navigate(routes.CONGRATS)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function handleClaimOffer() {
    const data = {
      emi: selectedEmi.emi,
      tenure: selectedEmi.tenure,
    }
    offerService
      .postClaimOffer(activeOffer?.productPreferenceId, data)
      .then(() => {
        navigate(routes.SANCTION)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function handleGetOtherPlans() {
    offerService
      .getOtherOffer()
      .then((res) => {
        setOtherOffer(res.data.data)
        setIsOtherOfferAvailable(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function handlePlanDetails(planId, showButton) {
    setPlanDetails({
      planId,
      showButton,
      isOpen: true,
    })
  }

  return {
    mobile,
    emiOptions,
    activeOffer,
    otherOffer,
    offersData,
    currentSlide,
    selectedEmi,
    isOtherOfferAvailable,
    isUpgradeOffersModalOpen,
    planDetails,
    handlePlanDetails,
    setPlanDetails,
    setIsUpgradeOffersModalOpen,
    handleClaimOffer,
    handleGetOtherPlans,
    handleOfferChange,
    getPriceFormat,
    setCurrentSlide,
    navigate,
    handleEmiOptionChange,
    handleOtherEmiOptionChange,
    openEdit,
    setOpenEdit,
    loadData,
    handleModalClose,
  }
}

export default usePlanOption
