import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import useNotEligible from 'containers/loanNotEligible/useNotEligible'
import useTestRideConfirmation from './useTestRideConfirmation'
import dayjs from 'dayjs'
import BlueBike from 'assets/images/BlueBike.svg'
import Line1 from 'assets/images/Lines1.svg'
import Line2 from 'assets/images/Lines2.svg'
import Person from 'assets/images/Person.png'
import Calendar from 'assets/images/calendar.svg'
import Slider from 'react-slick'
import Footer from 'components/organisms/footer'
import Line1Mobile from 'assets/images/Line1Mobile.png'
import Line2Mobile from 'assets/images/Line2Mobile.png'
import Heading from 'components/atoms/Heading'

export default function TestRideConfirmation() {
  const { mobile } = useNotEligible()
  const { testRideDetails, currentSlide, setCurrentSlide, handleCheckEligibility, handleAddToCalendar } =
    useTestRideConfirmation()

  const startDate = new Date(testRideDetails.slotStartDateTime + '.000Z')
  const endDate = new Date(testRideDetails.slotEndDateTime + '.000Z')
  const type = testRideDetails.testRideConfirmationBanner
  const data = testRideDetails.upsellProducts

  const settings = {
    className: 'center',
    arrows: false,
    dots: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    initialSlide: 0,
    infinite: false,
    centerPadding: '20px',
    beforeChange: (prev, next) => {
      setCurrentSlide(next)
    },
    appendDots: (dots) => {
      return (
        <div style={{ marginTop: '20px' }}>
          <ul style={{ padding: '0' }}>
            {dots.map((item, index) => {
              return (
                <li
                  key={index}
                  style={{
                    margin: '0px',
                    marginRight: index === currentSlide ? '35px' : '0px',
                  }}
                >
                  {item.props.children}
                </li>
              )
            })}
          </ul>
        </div>
      )
    },
    customPaging: (i) => (
      <div
        style={{
          width: i === currentSlide ? '50px' : '14px',
          height: '14px',
          // borderRight: i === currentSlide ? '30px solid #DCDCDC' : '0',
          marginTop: '1rem',
          borderRadius: '10px',
          backgroundColor: i === currentSlide ? '#DCDCDC' : '#00A99D',
          opacity: i === currentSlide ? '1' : '0.6',
          transition: 'all 0.3s ease-in-out',
        }}
      ></div>
    ),
  }

  return (
    <Box>
      <Heading />
      <Box
        bgcolor='#002824'
        px={mobile ? '1rem' : '4.8rem'}
        pt={mobile ? '6rem' : '10rem'}
        pb={mobile ? 3.5 : 5}
      >
        <Box className='gradient-border'>
          <Box
            display='flex'
            flexDirection={mobile ? 'column' : 'row'}
            p={mobile ? '1rem' : '4rem'}
            borderRadius={2.5}
            sx={{
              backgroundImage:
                'linear-gradient(108deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 250, 250, 0.00) 83.15%)',
              backgroundColor: '#002723',
            }}
          >
            <Box width={mobile ? '60%' : '20%'} display='flex' margin={mobile ? '0 auto' : '0'}>
              <img src={testRideDetails.vehiclePricingDetails.image ? testRideDetails.vehiclePricingDetails.image : BlueBike} alt='bike image' style={{ margin: '0 auto', width: '100%', height:mobile ? 'auto' : 'fit-content' }} />
            </Box>
            <Box width={mobile ? '100%' : '80%'} m={mobile ? '0' : '2rem'} color='#FFF'>
              <Box pb='2rem' sx={{ borderBottom: '1px solid #1D4540' }}>
                <Typography variant='h2'>Get ready to Ride!</Typography>
                <Typography
                  variant='body1'
                  display={mobile ? 'none' : 'block'}
                  mt={3}
                  fontFamily='Archivo expanded'
                >
                  You have successfully booked a test ride{' '}
                </Typography>
                <Typography
                  variant='body1'
                  display={mobile ? 'none' : 'block'}
                  fontFamily='Archivo expanded'
                >
                  of the {testRideDetails.vehiclePricingDetails.displayName}
                </Typography>
                <Typography
                  mt={3}
                  variant='body1'
                  fontSize='14px'
                  display={mobile ? 'block' : 'none'}
                  fontFamily='Archivo expanded'
                >
                  You have successfully booked a test ride of the{' '}
                  {testRideDetails.vehiclePricingDetails.displayName}
                </Typography>
              </Box>
              <Box
                py='2rem'
                display='flex'
                justifyContent='space-between'
                flexDirection={mobile ? 'column' : 'row'}
                gap={mobile ? 3 : 0}
                sx={{ borderBottom: '1px solid #1D4540' }}
              >
                <Box>
                  <Typography variant='caption' component={Typography} color='#ffffff80'>
                    Scooter
                  </Typography>
                  <Typography
                    variant='caption'
                    component={Typography}
                    mt={1}
                    color='#e5f6f5e6s'
                    fontFamily='Archivo expanded'
                  >
                    {testRideDetails.vehiclePricingDetails.displayName}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='caption' component={Typography} color='#ffffff80'>
                    Date and Time
                  </Typography>
                  <Typography
                    variant='caption'
                    component={Typography}
                    mt={1}
                    color='#e5f6f5e6s'
                    fontFamily='Archivo expanded'
                  >{`${dayjs(startDate).format('DD')} ${dayjs(startDate).format(
                    'MMM YYYY',
                  )} at ${dayjs(startDate).format('hh A')} - ${dayjs(endDate).format(
                    'hh A',
                  )}`}</Typography>
                </Box>
                <Box maxWidth='15rem'>
                  <Typography variant='caption' component={Typography} color='#ffffff80'>
                    Location
                  </Typography>
                  <Typography
                    variant='caption'
                    component={Typography}
                    mt={1}
                    color='#e5f6f5e6s'
                    fontFamily='Archivo expanded'
                  >
                    {testRideDetails.dealerDto.address}
                  </Typography>
                </Box>
              </Box>
              <Box pt='2rem' display='flex'>
                <Typography variant='caption' component={Typography} color='#e5f6f580' mr={0.5}>ⓘ</Typography>
                <Typography variant='caption' component={Typography} color='#e5f6f580'>
                  Please arrive a few minutes early with your driving license for necessary
                  paperwork and verification.
                </Typography>
              </Box>
              <Box display='none' justifyContent='center'>
                <Button sx={{ px: 8, mt: 4, mb: 2, display: mobile ? 'flex' : 'none', alignItems: 'center' }} onClick={handleAddToCalendar}>
                  <img src={Calendar} width='13px' style={{ paddingRight: '0.5rem' }} />
                  Add to Calendar
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        {type === 'CHECK_ELIGIBILITY' || type === 'REFER_FRIEND' ? (
          <Box height='300px' display={type === 'REFER_FRIEND' ? 'none' : 'block'}>
            <Box
              height='300px'
              borderRadius='1.25rem'
              sx={{ background: mobile ? 'linear-gradient(37deg, #00A99D 3.89%, #B0D456 99.09%)' : 'linear-gradient(12deg, #00A99D 3.89%, #B0D456 99.09%)' }}
              position='relative'
              display='flex'
              mt={mobile ? 3.5 : 5}
            >
              <img
                src={mobile ? Line1Mobile : Line1}
                style={{
                  top: 0,
                  left: 0,
                  position: 'absolute',
                  width: mobile ? '50px' : 'auto',
                }}
              />
              <img
                src={mobile ? Line2Mobile : Line2}
                style={{
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  width: mobile ? '180px' : 'auto',
                }}
              />
              <img
                src={Person}
                style={{
                  bottom: 0,
                  left: mobile ? 'auto' : '50%',
                  right: mobile ? '0' : 'auto',
                  transform: mobile ? 'none' : 'translate(-50%)',
                  margin: '0 auto',
                  position: 'absolute',
                  marginRight: mobile ? '-10px' : 0
                }}
              />
              <Grid
                container
                p={mobile ? '2rem 1.5rem' : '5rem 0'}
                alignItems='flex-start'
                zIndex='5'
              >
                <Grid item xs={mobile ? 12 : 8}>
                  <Typography
                    variant='h1'
                    lineHeight='normal'
                    color='#FFF'
                    fontSize={mobile ? '1.25rem' : '2rem'}
                    ml={mobile ? 0 : 10}
                    maxWidth={mobile ? '60%' : '300px'}
                  >
                    {type === 'REFER_FRIEND' ? 'Share with friends' : 'Get your new EV for less'}
                  </Typography>
                </Grid>
                <Grid item xs={mobile ? 12 : 4}>
                  <Typography
                    variant={mobile ? 'caption' : 'body1'}
                    component={Typography}
                    fontSize={mobile ? '14px' : '1.5rem'}
                    color='#FFF'
                    maxWidth={mobile ? '60%' : '300px'}
                    lineHeight='normal'
                  >
                    {type === 'REFER_FRIEND'
                      ? 'Share this with friends to enjoy together'
                      : 'Fast, affordable financing from trusted EV expert'}
                  </Typography>
                  {type === 'REFER_FRIEND' && (
                    <Button sx={{ color: '#00655E', background: '#FFF', px: 2, mt: 4 }}>
                      Share now
                    </Button>
                  )}
                  {type !== 'REFER_FRIEND' && (
                    <Button
                      onClick={handleCheckEligibility}
                      sx={{
                        color: '#00655E',
                        background: '#FFF',
                        px: 2,
                        mt: 4,
                        '&:hover': { background: '#FFF' },
                      }}
                    >
                      Check Eligibility
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          ''
        )}
        {type === 'UPSELL_PRODUCT' && (
          <Box
            display={mobile ? 'none' : 'block'}
            bgcolor='#00655E'
            borderRadius={2.5}
            mt='4rem'
            p='4rem'
            position='relative'
          >
            <Box
              bgcolor='#E5F6F5'
              position='absolute'
              left='5rem'
              top='-16px'
              height='2rem'
              borderRadius='1rem'
              px='1.25rem'
              display='flex'
              alignItems='center'
            >
              <Typography color='#00655E' variant='caption'>
                Exclusive for {testRideDetails.vehiclePricingDetails.brand}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant='h2'
                fontSize='1.25rem'
                fontFamily='Archivo Expanded'
                display='inline'
                color='#FFF'
              >
                {`WOW! `}
              </Typography>
              <Typography
                variant='h2'
                fontSize='1.25rem'
                fontFamily='Archivo Expanded'
                display='inline'
                color='#00A99D'
              >
                Enhance your plan and enjoy even better benefits
              </Typography>
              <Grid container justifyContent='center' spacing={4} mt='1.25rem'>
                {data.map((offer, index) => (
                  <Grid item xs={6} key={index}>
                    <Card
                      sx={{
                        background: '#00221F',
                        borderRadius: 2.5,
                        border: '1px solid rgba(81, 179, 167, 0.20)',
                        backdropFilter: 'blur(20px)',
                        color: '#fff',
                        px: 5,
                        py: 4,
                      }}
                    >
                      <Typography variant='body'>{offer.upsellText}</Typography>
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        mt={2.5}
                      >
                        <Button
                          sx={{
                            fontFamily: "'IBM Plex Sans', sans-serif",
                            height: '2.3rem !important',
                            px: 1.25,
                            background: 'linear-gradient(135deg, #00A99D, #00655E)',
                            '&:hover': { background: 'linear-gradient(135deg, #00A99D, #00655E)' }
                          }}
                          onClick={handleCheckEligibility}
                        >
                          Check Eligibility
                        </Button>
                        <Typography fontWeight='500'>{offer.productName}</Typography>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
      {type === 'UPSELL_PRODUCT' && (
        <Box
          py={6.25}
          bgcolor='#00655E'
          mt={6.5}
          sx={{ borderTopLeftRadius: '1.25rem', borderTopRightRadius: '1.25rem' }}
          position='relative'
          display={mobile ? 'block' : 'none'}
        >
          <Box px={5} pb={2}>
            <Typography
              variant='h2'
              fontSize='1.25rem'
              fontFamily='Archivo Expanded'
              display='inline'
              color='#FFF'
            >
              {`WOW! `}
            </Typography>
            <Typography
              variant='h2'
              fontSize='1.25rem'
              fontFamily='Archivo Expanded'
              display='inline'
              color='#FAFAFA'
              sx={{ opacity: 0.5 }}
            >
              Enhance your plan and enjoy even better benefits
            </Typography>
          </Box>
          <Box
            position='absolute'
            top='-1.25rem'
            left='1rem'
            pl={2}
            pr={5}
            py={1}
            display='flex'
            justifyContent='center'
            alignItems='center'
            color='#00655E'
            bgcolor='#E5F6F5'
            borderRadius={5}
          >
            Exclusive for {testRideDetails.vehiclePricingDetails.brand}
          </Box>
          <Slider {...settings} style={{ paddingBottom: '1rem' }}>
            {data.map((offer, index) => (
              <Card
                key={index}
                sx={{
                  background: '#00221F',
                  borderRadius: 2.5,
                  border: '1px solid rgba(81, 179, 167, 0.20)',
                  backdropFilter: 'blur(20px)',
                  color: '#fff',
                  px: 5,
                  py: 4,
                  maxWidth: data.length > 1 ? 'calc(100vw - 150px)' : 'calc(100vw - 120px)',
                }}
              >
                <Typography variant='body'>{offer.upsellText}</Typography>
                <Box display='flex' alignItems='center' justifyContent='space-between' mt={2.5}>
                  <Button
                    sx={{
                      background: 'linear-gradient(37deg, #00A99D 3.89%, #00655E 99.09%)',
                      fontFamily: 'IBM Plex Sans',
                      textTransform: 'capitalize',
                      height: '36px !important',
                      px: 2,
                      '&:hover': { background: 'linear-gradient(37deg, #00A99D 3.89%, #00655E 99.09%)' }
                    }}
                    onClick={handleCheckEligibility}
                  >
                    Check eligibility
                  </Button>
                  <Typography>{offer.productName}</Typography>
                </Box>
              </Card>
            ))}
          </Slider>
        </Box>
      )}
      <Footer mobile={mobile} />
    </Box>
  )
}
