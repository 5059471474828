import React from 'react'
import useMobileDevice from 'hooks/useMobileDevice'
import BannerGroup from 'assets/images/BannerGroup.svg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import BikeImage from 'assets/images/BikeImage.png'
import BikeImageMobile from 'assets/images/BikeImgMobile.png'

interface Props {
  children: JSX.Element
  pref?: boolean
  type?: string
}

const LeftSection = ({ children, pref = false, type = 'default' }: Props) => {
  const mobile = useMobileDevice()
  return (
    <Grid
      mt={mobile ? (pref ? 28 : type === 'bikeImage' ? 0 : 12) : 0}
      mb={mobile ? type === 'bikeImage' ? 0 : 2 : 0}
      container
      sx={{ overflowX: 'hidden' }}
    >
      <Grid
        item
        xs={mobile ? 12 : 5}
        position='relative'
        display={mobile && type !== 'bikeImage' ? 'none' : 'flex'}
        flexDirection='column'
        height={type ===  'bikeImage' ? mobile ? '50vh' : '100vh' : 'auto' }
      >
        {type === 'default' ? (
          <Box position='fixed' bottom={0} display='flex' alignItems='end' width='41.666667%'>
            <img
              src={BannerGroup}
              alt='mobile'
              style={{ width: '427px', height: '517px', position: 'absolute', right: '-1.25rem' }}
            />
            <Box
              height='5.75rem'
              width='100%'
              position='absolute'
              bottom='-30px'
              zIndex='-1'
              sx={{ background: 'linear-gradient(180deg, #1C3C38 0%, #000 100%)' }}
            ></Box>
          </Box>
        ) : (
          <img src={mobile ? BikeImageMobile : BikeImage} alt='BikeImage' style={{height: mobile ? '120%' : '100%', width: '100%', objectFit: 'fill', minHeight: '477px', zIndex: '-1'}} />
        )}
      </Grid>
      {children}
    </Grid>
  )
}

export default LeftSection
