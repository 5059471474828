import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import DealerCard from 'components/molecules/Cards/DealerCard'
import vehicleService from 'service/vechicleService'
import { Skeleton } from '@mui/material'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { routes } from 'constants/routes'

interface Props {
  mobile: boolean
  dealers: any[]
  selectedDealer: any
  isDealerSelected: boolean
  getLoanSanctionDetails: any
  loadingDealer: boolean
  setLoadingDealer: any
}
export default function DealerSection({
  mobile,
  dealers,
  selectedDealer,
  isDealerSelected,
  getLoanSanctionDetails,
  loadingDealer,
  setLoadingDealer,
}: Props) {
  const [checkedDealer, setCheckedDealer] = useState(null)
  const [isEditable, setIsEditable] = useState(false)

  const navigate = useNavigate()

  const submitDealer = () => {
    ReactGA.event({
      category: 'Sanction Page',
      action: 'Dealer Updated',
      label: 'Dealer Updated',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    vehicleService
      .saveDealer(`?dealerId=${checkedDealer}`)
      .then(() => {
        setLoadingDealer(true)
        getLoanSanctionDetails()
        setIsEditable(false)
        setCheckedDealer(null)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function handleTestRide() {
    localStorage.setItem('selectedDealerId', selectedDealer.dealerId)
    localStorage.setItem('test_ride', 'SANCTION')
    navigate(routes.SCHEDULE_RIDE)
  }

  return (
    <Box
      width='100%'
      flexWrap={mobile ? 'wrap' : 'unset'}
      display='flex'
      mt={2}
      justifyContent='space-between'
    >
      <Box
        width={mobile ? '100%' : 'fit-content'}
        display='flex'
        flexDirection='column'
        gap={1}
        pr={1}
        alignItems={mobile ? 'center' : 'baseline'}
      >
        <Typography
          variant='h2'
          fontSize='20px'
          color='#00655E'
          textAlign={mobile ? 'center' : 'left'}
          width={mobile ? '200px' : 'fit-content'}
          lineHeight='normal'
        >
          Partner Dealer Locations
        </Typography>
        <Typography
          mb={3}
          mt={1}
          textAlign={mobile ? 'center' : 'left'}
          color='#00221F'
          variant='caption'
          sx={{ opacity: '0.6' }}
        >
          Select your preferred dealer to complete.
        </Typography>
      </Box>
      {loadingDealer ? (
        <Box display='flex' mb={2} width={'80%'} alignItems='end' justifyContent='end'>
          <Skeleton variant='rounded' sx={{ my: 2 }} width={'70%'} height={'90px'} />
        </Box>
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          justifyContent={dealers.length > 0 ? 'start' : 'center'}
          alignItems={dealers.length > 0 ? 'end' : 'center'}
          gap={2}
        >
          <Box 
            sx={{overflowY: 'auto'}}
            maxHeight='300px'
            className='sanction-scrollbar'
          >
            {isDealerSelected && dealers.length > 0 && !isEditable ? (
              <DealerCard
                setIsEditable={setIsEditable}
                setCheckedDealer={setCheckedDealer}
                data={selectedDealer}
                state={2}
              />
            ) : dealers.length > 0 ? (
              dealers?.map((data, i) => (
                <DealerCard
                  setCheckedDealer={setCheckedDealer}
                  data={data}
                  key={i}
                  state={checkedDealer == data?.dealerId ? 1 : 0}
                />
              ))
            ) : (
              <Typography variant='caption'>No records found</Typography>
            )}
          </Box>
          {(!isDealerSelected || isEditable) && dealers.length > 0 && (
            <Button
              disabled={checkedDealer == null}
              onClick={() => submitDealer()}
              sx={{ width: mobile ? '100%' : '375px', margin: '15px 0' }}
            >
              Continue
            </Button>
          )}
          {isDealerSelected && !isEditable && (
            <Button
              onClick={() => handleTestRide()}
              sx={{ width: mobile ? '100%' : '365px', margin: '15px 0' }}
            >
              Book a Test Ride
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}
