import React, { SetStateAction, useState } from 'react'
import closeIcon from 'assets/images/close icon.svg'
import { Box, Button, Card, Drawer, Typography } from '@mui/material'
import Slider from 'react-slick'
import SmartFinDetails from 'containers/plan-details'

interface OffersDataInterface {
  productId: number
  productKey: string
  productName: string
  upsellOrder: number
  upsellText: string
}

interface UpgradeOffersModalInterface {
  mobile: boolean
  upgradeOffers: OffersDataInterface[]
  open: boolean
  setOpen: React.Dispatch<SetStateAction<boolean>>
  handleOfferChange: (number) => void
}

export default function UpgradeOffersModal({
  mobile,
  upgradeOffers,
  open,
  setOpen,
  handleOfferChange,
}: UpgradeOffersModalInterface) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [planDetails, setPlanDetails] = useState({
    planId: '',
    showButton: '',
    isOpen: false,
  })

  function handlePlanDetails(planId, showButton) {
    setPlanDetails({
      planId,
      showButton,
      isOpen: true,
    })
  }

  const settings = {
    className: 'center',
    arrows: false,
    dots: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    initialSlide: 0,
    infinite: false,
    centerPadding: '20px',
    beforeChange: (prev, next) => {
      setCurrentSlide(next)
    },
    appendDots: (dots) => {
      return (
        <div style={{ marginTop: '20px' }}>
          <ul style={{ padding: '0' }}>
            {dots.map((item, index) => {
              return (
                <li
                  key={index}
                  style={{
                    margin: '0px',
                    marginRight: index === currentSlide ? '35px' : '0px',
                  }}
                >
                  {item.props.children}
                </li>
              )
            })}
          </ul>
        </div>
      )
    },
    customPaging: (i) => (
      <div
        style={{
          width: i === currentSlide ? '50px' : '14px',
          height: '14px',
          // borderRight: i === currentSlide ? '30px solid #DCDCDC' : '0',
          marginTop: '1rem',
          borderRadius: '10px',
          backgroundColor: i === currentSlide ? '#DCDCDC' : '#00A99D',
          opacity: i === currentSlide ? '1' : '0.6',
          transition: 'all 0.3s ease-in-out',
        }}
      ></div>
    ),
  }

  return (
    <>
      <Drawer
        anchor={mobile ? 'bottom' : 'right'}
        open={open}
        PaperProps={{
          sx: {
            background: 'linear-gradient(65deg, #082C29 -11.66%, #000 52.77%)',
            borderRadius: mobile ? '1.25rem 1.25rem 0 0' : '1.25rem 0 0 1.25rem',
            padding: mobile ? '0' : '3rem 0',
          },
        }}
      >
        <Box display='flex' justifyContent='end' alignItems='center'>
          <img
            src={closeIcon}
            alt='close icon'
            onClick={() => setOpen(false)}
            style={{
              width: '2rem',
              height: '2rem',
              marginRight: mobile ? '1rem' : '2.5rem',
              marginTop: '1.5rem',
              cursor: 'pointer',
            }}
          />
        </Box>
        <Box
          py={6.25}
          bgcolor='#00655E'
          mt={mobile ? 5.5 : 2}
          sx={{ borderRadius: mobile ? '1.25rem 1.25rem 0 0' : '1.25rem' }}
          maxWidth={mobile ? '100%' : '360px'}
          ml={mobile ? 0 : 8}
          mr={mobile ? 0 : 5}
          position='relative'
        >
          <Box px={5} pb={2}>
            <Typography
              variant='h2'
              fontSize='1.25rem'
              fontFamily='Archivo Expanded'
              display='inline'
              color='#FFF'
            >
              {`WOW! `}
            </Typography>
            <Typography
              variant='h2'
              fontSize='1.25rem'
              fontFamily='Archivo Expanded'
              display='inline'
              color='#FAFAFA'
              sx={{ opacity: 0.5 }}
            >
              Enhance your plan and enjoy even better benefits
            </Typography>
          </Box>
          <Box
            position='absolute'
            top='-1.25rem'
            left='1rem'
            pl={2}
            pr={5}
            py={1}
            display='flex'
            justifyContent='center'
            alignItems='center'
            color='#00655E'
            bgcolor='#E5F6F5'
            borderRadius={5}
          >
            {upgradeOffers.length} {upgradeOffers.length > 1 ? 'Offers' : 'Offer'} available
          </Box>
          {mobile && (
            <Slider {...settings} style={{ paddingBottom: '1rem' }}>
              {upgradeOffers.map((offer, index) => (
                <Card
                  key={index}
                  sx={{
                    background: '#00221F',
                    borderRadius: 2.5,
                    border: '1px solid rgba(81, 179, 167, 0.20)',
                    backdropFilter: 'blur(20px)',
                    color: '#fff',
                    px: 5,
                    py: 4,
                    maxWidth: upgradeOffers.length > 1 ? 'calc(100vw - 150px)' : 'calc(100vw - 120px)',
                  }}
                >
                  <Typography variant='body'>{offer.upsellText}</Typography>
                  <Typography
                    onClick={() => handlePlanDetails(offer?.productId, true)}
                    variant='body'
                    sx={{ cursor: 'pointer', marginLeft: '10px' }}
                    color='#00A99D'
                  >
                    Learn more
                  </Typography>
                  <Box display='flex' alignItems='center' justifyContent='space-between' mt={2.5}>
                    <Button
                      sx={{
                        background: 'linear-gradient(37deg, #00A99D 3.89%, #00655E 99.09%)',
                        fontFamily: 'IBM Plex Sans',
                        textTransform: 'capitalize',
                        height: '36px !important',
                        px: 2,
                      }}
                      onClick={() => handleOfferChange(offer.productId)}
                    >
                      Select Plan
                    </Button>
                    <Typography>{offer.productName}</Typography>
                  </Box>
                </Card>
              ))}
            </Slider>
          )}
          {!mobile &&
            upgradeOffers.map((offer, index) => (
              <Card
                key={index}
                sx={{
                  background: '#00221F',
                  borderRadius: 2.5,
                  border: '1px solid rgba(81, 179, 167, 0.20)',
                  backdropFilter: 'blur(20px)',
                  color: '#fff',
                  px: 5,
                  py: 4,
                  mx: 2,
                  maxWidth: 'calc(100vw - 150px)',
                }}
              >
                <Typography variant='body'>{offer.upsellText}</Typography>
                <Typography
                  onClick={() => handlePlanDetails(offer?.productId, true)}
                  variant='body'
                  sx={{ cursor: 'pointer', marginLeft: '10px' }}
                  color='#00A99D'
                >
                  {` Learn more`}
                </Typography>
                <Box display='flex' alignItems='center' justifyContent='space-between' mt={2.5}>
                  <Button
                    sx={{
                      background: 'linear-gradient(37deg, #00A99D 3.89%, #00655E 99.09%)',
                      fontFamily: 'IBM Plex Sans',
                      textTransform: 'capitalize',
                      height: '36px !important',
                      px: 2,
                    }}
                    onClick={() => handleOfferChange(offer.productId)}
                  >
                    Select Plan
                  </Button>
                  <Typography>{offer.productName}</Typography>
                </Box>
              </Card>
            ))}
        </Box>
      </Drawer>
      <SmartFinDetails
        open={planDetails.isOpen}
        setOpen={setPlanDetails}
        planId={planDetails.planId}
        showButton={planDetails.showButton}
      />
    </>
  )
}
