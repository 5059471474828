import { ECOFIN, RIDEFIN, SMARTFIN, ZIPFIN } from 'constants/faqConstants'
import { routes } from 'constants/routes'
import useMobileDevice from 'hooks/useMobileDevice'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import offerService from 'service/offerService'

interface PropsInterface {
  planId: string,
  setOpen: React.Dispatch<React.SetStateAction<any>>,
}

const useSmartFinDetails = ({planId, setOpen}: PropsInterface) => {
  const mobile = useMobileDevice()
  const [tableData, setTableData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const rowsToHighlight = [3, 5, 7]

  const [accordiantData, setAccordiantData] = useState([])

  const retrivePlanDetails = (id) => {
    setLoading(true)
    offerService
      .getPlanDetails(id)
      .then((res) => {
        setTableData(res?.data?.data)

        switch (res?.data?.data?.productKey) {
          case 'Eco.fin':
            setAccordiantData(ECOFIN)
            break

          case 'Smart.fin':
            setAccordiantData(SMARTFIN)
            break

          case 'Ride.fin':
            setAccordiantData(RIDEFIN)
            break

          case 'Zip.fin':
            setAccordiantData(ZIPFIN)
            break
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleOfferChange = (productId) => {
    setIsLoading(true)
    offerService
      .getUpdateProduct(productId)
      .then((res) => {
        navigate(routes.CONGRATS)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (planId) retrivePlanDetails(planId)
  }, [planId])

  const truncateToTwoDecimalPlaces = (number) => {
    if (Number.isInteger(number)) {
      return number // It's an integer, so no need to truncate.
    } else {
      return Math.floor(number * 100) / 100 // Truncate to two decimal places.
    }
  }

  function handleClose() {
    setOpen({
      planId: '',
      showButton: '',
      isOpen: false
    })
  }

  return {
    mobile,
    accordiantData,
    rowsToHighlight,
    tableData,
    truncateToTwoDecimalPlaces,
    loading,
    isLoading,
    handleOfferChange,
    handleClose,
  }
}

export default useSmartFinDetails
