import React, {Fragment, useState} from 'react'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface AccordianInterface {
  summary: string
  details: string
}

interface PropsInterface {
  data: AccordianInterface[]
}

export default function CustomAccordian({ data }: PropsInterface) {
  const [expanded, setExpanded] = useState<number | null>(null)

  function handleChange(index) {
    setExpanded(index)
  }
  return (
    <Fragment>
      <Box>
        {data.map((accordian, index) => (
          <Accordion
            key={index}
            sx={{
              padding: '0.75rem 0',
              boxShadow: 'none',
              background: 'transparent',
              color:'#DCDCDC',
              borderBottom: '1px solid #00655E',
              borderRadius: '2px',
              '&:before': {
                display: 'none',
              },
              '& .Mui-expanded, .MuiCollapse-entered' : {
                color: '#E5F6F5',
              }
            }}
            expanded={expanded === index}
            onChange={() => handleChange(index)}
          >
            <AccordionSummary
              sx={{
                paddingLeft: '0',
                '& .Mui-expanded' : {
                  '& .MuiSvgIcon-root' : {
                    color: '#E5F6F5',
                    border: '1px solid #E5F6F5',
                  }
                }
              }}
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    borderRadius: '50%',
                    border: '1px solid #DCDCDC',
                    color: '#DCDCDC',
                    width: '1rem',
                    height: '1rem'
                  }}
                />
              }
            >
              <Typography sx={{marginRight:'50px', fontWeight: '500', }}>{accordian.summary}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pl: 0, marginRight: '50px' }}>
              <Typography fontSize='0.75rem' >{accordian.details}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Fragment>
  )
}