import React from 'react'
import OtpGif from 'assets/images/otp.gif'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import useMobileDevice from 'hooks/useMobileDevice'
import { Link } from 'react-router-dom'
import { routes } from 'constants/routes'
import CustomButton from 'components/atoms/Button'
import CircularProgress from '@mui/material/CircularProgress'
import OTPInput from 'react-otp-input'
import useOtpModal from './useOtpModal'

interface OtpModalInterface {
  onSubmit: () => void
  handlePhoneNumberChange: () => void
}

export default function OtpModal({onSubmit, handlePhoneNumberChange}: OtpModalInterface) {
  const mobile = useMobileDevice()

  const {
    handleSubmit,
    otp,
    handleChange,
    numberToShow,
    onLoginSubmit,
    onResendOtp,
    isLoading,
    isResend,
  } = useOtpModal({onSubmit})

  return (
    <Box px={4} py={1.5}>
      <Box display='flex' alignItems={mobile ? 'center' : 'flex-start'} flexDirection='column'>
        <img src={OtpGif} alt='otpAnimation' style={{ width: '59px' }} />
        <Typography variant='h2' color='#082F2F' mt={3}>
          Verify
        </Typography>
        <Typography variant='h2' color='#082F2F'>
          with OTP!
        </Typography>
        <Typography variant='caption' color='#082F2F' mt={2} textAlign={mobile ? 'center' : 'left'}>
          We have sent the verification code to {numberToShow()}.
        </Typography>
        <Link to='' style={{ color: '#00B6A7' }} onClick={handlePhoneNumberChange}>
          <Typography variant='caption'>Change phone number?</Typography>
        </Link>
        <Box display='flex' maxWidth='100%' mt={4} style={{ fontSize: '10px', maxWidth: '300px' }}>
          <OTPInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            skipDefaultStyles
            inputType='number'
            shouldAutoFocus={true}
            renderInput={(props) => <input {...props} className='customInput' />}
          />
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          mt={4}
          gap={mobile ? '1rem' : '4.2rem'}
        >
          <Typography variant='caption'>
            Didnt get an SMS code.
            <Typography
              onClick={onResendOtp}
              variant='caption'
              color='#00B6A7'
              ml={1}
              sx={{ cursor: 'pointer' }}
            >
              {isResend}
            </Typography>
          </Typography>
          <CustomButton
            disabled={otp?.length != 6}
            onClick={handleSubmit(onLoginSubmit)}
            sx={{ width: '108px' }}
          >
            {isLoading ? (
              <Box mx='auto' mt={1}>
                <CircularProgress size={20} color='success' />
              </Box>
            ) : (
              'Verify'
            )}
          </CustomButton>
        </Box>
      </Box>
    </Box>
  )
}
