import React, { useState, useEffect } from 'react'
import vehicleService from '../../service/vechicleService'
import useMobileDevice from '../../hooks/useMobileDevice'
import { useNavigate } from 'react-router'
import { routes } from 'constants/routes'
import ReactGA from 'react-ga4'

const useLoanPreference = () => {
  const [loanData, setLoanData] = useState<any>([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [payment, setPayment] = React.useState(0)
  const [tenure, setTenure] = React.useState(0)
  const [loanAmount, setLoanAmount] = React.useState(0)
  const [emi, setEmi] = useState(0)
  const navigate = useNavigate()
  const mobile = useMobileDevice()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    vehicleService
      .getLoanPreference()
      .then((res) => {
        setLoanData(res?.data?.data)
        setIsPageLoading(false)
        setPayment(parseInt(res?.data?.data?.defaultDownPayment))
        setTenure(res?.data?.data?.defaultTenure / 12)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  useEffect(() => {
    // const proFee = loanData?.processingFee / 100
    const monthTenure = tenure * 12
    const calculatedLoanAmount = loanData?.onRoadPrice - payment
    const EMI = (calculatedLoanAmount * loanData?.roi * monthTenure) / 1200 + calculatedLoanAmount
    setLoanAmount(calculatedLoanAmount)
    setEmi(Math.round(EMI / monthTenure))
  }, [payment, tenure])

  const onContinue = async () => {
    ReactGA.event({
      category: 'Preference Page',
      action: 'Customised financing option',
      label: 'Upfront payment and Loan Duration Updated',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    setIsLoading(true)
    const apiData = {
      productId: loanData?.productId,
      tenure: tenure * 12,
      downPayment: payment,
      emi: emi,
      loanAmount: loanAmount,
      onRoadPrice: loanData?.onRoadPrice,
    }
    try {
      const response = await vehicleService.saveLoanPreference(apiData)
      setIsLoading(false)
      navigate(routes.DETAILS)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  return {
    loanData,
    isPageLoading,
    payment,
    setPayment,
    tenure,
    setTenure,
    emi,
    mobile,
    onContinue,
    isLoading,
  }
}

export default useLoanPreference
