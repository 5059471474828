import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import DetailsGif from 'assets/images/details.gif'
import EditIcon from 'assets/images/edit.svg'
import CustomModal from 'components/molecules/Modal'
import { Controller } from 'react-hook-form'
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import useDetails from './useDetails'
import LeftSection from 'components/molecules/LeftSection'
import Stepper from 'components/molecules/Stepper'
import VehicleCard from 'components/molecules/Cards/VehicleCard'
import Footer from 'components/organisms/footer'
import Heading from 'components/atoms/Heading'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import PincodeModal from 'components/molecules/PincodeModal'
import usePincode from 'containers/pincodePage/usePincode'
import MuiLink from '@mui/material/Link'

export default function Details() {
  const {
    mobile,
    open,
    setOpen,
    errors,
    control,
    addressData,
    isAlphaWithSpace,
    isValidPan,
    handleSubmit,
    onSubmit,
    isLoading,
    profileData,
    values,
    isDisabled,
    canEditCity,
    loading,
  } = useDetails()
  dayjs.extend(localizedFormat)

  const { onPincodeSubmit, isLoading: isPincodeLoading } = usePincode(setOpen)

  return (
    <>
      <Heading />
      <LeftSection pref={true}>
        {!loading ? (
          <Grid item xs={mobile ? 0 : 7} width='100%' height='100%'>
            {!mobile && <Stepper currentStepper={3} totalNoOfSteppers={3} />}
            <Box
              width='100%'
              bgcolor='#FFF'
              borderRadius={mobile ? '20px 20px 0 0' : 0}
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
            >
              <Box
                width='320px'
                minHeight='100vh'
                display='flex'
                flexDirection='column'
                pt={mobile ? 3 : 10}
              >
                <img
                  src={DetailsGif}
                  alt='details'
                  style={{ margin: '0 auto', width: '4rem', height: '4rem', marginTop: '1.4rem' }}
                />
                <Typography variant='h2' textAlign='center' sx={{ my: 1.5 }}>
                  {`Let's Get to Know You Better!`}
                </Typography>
                <Typography variant='body' textAlign='center' sx={{ mb: 3 }} color='text.secondary'>
                  Help us serve you better by sharing some information about yourself.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name='name'
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value) {
                          return 'Name is required'
                        }
                        if (!isAlphaWithSpace(value)) {
                          return 'Only alphabets and spaces are allowed'
                        }
                        return true // Validation passed
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          fullWidth
                          label='Name'
                          disabled={!profileData?.editableFields?.includes('NAME')}
                          error={!!errors.name}
                          sx={{ mt: 1.5 }}
                          helperText={errors?.name?.message?.toString()}
                          value={values?.name ? values?.name : ''}
                        />
                        <Typography variant='caption' color='text.secondary'>
                          *Make sure your name matches your PAN card!
                        </Typography>
                      </>
                    )}
                  />
                  <Controller
                    name='fatherName'
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value) {
                          return 'Name is required'
                        }
                        if (!isAlphaWithSpace(value)) {
                          return 'Only alphabets and spaces are allowed'
                        }
                        return true // Validation passed
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label='Father’s Name'
                        disabled={!profileData?.editableFields?.includes('FATHERNAME')}
                        value={values?.fatherName ? values?.fatherName : ''}
                        error={!!errors.fatherName}
                        sx={{ mb: 2, mt: 3 }}
                        helperText={errors?.fatherName?.message?.toString()}
                      />
                    )}
                  />
                  <Controller
                    name='dob'
                    control={control}
                    rules={{
                      required: 'Date of birth is required',
                    }}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            label='Date of birth'
                            format='DD - MM - YYYY'
                            views={['year', 'month', 'day']}
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(date) => {
                              const selectedDate = date.toDate()
                              const newDate = new Date(
                                selectedDate.getFullYear(),
                                selectedDate.getMonth(),
                                selectedDate.getDate(),
                                12,
                                0,
                                0,
                                0,
                              )
                              field.onChange(newDate.toISOString().split('T')[0])
                            }}
                            className='custom-datepicker'
                            sx={{
                              svg: {
                                color: profileData?.editableFields?.includes('DOB')
                                  ? '#51B3A7'
                                  : 'gray',
                              },
                              width: '100%',
                            }}
                            disabled={!profileData?.editableFields?.includes('DOB')}
                            maxDate={dayjs(new Date(new Date().getFullYear() - 17, 0, 1))}
                            minDate={dayjs(new Date(new Date().getFullYear() - 120, 0, 1))}
                            onError={(error, value) => {
                              if (error) {
                                return errors?.dob?.message
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    )}
                  />

                  <Controller
                    name='pan'
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!profileData?.panVerified) {
                          if (!value) {
                            return 'PAN Number is required'
                          }
                          if (!isValidPan(value)) {
                            return 'Enter a valid PAN number'
                          }
                        }
                        return true // Validation passed
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        autoCapitalize='characters'
                        value={values?.pan ? values?.pan.toUpperCase() : ''}
                        disabled={!profileData?.editableFields?.includes('PAN_NUMBER')}
                        label='PAN Card Number'
                        error={!!errors.pan}
                        sx={{
                          mt: 3,
                          mb: 1.5,
                          display: profileData?.panVerified ? 'none' : 'block',
                        }}
                        helperText={errors?.pan?.message?.toString()}
                      />
                    )}
                  />

                  <Box
                    display='flex'
                    alignItems='right'
                    justifyContent={'end'}
                    my={1.5}
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography textAlign='center' color='text.secondary' fontWeight='600'>
                      {addressData?.city}
                    </Typography>
                    {canEditCity && (
                      <img
                        onClick={() => setOpen(true)}
                        src={EditIcon}
                        alt='edit'
                        style={{ marginLeft: '5px', fontWeight: 'bold' }}
                      />
                    )}
                  </Box>

                  <Controller
                    name='address'
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value) {
                          return 'Address is required'
                        }
                        if (value && value?.length <= 10) {
                          return 'Address should be greater than 10 characters'
                        }
                        return true // Validation passed
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        disabled={!profileData?.editableFields?.includes('ADDRESS')}
                        value={values?.address ? values?.address : ''}
                        label='Enter your address'
                        error={!!errors.address}
                        sx={{ my: 1.5 }}
                        helperText={errors?.address?.message?.toString()}
                      />
                    )}
                  />
                  <Controller
                    name='owned'
                    control={control}
                    rules={{
                      required: 'Entry is required',
                    }}
                    render={({ field }) => (
                      <FormControl sx={{ my: 1.5 }}>
                        <InputLabel>Current Address Type</InputLabel>
                        <Select
                          label='Current Address Type'
                          sx={{
                            borderRadius: '10px',
                            width: '320px',
                          }}
                          MenuProps={{
                            hideBackdrop: true,
                          }}
                          {...field}
                          value={values?.owned ? values?.owned : ''}
                          error={!!errors.owned}
                          fullWidth
                          disabled={!profileData?.editableFields?.includes('OWNED')}
                        >
                          <MenuItem value={'true'}>Owned</MenuItem>
                          <MenuItem value={'false'}>Rented</MenuItem>
                        </Select>
                        {errors?.owned?.message && (
                          <FormHelperText sx={{ color: 'red' }}>
                            {errors?.owned?.message?.toString()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                  <Box display='flex' alignItems='start' sx={{my: 1.5}}>
                  <Controller
                    name='consent'
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <Checkbox sx={{p: 0, m: 0}} {...field}/>
                    )}
                  />
                  <Typography
                    component={Typography}
                    variant='caption'
                    sx={{pl: 1}}
                  >
                    I acknowledge and consent to a credit check being conducted on my behalf, and I
                    agree to the terms outlined in our
                    <Typography
                      component={MuiLink}
                      variant='caption'
                      href='https://evfin.co/privacy.php'
                      target='_blank'
                      sx={{ marginLeft: '4px' }}
                      whiteSpace='nowrap'
                    >
                      Privacy Policy
                    </Typography>{' '}
                    and
                    <Typography
                      component={MuiLink}
                      variant='caption'
                      href='https://evfin.co/tnc.php'
                      target='_blank'
                      sx={{ marginLeft: '4px' }}
                      whiteSpace='nowrap'
                    >
                      Terms & Conditions
                    </Typography>
                  </Typography>
                  </Box>
                  <Button disabled={isDisabled} type='submit' sx={{ mt: 4, mb: 5, width: '100%' }}>
                    {isLoading ? (
                      <Box mx='auto' mt={1}>
                        <CircularProgress size={20} color='success' />
                      </Box>
                    ) : (
                      'Continue'
                    )}
                  </Button>
                </form>
              </Box>
            </Box>
            <Footer mobile={mobile} />
          </Grid>
        ) : (
          <></>
        )}
      </LeftSection>
      {mobile && (
        <Stepper
          currentStepper={3}
          totalNoOfSteppers={3}
          sx={{ position: 'absolute', top: '80px' }}
        />
      )}
      <Box
        sx={{
          position: mobile ? 'absolute' : 'fixed',
          bottom: mobile ? 'auto' : '2rem',
          top: mobile ? 0 : 'auto',
          left: mobile ? 0 : '5rem',
          width: mobile ? '100%' : 'auto',
        }}
      >
        <VehicleCard />
      </Box>
      <CustomModal padding={4} open={open} setOpen={setOpen}>
        <PincodeModal isLoading={isPincodeLoading} onSubmit={onPincodeSubmit} />
      </CustomModal>
    </>
  )
}
