import React from 'react'
import Layout from 'components/organisms/Layout'
import { Box, TextField, Button, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import InputAdornment from '@mui/material/InputAdornment'
import useLogin from './useLogin'
import CircularProgress from '@mui/material/CircularProgress'
import LockGif from '../../../src/assets/images/lock.gif'
import Heading from 'components/atoms/Heading'
import MuiLink from '@mui/material/Link'

const Login = () => {
  const {
    control,
    mobile,
    handleSubmit,
    formState: { errors },
    isMobileNumberValid,
    onLoginSubmit,
    isLoading,
    values,
  } = useLogin()
  
  return (
    <Layout
      title={
        <>
          Supercharge <div>your dreams!</div>
        </>
      }
      description='Share your mobile number to unlock personalized loan options tailored just for you.'
      icon={LockGif}
    >
      <Heading />
      <Controller
        name='mobile_number'
        control={control}
        rules={{
          required: 'Mobile number is required',
          validate: (value) => isMobileNumberValid(value) || 'Invalid mobile number'
        }}
        render={({ field }) => (
          <TextField
            fullWidth
            type='number'
            autoFocus
            label='Mobile Number'
            InputProps={{
              startAdornment: <InputAdornment position='start'>+91 | </InputAdornment>,
            }}
            inputProps={{
              pattern: '[0-9]*'
            }}
            value={values.mobile_number}
            onChange={(e) => {
              const length = e.target.value.length
              if(length < 11){
                field.onChange(e.target.value)
              }
            }}
            autoComplete='off'
          />
        )}
      />
      <Button
        onClick={handleSubmit(onLoginSubmit)}
        disabled={!!errors.mobile_number || values?.mobile_number == undefined} // Disable if there are errors
      >
        {isLoading ? (
          <Box mx='auto' mt={1}>
            <CircularProgress size={20} color='success' />
          </Box>
        ) : (
          'Send OTP'
        )}
      </Button>
      <Typography mt={2} variant='caption' align={mobile ? 'center' : 'left'}>
        By continuing to use our website, you agree to our{' '}
        <Typography
          component={MuiLink}
          variant='caption'
          href='https://evfin.co/privacy.php'
          target='_blank'
          whiteSpace='nowrap'
        >
          Privacy Policy
        </Typography>
        {' '}and
        <Typography
          component={MuiLink}
          variant='caption'
          href='https://evfin.co/tnc.php'
          target='_blank'
          sx={{ marginLeft: '4px' }}
          whiteSpace='nowrap'
        >
          Terms & Conditions
        </Typography>
      </Typography>
    </Layout>
  )
}

export default Login
