import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import LeftSection from 'components/molecules/LeftSection'
import { Typography } from '@mui/material'
import CustomSelect from 'components/molecules/CustomSelect'
import useTestRideVehicleSelection from './useTestRideVehicleSelection'
import EditIcon from 'assets/images/editWhite.svg'
import CustomModal from 'components/molecules/Modal'
import PincodeModal from 'components/molecules/PincodeModal'
import useMobileDevice from 'hooks/useMobileDevice'
import UnavailablePincodeModal from 'components/molecules/UnavailablePincodeModal'
import SuccessModal from 'containers/pincodePage/successModal'
import Heading from 'components/atoms/Heading'

export default function TestRideVehicleSelection() {
  const {
    brandOptions,
    showBrands,
    showModels,
    selectedModel,
    modelOptions,
    addressData,
    isLoading,
    isPincodeModalOpen,
    selectedBrand,
    isSuccessModalOpen,
    isPincodeLoading,
    isUnavailabilityPincodeOpen,
    onPincodeSubmit,
    setIsUnavailabilityPincodeOpen,
    setIsSuccessModalOpen,
    setIsPincodeModalOpen,
    setShowBrands,
    onVehicleSubmit,
    handleSelectVariant,
    handleBrandChange,
    setShowModels,
    handleVariantSelected,
    handleModelChange,
  } = useTestRideVehicleSelection()

  const mobile = useMobileDevice()

  return (
    <>
      <Heading />
      <LeftSection type='bikeImage'>
        <Grid
          item
          xs={mobile ? 12 : 7}
          borderRadius={mobile ? '1.25rem 1.25rem 0 0' : 0}
          bgcolor='#002723'
          minHeight={mobile ? '50vh' : '100vh'}
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            textAlign='center'
            color='#FFF'
            mt={mobile ? '3rem' : '5rem'}
          >
            <Box maxWidth='10rem'>
              <Typography variant='h2'>Schedule a Test Ride</Typography>
              {/* <Typography component={Typography} variant='body' color='#E5F6F5' mt={1}>
                Showing brands at
              </Typography>
              <Box display='flex' gap={1} alignItems='flex-start' justifyContent='center' style={{ cursor: 'pointer' }}>
                <Typography component={Typography} variant='body' color='#E5F6F5' fontWeight='500'>
                  {addressData?.city}
                </Typography>
                <img
                  src={EditIcon}
                  alt='edit'
                  onClick={() => setIsPincodeModalOpen(true)}
                  style={{ marginLeft: '5px', fontWeight: 'bold' }}
                />
              </Box> */}
            </Box>
            <Box
              display='flex'
              flexWrap={mobile ? 'wrap' : 'nowrap'}
              maxWidth={mobile ? '50%' : 'none'}
              my='16px'
              justifyContent='center'
            >
              <Typography variant='bodyStrong' fontWeight={400} textAlign='center' color='#E5F6F5CC'>
                Showing brands at
              </Typography>
              <Box display='flex' alignItems='flex-start' ml='5px' style={{ cursor: 'pointer' }} onClick={() => setIsPincodeModalOpen(true)}>
                <Typography variant='body' textAlign='center' color='#E5F6F5CC' fontWeight='600'>
                  {addressData?.city}
                </Typography>
                <img
                  src={EditIcon}
                  alt='edit'
                  style={{ marginLeft: '5px', fontWeight: 'bold' }}
                />
              </Box>
            </Box>
            <Box
              width='320px'
              display='flex'
              flexDirection='column'
              textAlign='left'
              color='#00A99D'
              mt={mobile ? 2 : 8}
            >
              <CustomSelect
                label='Brand'
                drawerTitle='What’s your favourite EV maker?'
                buttonName='Select a model'
                options={brandOptions}
                open={showBrands}
                setOpen={setShowBrands}
                handleClose={() => setShowBrands(false)}
                handleClick={handleSelectVariant}
                onChange={(option) => handleBrandChange(option)}
                mode='dark'
              />
              <CustomSelect
                label='Variant'
                drawerTitle='Select a Variant'
                buttonName='Done'
                open={showModels}
                disabled={!selectedBrand}
                value={selectedModel}
                setOpen={setShowModels}
                handleClose={() => setShowModels(false)}
                options={modelOptions}
                handleClick={handleVariantSelected}
                onChange={(option) => handleModelChange(option)}
                mode='dark'
              />

              <Button
                sx={{ mt: 2, mb: 5 }}
                onClick={onVehicleSubmit}
                disabled={selectedModel?.vehicleId === '' ? true : false}
              >
                {isLoading ? (
                  <Box mx='auto' mt={1}>
                    <CircularProgress size={20} color='success' />
                  </Box>
                ) : (
                  'Continue'
                )}
              </Button>
            </Box>
          </Box>
        </Grid>
      </LeftSection>
      <CustomModal padding={4} open={isPincodeModalOpen} setOpen={setIsPincodeModalOpen}>
        <PincodeModal
          isLoading={isPincodeLoading}
          onSubmit={onPincodeSubmit}
          title={'Pincode / City'}
          description={'Provide your pincode / city where you are planning to purchase your vehicle'}
        />
      </CustomModal>
      <CustomModal
        padding={4}
        open={isUnavailabilityPincodeOpen}
        setOpen={setIsUnavailabilityPincodeOpen}
      >
        <UnavailablePincodeModal
          setOpen={setIsUnavailabilityPincodeOpen}
          setSuccessModalOpen={setIsSuccessModalOpen}
          isPincodeLoading={isLoading}
          onPincodeSubmit={onPincodeSubmit}
          title={'Sorry, currently we are not in your city.'}
          description={`Explore nearby city pincodes or leave your details, we'll notify you upon launch.`}
          addressLocation='top'
        />
      </CustomModal>

      <CustomModal padding={0} open={isSuccessModalOpen} setOpen={setIsSuccessModalOpen}>
        <SuccessModal />
      </CustomModal>
    </>
  )
}
