import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useMobileDevice from 'hooks/useMobileDevice'

interface Props {
  time: string
}

export default function TimerSection({ time }: Props) {
  const mobile = useMobileDevice()
  const [date, setDate] = useState(null)
  const [timer, setTimer] = useState(null)

  function getTimeDifference(incomingDate) {
    // Convert incoming date string to a JavaScript Date object
    const endDate = new Date(incomingDate)

    // Get the current date
    const currentDate = new Date()

    // Calculate the time difference in milliseconds
    const timeDifference = endDate.getTime() - currentDate.getTime()

    // Calculate days, hours, and minutes
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))

    return { days, hours, minutes }
  }

  useEffect(() => {
    if (time) {
      setDate(new Date(time))
      setTimer(getTimeDifference(time))
    }
  }, [time])
 
  return (
    <>
      {timer && (
        <Box width={mobile ? '80%' : '250px'} display='flex' justifyContent='space-between'>
          <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
            <Typography variant='bodyStrong' fontFamily="'Archivo Expanded', sans-serif" color='#00221F'>{timer?.days && timer?.days >= 0 ? timer?.days : 0}</Typography>
            <Typography variant='caption' color='#00221F'>Days</Typography>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
            <Typography variant='bodyStrong' fontFamily="'Archivo Expanded', sans-serif" color='#00221F'>{timer?.hours && timer?.hours >= 0 ? timer?.hours : 0}</Typography>
            <Typography variant='caption' color='#00221F'>Hours</Typography>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
            <Typography variant='bodyStrong' fontFamily="'Archivo Expanded', sans-serif" color='#00221F'>{timer?.minutes && timer?.minutes >= 0 ? timer?.minutes : 0}</Typography>
            <Typography variant='caption' color='#00221F'>Minutes</Typography>
          </Box>
        </Box>
      )}
      {date && (
        <Typography py={3.5} variant='caption' sx={{ opacity: 0.4 }}>
          Approval is valid till
          {` ${date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}`}
        </Typography>
      )}
    </>
  )
}
