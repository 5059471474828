import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    d1: React.CSSProperties
    d2: React.CSSProperties
    bodyStrong: React.CSSProperties
    body: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    d1?: React.CSSProperties
    d2?: React.CSSProperties
    bodyStrong?: React.CSSProperties
    body?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    d1: true
    d2: true
    bodyStrong: true
    body: true
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 970,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#51B3A7',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#00B6A7',
    },
    background: {
      default: '#000000',
    },
    text: {
      primary: '#082F2F',
      secondary: '#004943',
    },
    divider: '#373737',
  },
  typography: {
    fontFamily: "'IBM Plex Sans', sans-serif",
    d1: {
      fontFamily: "'Archivo Expanded', sans-serif",
      fontSize: '64px',
      fontWeight: 600,
      lineHeight: '62px',
    },
    d2: {
      fontFamily: "'Archivo Expanded', sans-serif",
      fontSize: '48px',
      fontWeight: 500,
    },
    h1: {
      fontFamily: "'Archivo Expanded', sans-serif",
      fontSize: '36px',
      fontWeight: 500,
      lineHeight: '43.2px',
    },
    h2: {
      fontFamily: "'Archivo Expanded', sans-serif",
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    h3: {
      fontFamily: "'Archivo Expanded', sans-serif",
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    subtitle1: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '20px',
    },
    bodyStrong: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
    },
    body: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    caption: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '15px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Archivo Expanded',
          fontSize: '12px !important',
          textTransform: 'capitalize',
          background: 'linear-gradient(169deg, #51B3A7 68.62%, #A4D53A 108.22%)',
          height: '3rem !important',
          borderRadius: '1.5rem',
          color: '#FFF',
          '&.Mui-disabled': {
            color: '#fff',
            background: '#959595',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderRadius: `10px`,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px'
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(5px)',
          background: 'rgba(23, 33, 32, 0.90);',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-track': {
            background: 'linear-gradient(to right, #00A99D, #00655E)',
          },
          '& .MuiSlider-thumb': {
            background: 'linear-gradient(to bottom, #00A99D, #B0D456)',
            border: '5px solid #00655E'
          },
          '& .MuiSlider-markLabel': {
            transform: 'none',
          },
          '& .MuiSlider-markLabel[data-index="1"]': {
            right: '0% !important',
            left: 'auto !important'
          },
          color: '#00A99D',
          height: '6px'
        },
      },
    },
  },
})
