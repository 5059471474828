import React from "react"
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import EditIcon from 'assets/images/editBlue.svg'
import useMobileDevice from "hooks/useMobileDevice"

interface TestRideCardInterface {
  brandLogo: string,
  modelName: string,
  modelDescription: string,
  onEditClick?: () => void
}

export default function TestRideCard({brandLogo, modelName, modelDescription, onEditClick}: TestRideCardInterface) {
  const mobile = useMobileDevice()
  return(
    <Card
      sx={{
        maxWidth: mobile ? 'none' : '400px',
        borderRadius: '10px',
        boxShadow: '0px 4px 43px 0px rgba(0, 0, 0, 0.25)',
        background: 'linear-gradient(108deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 250, 250, 0.00) 83.15%)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: '1.25rem',
        gap: 4
      }}
    >
      <img src={brandLogo} style={{width: mobile ? '30%' : 'auto'}} alt='brand icon' />
      <Box display='flex' alignItems='start' gap={mobile ? 0 : 2}>
        <Box>
          <Typography component={Typography} variant='body' color='#FFF' fontFamily="'Archivo Expanded', sans-serif">
            {modelName}
          </Typography>
          <Typography component={Typography} mt={1} variant='caption' color='#FFF'>
            {modelDescription}
          </Typography>
          
        </Box>
        <img
          src={EditIcon}
          onClick={onEditClick}
          alt='selected'
          style={{
            width: '14px',
            height: '14px',
            marginLeft: '10px',
            cursor: 'pointer',
          }}
        />
      </Box>
    </Card>
  )
}