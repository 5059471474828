import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ReactCardFlip from 'react-card-flip'
import CardLogo from 'assets/images/cardLogo.svg'
import { getPriceFormat } from 'utils/function'
import QRCode from 'react-qr-code'
import { toJpeg } from 'html-to-image'
import DownSvg from 'assets/images/down.svg'
import RightSvg from 'assets/images/right.svg'
import { routes } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import SmartFinDetails from 'containers/plan-details'
import ReactGA from 'react-ga4'

interface Props {
  loanDetails: any
}

export default function LoanCard({ loanDetails }: Props) {
  const [isFlipped, setIsFlipped] = useState(true)
  const [planDetails, setPlanDetails] = useState({
    planId: '',
    showButton: '',
    isOpen: false,
  })
  const componentRef1 = useRef()
  const componentRef2 = useRef()
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      setIsFlipped(false)
    }, 0)
  }, [])

  const handleClick = () => {
    setIsFlipped(!isFlipped)
  }

  function handlePlanDetails(planId, showButton) {
    setPlanDetails({
      planId,
      showButton,
      isOpen: true,
    })
  }

  const captureAndDownloadImages = () => {
    ReactGA.event({
      category: 'Sanction Page',
      action: 'Download ev.fin card',
      label: 'Download card',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    // Create two canvas elements
    const canvas1 = document.createElement('canvas')
    const canvas2 = document.createElement('canvas')

    // Get the rendering contexts of the canvas elements
    const context1 = canvas1.getContext('2d')
    const context2 = canvas2.getContext('2d')

    // Get the data URLs for the images
    toJpeg(componentRef1.current, { cacheBust: true })
      .then((dataUrl1) => {
        const image1 = new Image()
        image1.src = dataUrl1
        image1.onload = () => {
          // Draw the first image onto the first canvas
          canvas1.width = image1.width
          canvas1.height = image1.height
          context1.drawImage(image1, 0, 0)

          toJpeg(componentRef2.current, { cacheBust: true })
            .then((dataUrl2) => {
              const image2 = new Image()
              image2.src = dataUrl2
              image2.onload = () => {
                // Draw the second image onto the second canvas
                canvas2.width = image2.width
                canvas2.height = image2.height
                context2.drawImage(image2, 0, 0)

                // Create a new canvas to combine the two images
                const combinedCanvas = document.createElement('canvas')
                combinedCanvas.width = Math.max(canvas1.width,canvas2.width)
                combinedCanvas.height = canvas1.height + canvas2.height

                const combinedContext = combinedCanvas.getContext('2d')

                // Draw the first image onto the combined canvas
                combinedContext.drawImage(canvas1, 0, 0)

                // Draw the second image next to the first image
                combinedContext.drawImage(canvas2, 0, canvas1.height+20)

                // Convert the combined canvas to a data URL and download it
                const combinedDataUrl = combinedCanvas.toDataURL('image/jpeg')
                const link = document.createElement('a')
                link.download = `${loanDetails?.loanProduct?.productName}_card.jpeg`
                link.href = combinedDataUrl
                link.click()
              }
            })

            .catch((err) => {
              console.log(err)
            })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <ReactCardFlip
        flipSpeedFrontToBack={2}
        flipSpeedBackToFront={2}
        isFlipped={isFlipped}
        flipDirection='horizontal'
      >
        <Box
          onClick={handleClick}
          ref={componentRef1}
          borderRadius='10px'
          sx={{ background: '#282828', cursor: 'pointer' }}
          width='328px'
          height='207px'
        >
          <Box
            sx={{
              background: loanDetails?.isExpired
                ? 'linear-gradient(37deg, #757575 4%, #494949 100%)'
                : 'linear-gradient(37deg, #00A99D 4%, #00655E 100%)',
            }}
            width='328px'
            height='160px'
            borderRadius='10px'
          >
            <Box p='24px' display='flex' justifyContent='space-between' alignItems='center'>
              <Typography
                fontSize='10px'
                fontWeight={500}
                color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}
                sx={{ textTransform: 'capitalize' }}
              >
                {loanDetails?.customerName}
              </Typography>
              <img
                src={CardLogo}
                alt='ev-fin Logo'
                style={{
                  width: '30px',
                  height: '16px',
                }}
              />
            </Box>
            <Box
              px='24px'
              py='4px'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography
                width='168px'
                variant='h2'
                fontSize='20px'
                color='white'
                sx={{ textTransform: 'capitalize' }}
              >
                {loanDetails?.loanProduct?.productName}
              </Typography>
              <Box width='112px' display='flex' flexDirection='column' alignItems='end'>
                <Typography
                  variant='caption'
                  color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}
                >
                  Eligible loan amount
                </Typography>
                <Box width='100%' display='flex' justifyContent='end'>
                  {/* <Typography
                    sx={{ marginLeft: '8px' }}
                    variant='caption'
                    color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}
                  >
                    amount
                  </Typography> */}
                  <Typography
                    fontWeight={600}
                    variant='caption'
                    color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}
                  >
                    {`₹ ${getPriceFormat(loanDetails?.offerDetails?.loanAmount)}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box p='24px' display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='caption' color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}>
                EMI
                <span style={{ fontWeight: 600, marginLeft: '10px' }}>
                  {`₹ ${
                    loanDetails?.offerDetails?.emi
                      ? getPriceFormat(loanDetails?.offerDetails?.emi)
                      : '0'
                  }`}
                </span>
              </Typography>
              <Typography variant='caption' color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}>
                Tenure
                <span style={{ fontWeight: 600, marginLeft: '10px' }}>
                  {`${loanDetails?.offerDetails?.tenure || 0} Months`}
                </span>
              </Typography>
              <Typography variant='caption' color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}>
                ROI
                <span style={{ fontWeight: 600, marginLeft: '10px' }}>
                  {`${loanDetails?.offerDetails?.roi}%`}
                </span>
              </Typography>
            </Box>
          </Box>
          <Box p='16px 24px' display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='caption' color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}>
              Sanction Voucher
            </Typography>
            <Typography
              variant='caption'
              fontWeight={600}
              color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}
            >
              {`Ref ID  ${loanDetails?.loanApplicationReferenceNo}`}
            </Typography>
          </Box>
        </Box>

        <Box
          ref={componentRef2}
          onClick={handleClick}
          borderRadius='10px'
          sx={{ background: '#282828', cursor: 'pointer' }}
          width='328px'
          height='207px'
        >
          <Box
            sx={{
              background: loanDetails?.isExpired
                ? 'linear-gradient(37deg, #757575 4%, #494949 100%)'
                : 'linear-gradient(37deg, #00A99D 4%, #00655E 100%)',
            }}
            width='328px'
            height={loanDetails?.isExpired ? '160px' : '97px'}
            borderRadius='10px'
          >
            <Box p='24px' display='flex' justifyContent='end'>
              <img
                src={CardLogo}
                alt='ev-fin Logo'
                style={{
                  width: '30px',
                  height: '16px',
                }}
              />
            </Box>
            {loanDetails?.isExpired && (
              <Typography
                width='168px'
                variant='h2'
                fontSize='20px'
                color='white'
                sx={{ textTransform: 'capitalize', marginLeft: '100px', marginTop: '6px' }}
              >
                {loanDetails?.loanProduct?.productName}
              </Typography>
            )}
          </Box>
          {loanDetails?.isExpired ? (
            <Box p='16px 24px' display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='caption' color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}>
                Sanction Voucher
              </Typography>
              <Typography
                variant='caption'
                fontWeight={600}
                color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}
              >
                EXPIRED
              </Typography>
            </Box>
          ) : (
            <>
              <Box
                bgcolor='white'
                top={'19px'}
                left={'80px'}
                height='170px'
                width='170px'
                position='absolute'
                borderRadius='120px'
              >
                <QRCode style={{ padding: '32px' }} size={107} value={loanDetails?.qrString} />
              </Box>
              {/* <Box
                color={loanDetails?.isExpired ? '#DCDCDC' : '#E5F6F5'}
                mx='70px'
                mt='60px'
                width='185px'
                textAlign='center'
                fontSize='12px'
                fontWeight={400}
                fontFamily='IBM Plex Sans'
              >
                Present the distinct QR code to the dealer for verification
              </Box> */}
            </>
          )}
        </Box>
      </ReactCardFlip>
      {!loanDetails?.isExpired && (
        <Box mt={2} display='flex' justifyContent='space-evenly'>
          <Typography
            onClick={captureAndDownloadImages}
            sx={{ cursor: 'pointer' }}
            variant='caption'
            fontWeight={600}
            color='primary'
          >
            <img
              src={DownSvg}
              alt='details'
              style={{
                margin: '0 auto',
                marginRight: '4px',
                width: '14px',
                height: '14px',
                marginTop: '2rem',
              }}
            />
            DOWNLOAD
          </Typography>
          {loanDetails?.loanProduct?.productId && (
            <Typography
              sx={{ cursor: 'pointer', display: 'flex', alignItems: 'end' }}
              variant='caption'
              fontWeight={600}
              onClick={() =>{
                setPlanDetails({
                  planId: loanDetails?.loanProduct?.productId,
                  showButton: 'false',
                  isOpen: true,
                })
                ReactGA.event({
                  category: 'Sanction Page',
                  action: 'Plan details modal',
                  label: 'View plan details',
                })
                ReactGA.set({ timestamp: new Date().toISOString() })
              }}
              color='primary'
            >
              <img
                src={RightSvg}
                alt='details'
                style={{
                  margin: '0 auto',
                  marginRight: '4px',
                  width: '14px',
                  height: '14px',
                  marginTop: '2rem',
                }}
              />
              <div style={{ marginTop: '35px' }}>PLAN DETAILS</div>
            </Typography>
          )}
        </Box>
      )}
      <SmartFinDetails
        open={planDetails.isOpen}
        setOpen={setPlanDetails}
        planId={planDetails.planId}
        showButton={planDetails.showButton}
      />
    </>
  )
}
