import request from 'utils/request'
import { SAVE_TEST_RIDE, TEST_RIDE_DEALERS, TEST_RIDE_DETAILS, TEST_RIDE_TIMINGS } from 'constants/apiConstants'

const getTestRideDetails = (testRideScheduleId) => {
  return request.get(TEST_RIDE_DETAILS+`/${testRideScheduleId}`)
}

const getTestRideDealers = (vehicleId) => {
  return request.get(TEST_RIDE_DEALERS+`?custVehicleId=${vehicleId}`)
}

const saveTestRide = (data) => {
  return request.post(SAVE_TEST_RIDE, data)
}

const getTestRideTimings = () => {
  return request.get(TEST_RIDE_TIMINGS)
}

const testRideService = {
  getTestRideDetails,
  getTestRideDealers,
  saveTestRide,
  getTestRideTimings,
}

export default testRideService