import { routes, routesName } from 'constants/routes'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const Heading = () => {
  const location = useLocation()
  const [heading, setTitle] = useState('')
  // Function to set page title

  const setPageTitle = () => {
    const routeTitles = {}

    for (const route in routes) {
      if (routesName[route]) {
        routeTitles[routes[route]] = routesName[route]
      }
    }
    const route = location.pathname
    const title = routeTitles[route] || ''
    if (route.includes('plan-details')) {
      setTitle('Plan details')
    } else if (route.includes('test-ride-confirmation')) {
      setTitle('Confirmation')
    } else setTitle(title)

    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      timestamp: new Date().toISOString(),
      title: route.includes('plan-details') ? 'Plan details' : title,
    })
  }

  useEffect(() => {
    setPageTitle()
  }, [location])

  return (
    <Helmet>
      <title>{heading} | Greave finance</title>
    </Helmet>
  )
}

export default Heading
