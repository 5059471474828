import { useEffect, useState } from 'react'
import useMobileDevice from 'hooks/useMobileDevice'
import confetti from 'canvas-confetti'
import offerService from 'service/offerService'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { routes } from 'constants/routes'

interface OfferInterface {
  offerAmount: number
  offerStatus: 'ELIGIBLE' | 'NOT_ELIGIBLE'
  vehicleName: string
}

const useCongrats = () => {
  const mobile = useMobileDevice()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [offer, setOffer] = useState<OfferInterface>()

  useEffect(() => {
    setIsLoading(true)
    offerService
      .getGenerateOffer()
      .then((res) => {
        setOffer(res.data.data)
      })
      .catch(err => {
        if(err.message === 'Personal details required to generate offer') {
          navigate(routes.DETAILS)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    const count = 200
    const defaults = {
      origin: { x: mobile ? 0.5 : 0.7, y: 0.9 },
    }

    function fire(particleRatio, opts) {
      confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio),
        }),
      )
    }

    if (offer?.offerStatus === 'ELIGIBLE') {
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      })
      fire(0.2, {
        spread: 60,
      })
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      })
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      })
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      })
      setTimeout(() => {
        navigate(routes.PLAN_OPTION)
      }, 5000)
    } else if (offer?.offerStatus === "NOT_ELIGIBLE") {
      navigate(routes.NOT_ELIGIBLE1)
    }
  }, [offer])

  return {
    mobile,
    offer,
    isLoading
  }
}

export default useCongrats
