import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import { SxProps, Theme } from '@mui/material'

interface FooterInterface {
  mobile: boolean
  sx?: SxProps<Theme>
}

export default function Footer({ mobile, sx }: FooterInterface) {
  return (
    <Box
      pt={1}
      px={mobile ? 2 : 5.5}
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      bgcolor='#000'
      sx={{ ...sx }}
    >
      <Box display='flex' justifyContent='space-between' width='100%' alignItems='center' py={2}>
        <Grid
          container
          display='flex'
          alignItems='center'
          rowGap={1}
          flexWrap='wrap'
          justifyContent='space-between'
          width='66.6666%'
        >
          <Grid item xs={12} md={6} display='flex' alignItems='center' sx={{ flexShrink: '0' }}>
            <Typography variant='caption' color='#dcdcdce6'>
              Question
            </Typography>
            <Typography
              component={Link}
              href='mailto:sales@evfin.co'
              target='_blank'
              ml={2}
              variant='caption'
              color='#dcdcdce6'
              sx={{ textDecoration: 'none' }}
            >
              sales@evfin.co
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display='flex'
            sx={{ flexShrink: '0', justifyContent: mobile ? 'start' : 'center' }}
          >
            <Typography
              component={Link}
              href='https://evfin.co/tnc.php'
              target='_blank'
              variant='caption'
              color='#dcdcdce6'
              whiteSpace='nowrap'
              sx={{ textDecoration: 'none' }}
            >
              Terms & Conditions
            </Typography>
            <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
            <Typography
              component={Link}
              href='http://www.greavesfinance.com/privacy-policy/'
              target='_blank'
              variant='caption'
              color='#dcdcdce6'
              whiteSpace='nowrap'
              sx={{ textDecoration: 'none' }}
            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
        <Box display='flex' alignItems='center' justifyContent='space-between' maxWidth={mobile ? 'auto' : '5rem'} width={mobile ? 'auto': '15%'}>
          <Typography
            component={Link}
            href='https://www.facebook.com/profile.php?id=100093237878774'
            target='_blank'
            sx={{ textDecoration: 'none', mr: mobile ? 3 : 0 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='8'
              height='15'
              viewBox='0 0 8 15'
              fill='none'
            >
              <path
                d='M7.47575 8.4375L7.8906 5.72285H5.29677V3.96123C5.29677 3.21855 5.65911 2.49463 6.8208 2.49463H8V0.183398C8 0.183398 6.92991 0 5.90679 0C3.7707 0 2.37444 1.3002 2.37444 3.65391V5.72285H0V8.4375H2.37444V15H5.29677V8.4375H7.47575Z'
                fill='#F3E9E9'
              />
            </svg>
          </Typography>
          <Typography
            component={Link}
            href='https://www.instagram.com/hello_evfin/'
            target='_blank'
            sx={{ textDecoration: 'none', mr: mobile ? 3 : 0 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='15'
              height='15'
              viewBox='0 0 15 15'
              fill='none'
            >
              <path
                d='M7.50167 3.65419C5.3734 3.65419 3.65672 5.37125 3.65672 7.5C3.65672 9.62875 5.3734 11.3458 7.50167 11.3458C9.62995 11.3458 11.3466 9.62875 11.3466 7.5C11.3466 5.37125 9.62995 3.65419 7.50167 3.65419ZM7.50167 10.0003C6.12633 10.0003 5.00195 8.879 5.00195 7.5C5.00195 6.121 6.12298 4.99972 7.50167 4.99972C8.88037 4.99972 10.0014 6.121 10.0014 7.5C10.0014 8.879 8.87702 10.0003 7.50167 10.0003ZM12.4007 3.49688C12.4007 3.99559 11.9992 4.3939 11.5039 4.3939C11.0053 4.3939 10.6071 3.99225 10.6071 3.49688C10.6071 3.00151 11.0086 2.59985 11.5039 2.59985C11.9992 2.59985 12.4007 3.00151 12.4007 3.49688ZM14.9473 4.40729C14.8904 3.20568 14.616 2.1413 13.7359 1.26436C12.8592 0.387426 11.795 0.112964 10.5937 0.0527167C9.35555 -0.0175722 5.64445 -0.0175722 4.4063 0.0527167C3.20831 0.109617 2.14417 0.384079 1.26408 1.26102C0.383993 2.13796 0.112939 3.20233 0.052705 4.40394C-0.0175683 5.64236 -0.0175683 9.35429 0.052705 10.5927C0.109593 11.7943 0.383993 12.8587 1.26408 13.7356C2.14417 14.6126 3.20496 14.887 4.4063 14.9473C5.64445 15.0176 9.35555 15.0176 10.5937 14.9473C11.795 14.8904 12.8592 14.6159 13.7359 13.7356C14.6127 12.8587 14.8871 11.7943 14.9473 10.5927C15.0176 9.35429 15.0176 5.64571 14.9473 4.40729ZM13.3477 11.9215C13.0867 12.5775 12.5814 13.083 11.9222 13.3474C10.935 13.739 8.59258 13.6486 7.50167 13.6486C6.41076 13.6486 4.06497 13.7356 3.08115 13.3474C2.42526 13.0863 1.91997 12.5809 1.65561 11.9215C1.26408 10.9341 1.35443 8.59115 1.35443 7.5C1.35443 6.40885 1.26743 4.06253 1.65561 3.07849C1.91662 2.42246 2.42192 1.91705 3.08115 1.65263C4.06832 1.26102 6.41076 1.35139 7.50167 1.35139C8.59258 1.35139 10.9384 1.26436 11.9222 1.65263C12.5781 1.9137 13.0834 2.41911 13.3477 3.07849C13.7393 4.06588 13.6489 6.40885 13.6489 7.5C13.6489 8.59115 13.7393 10.9375 13.3477 11.9215Z'
                fill='#F3E9E9'
              />
            </svg>
          </Typography>
          <Typography
            component={Link}
            href='https://www.linkedin.com/company/go-evfin/'
            target='_blank'
            sx={{ textDecoration: 'none' }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='15'
              height='15'
              viewBox='0 0 15 15'
              fill='none'
            >
              <path
                d='M3.35759 15H0.247768V4.98527H3.35759V15ZM1.801 3.61917C0.806585 3.61917 0 2.79549 0 1.80104C7.11763e-09 1.32338 0.189748 0.865275 0.527502 0.527514C0.865256 0.189752 1.32335 0 1.801 0C2.27866 0 2.73675 0.189752 3.07451 0.527514C3.41226 0.865275 3.60201 1.32338 3.60201 1.80104C3.60201 2.79549 2.79509 3.61917 1.801 3.61917ZM14.9967 15H11.8935V10.1249C11.8935 8.96303 11.8701 7.47305 10.2767 7.47305C8.65982 7.47305 8.41205 8.73535 8.41205 10.0412V15H5.30558V4.98527H8.28817V6.35137H8.3317C8.74687 5.56452 9.76105 4.73415 11.2741 4.73415C14.4214 4.73415 15 6.80674 15 9.49876V15H14.9967Z'
                fill='#F3E9E9'
              />
            </svg>
          </Typography>
        </Box>
      </Box>
      <Divider flexItem />
      <Box py={2}>
        <Typography variant='caption' color='#dcdcdce6' textAlign='center'>
          © 2023 evfin. All rights reserved.
        </Typography>
      </Box>
    </Box>
  )
}
