import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AnimatedNumbers from 'react-animated-numbers'
import useCongrats from './useCongrats'
import Heading from 'components/atoms/Heading'
import LeftSection from 'components/molecules/LeftSection'

export default function Congrats() {
  const { mobile, offer, isLoading } = useCongrats()
  return (
    <>
      <Heading />
      <LeftSection>
        <Grid
          item
          xs={mobile ? 12 : 7}
          display='flex'
          minHeight={mobile ? 'calc(100vh - 120px)' : '100vh'}
          textAlign='center'
          alignItems='center'
          justifyContent='center'
        >
          {isLoading ? (
            <Box width='23rem' maxWidth='25rem' ml={2.5}>
              <Box>
                <Typography variant='d2' component={Typography} textAlign='left' className='gradientText'>Finalising</Typography>
                <Box className='congratsWrapper'>
                  <Box className='congratsWrapper-alone'>
                    <Typography variant='d2' textAlign='left' className='gradientText'>Your</Typography>
                  </Box>
                  <Box className='congratsWrapper-words'>
                    <Typography variant='d2' className='change gradientText'>Loan</Typography>
                    <Typography variant='d2' className='change gradientText'>Amount</Typography>
                    <Typography variant='d2' className='change gradientText'>Vehicle</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box maxWidth='25rem'>
              <Box maxWidth='12rem'>
                <Typography variant='h3' color='#fff'>
                  Congratulations
                </Typography>
                <Typography className='gradientText' mt={3} fontFamily='Archivo expanded'>
                  Your selected loan amount
                </Typography>
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Typography
                    className='gradientText'
                    variant='h3'
                    lineHeight='70px'
                    fontSize='36px'
                    fontWeight='500'
                  >
                    ₹
                  </Typography>
                  <Box ml={1}>
                    <AnimatedNumbers
                      animateToNumber={Math.round(offer?.offerAmount)}
                      fontStyle={{
                        fontSize: 40,
                        fontFamily: 'Archivo expanded',
                        lineHeight: '70px',
                        background: 'linear-gradient(102deg, #00A99D 36.48%, #B0D456 124.88%)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                      }}
                      includeComma={false}
                      configs={(number, index) => {
                        return { mass: 1, tension: 230 * (index + 1), friction: 140 }
                      }}
                    ></AnimatedNumbers>
                  </Box>
                </Box>
                <Typography
                  component={Typography}
                  mt={2}
                  textAlign='center'
                  variant='body'
                  className='gradientText'
                >
                  For your {offer?.vehicleName}
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </LeftSection>
    </>
  )
}
