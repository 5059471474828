import React, { useEffect, useState } from 'react'
import vehicleService from 'service/vechicleService'
import { useNavigate } from 'react-router'
import { routes } from 'constants/routes'
import ReactGA from 'react-ga4'

const useCard = () => {
  const [vehicleData, setVehicleData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    vehicleService
      .getVehiclePricing()
      .then((res) => {
        setVehicleData(res?.data?.data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const onEditClick = () => {
    navigate(routes.FINANCE)
    ReactGA.event({
      category: 'Loan flow',
      action: 'Scooter Change',
      label: 'Edited by Card',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
  }
  return { vehicleData, onEditClick, isLoading }
}

export default useCard
