import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useSmartFinDetails from './useSmartFinDetails'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CustomAccordian from 'components/molecules/CustomAccordian'
import Footer from 'components/organisms/footer'
import { getPriceFormat } from 'utils/function'
import { Drawer, Skeleton } from '@mui/material'
import Heading from 'components/atoms/Heading'
import closeIcon from 'assets/images/close icon.svg'
import ReactGA from 'react-ga4'

export default function SmartFinDetails({ planId, showButton, open, setOpen }) {
  const {
    mobile,
    accordiantData,
    tableData,
    truncateToTwoDecimalPlaces,
    loading,
    isLoading,
    handleOfferChange,
    handleClose,
  } = useSmartFinDetails({ planId, setOpen })

  return (
    <>
      <Heading />
      {mobile ? (
        <Drawer
          anchor='bottom'
          open={open}
          hideBackdrop
          PaperProps={{
            sx: {
              background: 'linear-gradient(155deg, #082C29 -11.66%, #000 52.77%)',
              backdropFilter: 'blur(30px)',
              borderRadius: mobile ? '1.25rem 1.25rem 0 0' : '1.25rem 0 0 1.25rem',
              maxHeight: 'calc(100vh - 100px)',
            },
          }}
        >
          {loading ? (
            <>
              <Skeleton
                variant='rounded'
                sx={{ mt: 8, mb: 5, mx: 'auto' }}
                width={'200px'}
                height={'90px'}
              />
              <Skeleton
                variant='rounded'
                sx={{ my: 3, mx: 'auto' }}
                width={'400px'}
                height={'150px'}
              />
              <Skeleton
                variant='rounded'
                sx={{ my: 3, mx: 'auto' }}
                width={'500px'}
                height={'450px'}
              />
              <Skeleton
                variant='rounded'
                sx={{ my: 3, mx: 'auto' }}
                width={'500px'}
                height={'140px'}
              />
            </>
          ) : (
            <>
              <Box
                borderRadius='1.25rem 1.25rem 0 0'
                textAlign='center'
                sx={{
                  background: 'linear-gradient(-31deg, #000 34%, #00221F 97.23%)',
                }}
              >
                <Box display='flex' justifyContent='end' alignItems='center'>
                  <img
                    src={closeIcon}
                    alt='close icon'
                    onClick={handleClose}
                    style={{
                      width: '2rem',
                      height: '2rem',
                      marginRight: mobile ? '1rem' : '2rem',
                      marginTop: '1.5rem',
                      cursor: 'pointer',
                    }}
                  />
                </Box>
                <Typography variant='h2' mt={1} mb={4} className='gradientTextWhite'>
                  {tableData?.productName}
                </Typography>
                <Box maxWidth='16rem' display='flex' m='auto'>
                  <Typography variant='body' className='gradientText'>
                    {tableData?.description}
                  </Typography>
                </Box>
                <Box bgcolor='#00221F' textAlign='left' m={2} mt={4} p={3} borderRadius={5}>
                  <Typography
                    variant='caption'
                    fontFamily="'IBM Plex Sans', sans-serif"
                    fontWeight='500'
                    color='#00655E'
                  >
                    Features & Benefits
                  </Typography>
                  <ul
                    style={{
                      color: '#E5F6F5',
                      fontFamily: "'IBM Plex Sans', sans-serif",
                      fontSize: '12px',
                      padding: '0 1.25rem',
                      lineHeight: '1.25rem',
                      marginTop: '1rem',
                    }}
                  >
                    {tableData?.keyBenefits?.length > 0 &&
                      tableData?.keyBenefits.map((data, i) => <li key={i}>{data}</li>)}
                  </ul>
                </Box>
              </Box>
              {tableData?.showBreakup && (
                <Box borderRadius='1.25rem 1.25rem 0 0' bgcolor='#E5F6F5'>
                  <Box borderRadius={5} sx={{ backgroundColor: '#f5fbfb' }}>
                    <Box
                      borderRadius={5}
                      px={3}
                      border='1px solid #E5F6F5'
                      sx={{ backgroundColor: '#FFF' }}
                    >
                      <Typography
                        variant='h2'
                        fontSize='0.75rem'
                        mt={3}
                        textAlign='center'
                        color='#00A99D'
                      >
                        Savings Overview
                      </Typography>
                      <Typography
                        variant='h2'
                        fontSize='1.25rem'
                        mt={3}
                        textAlign='center'
                        color='#00A99D'
                      >
                        {tableData?.productName}
                      </Typography>
                      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                        <Table>
                          <TableBody>
                            {tableData?.emi && (
                              <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                <TableCell sx={{ padding: '1rem 0' }}>EMI</TableCell>
                                <TableCell align='right' sx={{ padding: '1rem 0' }}>
                                  {`₹ ${getPriceFormat(tableData?.emi)}`}
                                </TableCell>
                              </TableRow>
                            )}
                            {tableData?.tenure && (
                              <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                <TableCell
                                  sx={{ padding: '1rem 0' }}
                                >{`Tenure (months)`}</TableCell>
                                <TableCell align='right' sx={{ padding: '1rem 0' }}>
                                  {tableData?.tenure}
                                </TableCell>
                              </TableRow>
                            )}
                            {tableData?.totalEmi && (
                              <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                <TableCell
                                  sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                >
                                  Total EMI
                                </TableCell>
                                <TableCell
                                  align='right'
                                  sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                >
                                  {`₹ ${getPriceFormat(tableData?.totalEmi)}`}
                                </TableCell>
                              </TableRow>
                            )}
                            {tableData?.downPayment && (
                              <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                <TableCell sx={{ padding: '1rem 0' }}>DP</TableCell>
                                <TableCell align='right' sx={{ padding: '1rem 0' }}>
                                  {`₹ ${getPriceFormat(tableData?.downPayment)}`}
                                </TableCell>
                              </TableRow>
                            )}
                            {tableData?.totalAmount && (
                              <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                <TableCell
                                  sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                >
                                  Total paid for {tableData?.tenure / 12} years
                                </TableCell>
                                <TableCell
                                  align='right'
                                  sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                >
                                  {`₹ ${getPriceFormat(tableData?.totalAmount)}`}
                                </TableCell>
                              </TableRow>
                            )}
                            {typeof(tableData?.buybackAmount === 'number') && (
                              <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                <TableCell sx={{ padding: '1rem 0' }}>
                                  Buy back amount after {tableData?.tenure / 12} years
                                </TableCell>
                                <TableCell align='right' sx={{ padding: '1rem 0' }}>
                                  {`₹ ${getPriceFormat(tableData?.buybackAmount)}`}
                                </TableCell>
                              </TableRow>
                            )}
                            {tableData?.totalPaidToEvfin && (
                              <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                <TableCell
                                  sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                >
                                  Total paid for {tableData?.tenure / 12} years to ev.fin
                                </TableCell>
                                <TableCell
                                  align='right'
                                  sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                >
                                  {`₹ ${getPriceFormat(tableData?.totalPaidToEvfin)}`}
                                </TableCell>
                              </TableRow>
                            )}
                            {tableData?.onRoadPrice && (
                              <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                <TableCell
                                  sx={{ padding: '1rem 0' }}
                                >{`ORP [${tableData?.city}]`}</TableCell>
                                <TableCell align='right' sx={{ padding: '1rem 0' }}>
                                  {`₹ ${getPriceFormat(tableData?.onRoadPrice)}`}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box px={3}>
                      <TableContainer
                        component={Paper}
                        sx={{ boxShadow: 'none', bgcolor: 'transparent' }}
                      >
                        <Table>
                          <TableBody>
                            <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                              <TableCell sx={{ padding: '1rem 0', color: '#00655E' }}>
                                Extra amount paid for {tableData?.tenure / 12} years
                              </TableCell>
                              <TableCell align='right' sx={{ padding: '1rem 0', color: '#00655E' }}>
                                {`₹ ${getPriceFormat(tableData?.extraAmount)}`}
                              </TableCell>
                            </TableRow>
                            <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                              <TableCell
                                sx={{ padding: '1rem 0', fontWeight: 500, color: '#00655E' }}
                              >
                                TCO
                              </TableCell>
                              <TableCell
                                align='right'
                                sx={{ padding: '1rem 0', fontWeight: 500, color: '#00655E' }}
                              >
                                {`${truncateToTwoDecimalPlaces(tableData?.tco)}%`}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                  {showButton && (
                    <Box display='flex'>
                      <Button
                        onClick={() => {
                          handleOfferChange(tableData?.productId)
                          ReactGA.event({
                            category: 'Plan Option Page',
                            action: 'Select Plan',
                            label: 'Plan update',
                          })
                          ReactGA.set({ timestamp: new Date().toISOString() })
                        }}
                        disabled={isLoading}
                        sx={{ mt: 3, mb: 4, mx: 'auto', px: '6.25rem' }}
                      >
                        Select {tableData?.productName}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              <Box bgcolor='#E5F6F5'>
                <Box padding='3.25rem 2rem' bgcolor='#00221F' borderRadius='1.25rem 1.25rem 0 0'>
                  <Typography textAlign='center' variant='h2' color='#FFF'>
                    What is {tableData?.productName}, and how does it work?
                  </Typography>
                  <CustomAccordian data={accordiantData} />
                </Box>
              </Box>
            </>
          )}
        </Drawer>
      ) : (
        <Drawer
          anchor='right'
          open={open}
          PaperProps={{
            sx: {
              maxWidth: '50%',
            },
          }}
        >
          {loading ? (
            <>
              <Skeleton
                variant='rounded'
                sx={{ mt: 8, mb: 5, mx: 'auto' }}
                width={'200px'}
                height={'90px'}
              />
              <Skeleton
                variant='rounded'
                sx={{ my: 3, mx: 'auto' }}
                width={'400px'}
                height={'150px'}
              />
              <Skeleton
                variant='rounded'
                sx={{ my: 3, mx: 'auto' }}
                width={'500px'}
                height={'450px'}
              />
              <Skeleton
                variant='rounded'
                sx={{ my: 3, mx: 'auto' }}
                width={'500px'}
                height={'140px'}
              />
            </>
          ) : (
            <>
              <Box
                display='flex'
                alignItems='center'
                flexDirection='column'
                textAlign='center'
                pt='7.25rem'
                boxShadow='0px 10px 24px 0px rgba(0, 169, 157, 0.10)'
                sx={{
                  background: 'linear-gradient(199deg, #00221F -0.11%, #000 86.23%)',
                  borderRadius: '0 0 20px 20px',
                }}
              >
                <Box
                  position='fixed'
                  top='1rem'
                  right='1rem'
                  display='flex'
                  justifyContent='end'
                  alignItems='center'
                >
                  <img
                    src={closeIcon}
                    alt='close icon'
                    onClick={handleClose}
                    style={{
                      width: '2rem',
                      height: '2rem',
                      marginRight: mobile ? '1rem' : '2rem',
                      marginTop: '1.5rem',
                      cursor: 'pointer',
                    }}
                  />
                </Box>
                <Typography variant='h2' className='gradientTextWhite'>
                  {tableData?.productName}
                </Typography>
                <Box display='flex' mt={2} maxWidth='18.75rem'>
                  <Typography variant='body' mt={2} className='gradientText'>
                    {tableData?.description}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  textAlign='left'
                  justifyContent='space-between'
                  borderRadius={2.5}
                  bgcolor='#00221F'
                  p={5}
                  color='#fff'
                  margin='3.375rem 2rem 5.625rem'
                  gap='6rem'
                >
                  <Box maxWidth='40%'>
                    <Typography
                      variant='body'
                      fontFamily="'IBM Plex Sans', sans-serif"
                      fontWeight='500'
                      color='#00655E'
                    >
                      Features & Benefits
                    </Typography>
                  </Box>
                  <Box maxWidth='60%'>
                    <ul
                      style={{
                        color: '#E5F6F5',
                        fontFamily: "'IBM Plex Sans', sans-serif",
                        fontSize: '12px',
                        padding: '0 1.25rem',
                        lineHeight: '1.25rem',
                        margin: 0,
                      }}
                    >
                      {tableData?.keyBenefits?.length > 0 &&
                        tableData?.keyBenefits.map((data, i) => <li key={i}>{data}</li>)}
                    </ul>
                  </Box>
                </Box>
              </Box>
              {tableData?.showBreakup && (
                <Box margin='3rem 4.625rem' borderRadius={5}>
                  <Box borderRadius={5} bgcolor='#E5F6F5'>
                    <Box borderRadius={5} sx={{ backgroundColor: '#f5fbfb' }}>
                      <Box
                        borderRadius={5}
                        px={3}
                        border='1px solid #E5F6F5'
                        sx={{ backgroundColor: '#FFF' }}
                      >
                        <Typography
                          variant='h2'
                          fontSize='1.25rem'
                          mt={4}
                          textAlign='center'
                          color='#00A99D'
                        >
                          {tableData?.productName}
                        </Typography>
                        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                          <Table>
                            <TableBody>
                              {tableData?.emi && (
                                <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                  <TableCell sx={{ padding: '1rem 0' }}>EMI</TableCell>
                                  <TableCell align='right' sx={{ padding: '1rem 0' }}>
                                    {`₹ ${getPriceFormat(tableData?.emi)}`}
                                  </TableCell>
                                </TableRow>
                              )}
                              {tableData?.tenure && (
                                <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                  <TableCell
                                    sx={{ padding: '1rem 0' }}
                                  >{`Tenure (months)`}</TableCell>
                                  <TableCell align='right' sx={{ padding: '1rem 0' }}>
                                    {tableData?.tenure}
                                  </TableCell>
                                </TableRow>
                              )}
                              {tableData?.totalEmi && (
                                <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                  <TableCell
                                    sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                  >
                                    Total EMI
                                  </TableCell>
                                  <TableCell
                                    align='right'
                                    sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                  >
                                    {`₹ ${getPriceFormat(tableData?.totalEmi)}`}
                                  </TableCell>
                                </TableRow>
                              )}
                              {tableData?.downPayment && (
                                <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                  <TableCell sx={{ padding: '1rem 0' }}>DP</TableCell>
                                  <TableCell align='right' sx={{ padding: '1rem 0' }}>
                                    {`₹ ${getPriceFormat(tableData?.downPayment)}`}
                                  </TableCell>
                                </TableRow>
                              )}
                              {tableData?.totalAmount && (
                                <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                  <TableCell
                                    sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                  >
                                    Total paid for {tableData?.tenure / 12} years
                                  </TableCell>
                                  <TableCell
                                    align='right'
                                    sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                  >
                                    {`₹ ${getPriceFormat(tableData?.totalAmount)}`}
                                  </TableCell>
                                </TableRow>
                              )}
                              {typeof(tableData?.buybackAmount === 'number') && (
                                <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                  <TableCell sx={{ padding: '1rem 0' }}>
                                    Buy back amount after {tableData?.tenure / 12} years
                                  </TableCell>
                                  <TableCell align='right' sx={{ padding: '1rem 0' }}>
                                    {`₹ ${getPriceFormat(tableData?.buybackAmount)}`}
                                  </TableCell>
                                </TableRow>
                              )}
                              {tableData?.totalPaidToEvfin && (
                                <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                  <TableCell
                                    sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                  >
                                    Total paid for {tableData?.tenure / 12} years to ev.fin
                                  </TableCell>
                                  <TableCell
                                    align='right'
                                    sx={{ padding: '1rem 0', color: '#00A99D', fontWeight: 500 }}
                                  >
                                    {`₹ ${getPriceFormat(tableData?.totalPaidToEvfin)}`}
                                  </TableCell>
                                </TableRow>
                              )}
                              {tableData?.onRoadPrice && (
                                <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                  <TableCell
                                    sx={{ padding: '1rem 0' }}
                                  >{`ORP [${tableData?.city}]`}</TableCell>
                                  <TableCell align='right' sx={{ padding: '1rem 0' }}>
                                    {`₹ ${getPriceFormat(tableData?.onRoadPrice)}`}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      <Box px={3}>
                        <TableContainer
                          component={Paper}
                          sx={{ boxShadow: 'none', bgcolor: 'transparent' }}
                        >
                          <Table>
                            <TableBody>
                              <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                <TableCell sx={{ padding: '1rem 0', color: '#00655E' }}>
                                  Extra amount paid for {tableData?.tenure / 12} years
                                </TableCell>
                                <TableCell
                                  align='right'
                                  sx={{ padding: '1rem 0', color: '#00655E' }}
                                >
                                  {`₹ ${getPriceFormat(tableData?.extraAmount)}`}
                                </TableCell>
                              </TableRow>
                              <TableRow sx={{ borderBottom: '2px solid #00a99d33' }}>
                                <TableCell
                                  sx={{ padding: '1rem 0', fontWeight: 500, color: '#00655E' }}
                                >
                                  TCO
                                </TableCell>
                                <TableCell
                                  align='right'
                                  sx={{ padding: '1rem 0', color: '#00655E' }}
                                >
                                  {`${truncateToTwoDecimalPlaces(tableData?.tco)}%`}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                    {showButton && (
                      <Box display='flex'>
                        <Button
                          onClick={() => {
                            handleOfferChange(tableData?.productId)
                            ReactGA.event({
                              category: 'Plan Option Page',
                              action: 'Select Plan',
                              label: 'Plan update',
                            })
                            ReactGA.set({ timestamp: new Date().toISOString() })
                          }}
                          disabled={isLoading}
                          sx={{ mt: 3, mb: 4, mx: 'auto', px: '6.25rem' }}
                        >
                          Select {tableData?.productName}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              <Box
                bgcolor='#00221F'
                p={10}
                borderRadius='1.25rem 1.25rem 0 0'
                display='flex'
                justifyContent='space-between'
              >
                <Box maxWidth='40%' position={mobile ? 'static' : 'sticky'} top='40px' height='100px'>
                  <Typography variant='h2' mt={2} fontSize='1.25rem' color='#FFF'>
                    What is {tableData?.productName}, and how does it work?
                  </Typography>
                </Box>
                <Box maxWidth='60%'>
                  <CustomAccordian data={accordiantData} />
                </Box>
              </Box>
            </>
          )}
        </Drawer>
      )}
    </>
  )
}
