const prependUrl = process.env.REACT_APP_ENV === 'production' ? '/apply' : ''

export const apiRoutes = {
  PIN_CODE: `${prependUrl}/pincode`,
  PREFERENCE: `${prependUrl}/preference`,
  PERSONAL_INFO: `${prependUrl}/details`,
  PLAN_OPTION: `${prependUrl}/plan-option`,
  GENERATE_OFFER: `${prependUrl}/congrats`,
  SANCTION_CARD: `${prependUrl}/sanction`,
}
