import React, { useEffect, useState } from 'react'
import vehicleService from 'service/vechicleService'
import { useNavigate } from 'react-router'
import { routes } from 'constants/routes'

interface PropsInterface {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  loadData: () => void
}

const useEditLoan = ({ setOpen, loadData }: PropsInterface) => {
  const [loanData, setLoanData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [payment, setPayment] = useState(0)
  const [loanAmount, setLoanAmount] = useState(0)
  const [id, setId] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    vehicleService
      .getOfferPreference()
      .then((res) => {
        setLoanData(res?.data?.data)
        setPayment(res?.data?.data?.selectedDownPayment)
        setLoanAmount(res?.data?.data?.selectedDownPayment)
        setId(res?.data?.data?.preferenceId)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  useEffect(() => {
    setLoanAmount(loanData?.onRoadPrice - payment)
  }, [payment])

  const onUpdate = async () => {
    setIsLoading(true)
    const apiData = {
      downPayment: payment,
      loanAmount: loanAmount,
    }
    try {
      const response = await vehicleService.updatePreference(apiData, id)
      setIsLoading(false)
      setOpen(false)
      loadData()
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }
  return { loanData, onUpdate, isLoading, payment, setPayment, loanAmount }
}

export default useEditLoan
