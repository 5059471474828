import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Heading from 'components/atoms/Heading'
import useMobileDevice from 'hooks/useMobileDevice'
import EditIcon from 'assets/images/editWhite.svg'
import CustomModal from 'components/molecules/Modal'
import PincodeModal from 'components/molecules/PincodeModal'
import TestRideLeftSection from 'components/molecules/TestRideLeftSection'
import ScheduleDealerCard from 'components/molecules/Cards/ScheduleDealerCard'
import TestRideCard from 'components/molecules/Cards/TestRideCard'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { Controller } from 'react-hook-form'
import useScheduleRide from './useScheduleRide'
import { DateCalendar } from '@mui/x-date-pickers'
import CalendarIcon from 'assets/images/calendar.svg'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import OtpModal from 'components/molecules/OtpModal'
import useHomePage from 'containers/homePage.tsx/useHomePage'
import UnavailablePincodeModal from 'components/molecules/UnavailablePincodeModal'
import SuccessModal from 'containers/pincodePage/successModal'
import MuiLink from '@mui/material/Link'

const ScheduleRide = () => {
  const mobile = useMobileDevice()

  const {
    onPincodeSubmit,
    isLoading: isPincodeLoading,
    isSuccessModalOpen,
    setIsSuccessModalOpen,
    isUnavailabilityPincodeOpen,
    setIsUnavailabilityPincodeOpen,
  } = useHomePage()

  const {
    control,
    errors,
    addressData,
    loadingDealer,
    dealerDetails,
    vehicleDetails,
    selectedDealerId,
    isEditable,
    selectedDealer,
    open,
    isLoading,
    testRideDate,
    showCalendarType,
    testRideTimings,
    values,
    guestToken,
    isOtpModalOpen,
    mobileRef,
    handleOtpSubmit,
    setIsOtpModalOpen,
    setShowCalendarType,
    handleEditVehicle,
    setSelectedDealer,
    setIsEditable,
    scheduleTestRide,
    setTestRideDate,
    setOpen,
    handlePhoneNumberChange,
  } = useScheduleRide(isSuccessModalOpen)

  const disableDates = (date: Dayjs) => {
    const day = date.day()
    return testRideTimings.test_ride_slot_settings.non_working_days.includes(day + 1)
  }

  const tomorrow = dayjs().add(1, 'day')

  return (
    <>
      <Heading />
      <TestRideLeftSection pref={true} image={vehicleDetails.image}>
        <>
          <Grid
            item
            xs={mobile ? 12 : 7}
            width='100%'
            minHeight='100vh'
            display='flex'
            pt={7}
            px={mobile ? 6 : 12}
            alignItems='center'
            flexDirection='column'
            color='white'
            bgcolor='#002723'
          >
            <Typography variant='h2' textAlign='center' maxWidth={mobile ? '60%' : 'none'}>
              Schedule a Test Ride
            </Typography>
            <Box
              display='flex'
              flexWrap={mobile ? 'wrap' : 'nowrap'}
              maxWidth={mobile ? '50%' : 'none'}
              my='16px'
              justifyContent='center'
            >
              <Typography
                variant='bodyStrong'
                fontWeight={400}
                textAlign='center'
                color='#E5F6F5CC'
              >
                Showing brands at
              </Typography>
              <Box display='flex' alignItems='flex-start' ml='5px' style={{ cursor: 'pointer' }}>
                <Typography variant='body' textAlign='center' color='#E5F6F5CC' fontWeight='600'>
                  {addressData?.city}
                </Typography>
                <img
                  src={EditIcon}
                  alt='edit'
                  onClick={() => setOpen(true)}
                  style={{ marginLeft: '5px', fontWeight: 'bold' }}
                />
              </Box>
            </Box>
            <Grid container mt={mobile ? 4 : 7} gap={mobile ? 1 : 4}>
              <Grid item md={4} xs={12} display={mobile ? 'flex' : 'block'} justifyContent='center'>
                <Typography
                  pb={2}
                  variant='h2'
                  textAlign={mobile ? 'center' : 'left'}
                  fontSize={mobile ? '12px' : '20px'}
                  color='white'
                  lineHeight='normal'
                  position='sticky'
                  top={40}
                >
                  Your Preferred Dealer
                </Typography>
              </Grid>
              <Grid item md={7} xs={12} display='flex' flexDirection='column'>
                {loadingDealer === true ? (
                  <Box width='100%' mb={2}>
                    <Skeleton
                      variant='rounded'
                      width='100%'
                      height='90px'
                      sx={{ my: 2, bgcolor: 'grey' }}
                    />
                    <Skeleton
                      variant='rounded'
                      width='100%'
                      height='90px'
                      sx={{ my: 2, bgcolor: 'grey' }}
                    />
                    <Skeleton
                      variant='rounded'
                      width='100%'
                      height='90px'
                      sx={{ my: 2, bgcolor: 'grey' }}
                    />
                  </Box>
                ) : dealerDetails?.length > 0 ? (
                  <Box maxHeight='330px' sx={{ overflowY: 'auto' }} className='schedule-scrollbar'>
                    {dealerDetails.map((dealer) => {
                      if (selectedDealerId && !isEditable) {
                        if (dealer.dealerId === selectedDealerId) {
                          return (
                            <ScheduleDealerCard
                              key={dealer.dealerId}
                              setIsEditable={setIsEditable}
                              setCheckedDealer={setSelectedDealer}
                              data={dealer}
                              state={2}
                            />
                          )
                        }
                      } else {
                        return (
                          <Box mb={2} key={dealer.dealerId}>
                            <ScheduleDealerCard
                              setIsEditable={setIsEditable}
                              setCheckedDealer={setSelectedDealer}
                              data={dealer}
                              state={selectedDealer === dealer.dealerId ? 1 : 0}
                            />
                          </Box>
                        )
                      }
                    })}
                  </Box>
                ) : (
                  <Typography variant='caption'>No records found</Typography>
                )}
              </Grid>
              <Grid item md={4} xs={12} display={mobile ? 'flex' : 'block'} justifyContent='center'>
                <Typography
                  pb={2}
                  variant='h2'
                  maxWidth={mobile ? 'none' : '200px'}
                  fontSize={mobile ? '12px' : '20px'}
                  color='white'
                  lineHeight='normal'
                  textAlign={mobile ? 'center' : 'left'}
                >
                  Your Preferred Date & Time
                </Typography>
              </Grid>
              <Grid
                item
                md={7}
                xs={12}
                display={mobile ? 'flex' : 'block'}
                justifyContent='center'
                flexDirection='column'
              >
                <Box>
                  <TextField
                    name='dateAndTime'
                    fullWidth
                    // value={field?.value ? field?.value : ''}
                    label='Pick a date and time'
                    InputProps={{
                      endAdornment: (
                        <img src={CalendarIcon} style={{ width: '13px', height: '18px' }} />
                      ),
                      readOnly: true,
                    }}
                    value={testRideDate.showValue ? testRideDate.value : ''}
                    className='textFieldDark'
                    onClick={() =>
                      setShowCalendarType((prev) => (prev === 'calendar' ? 'none' : 'calendar'))
                    }
                    autoComplete='off'
                    aria-readonly
                  />
                </Box>
                <Box
                  padding={showCalendarType === 'none' ? '0px' : '1px'}
                  height={showCalendarType === 'none' ? '0%' : '100%'}
                  maxHeight={showCalendarType === 'calendar' ? '334px' : '239px'}
                  overflow='hidden'
                  className='gradient-border heightAnimation'
                  mt={4}
                >
                  {showCalendarType === 'calendar' && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        // minDate={tomorrow}
                        shouldDisableDate={disableDates}
                        className='darkMode'
                        disablePast
                        views={['year', 'month', 'day']}
                        showDaysOutsideCurrentMonth
                        fixedWeekNumber={6}
                        sx={{ width: '100%' }}
                        value={dayjs(testRideDate.date)}
                        onChange={(newDate, status) => {
                          if (status === 'finish') {
                            setTestRideDate({
                              ...testRideDate,
                              date: newDate.format(),
                              value: `${newDate.format('DD')}th ${newDate.format('ddd')} - `,
                              showValue: false,
                            })
                            setShowCalendarType('time')
                          } else {
                            setTestRideDate({
                              ...testRideDate,
                              date: newDate.format(),
                            })
                          }
                        }}
                        dayOfWeekFormatter={(day, date) => date.format('ddd').toUpperCase()}
                      />
                    </LocalizationProvider>
                  )}
                  {showCalendarType === 'time' && (
                    <Box
                      p={1.5}
                      borderRadius={2.5}
                      sx={{
                        backgroundColor: '#002723',
                        backgroundImage:
                          'linear-gradient(108deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 250, 250, 0.00) 83.15%)',
                      }}
                    >
                      <Box display='flex' alignItems='center' justifyContent='space-between' mb={2}>
                        <ChevronLeftIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setShowCalendarType('calendar')}
                        />
                        <Typography component={Typography} variant='body' fontWeight='500'>
                          Select your preferred time
                        </Typography>
                        <Box></Box>
                      </Box>
                      {testRideTimings.test_ride_slot_settings.available_slots.map(
                        (slot, index) => (
                          <Box
                            key={slot.start_time}
                            sx={{
                              borderRadius: '5px',
                              color: '#00A99D',
                              textAlign: 'center',
                              backgroundColor:
                                testRideDate.selectedTime === index
                                  ? '#00221F'
                                  : 'rgba(0, 34, 31, 0.30)',
                              borderBottom:
                                testRideDate.selectedTime === index ? '1px solid #00A99D' : 'none',
                              cursor: 'pointer',
                              mb: 2,
                              px: 0.5,
                              py: 1.5,
                            }}
                            onClick={() => {
                              setTestRideDate({
                                ...testRideDate,
                                selectedSlot: slot,
                                selectedTime: index,
                                value: `${testRideDate.value}${slot.slot_time}`,
                                showValue: true,
                              })
                              setShowCalendarType('none')
                            }}
                          >
                            {slot.slot_time}
                          </Box>
                        ),
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item md={4} xs={12} display={mobile ? 'flex' : 'block'} justifyContent='center'>
                <Typography
                  pb={2}
                  variant='h2'
                  maxWidth={mobile ? 'none' : '200px'}
                  fontSize={mobile ? '12px' : '20px'}
                  color='white'
                  lineHeight='normal'
                  textAlign={mobile ? 'center' : 'left'}
                >
                  Your Contact & Purchase Info
                </Typography>
              </Grid>
              <Grid
                item
                md={7}
                xs={12}
                display={mobile ? 'flex' : 'block'}
                justifyContent='center'
                flexDirection='column'
              >
                <Controller
                  name='name'
                  control={control}
                  rules={{
                    required: 'Name is required',
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      value={values?.name ? values?.name : ''}
                      label='Name'
                      className='autofill textFieldDark'
                      autoComplete='off'
                    />
                  )}
                />
                <Controller
                  name='mobileNumber'
                  control={control}
                  rules={{
                    required: 'Mobile number is required',
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mt: 3 }}
                      inputRef={mobileRef}
                      fullWidth
                      disabled={testRideTimings.mobile ? true : false}
                      value={values?.mobileNumber ? values?.mobileNumber : ''}
                      label='Mobile Number'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment sx={{ color: 'white' }} position='start'>
                            +91 |{' '}
                          </InputAdornment>
                        ),
                      }}
                      type='number'
                      inputProps={{
                        pattern: '[0-9]*',
                      }}
                      autoComplete='off'
                      autoCorrect='off'
                      onChange={(e) => {
                        const length = e.target.value.length
                        if (length < 11) {
                          field.onChange(e.target.value)
                        }
                      }}
                      className={`autofill textFieldDark ${
                        testRideTimings.mobile ? 'disabled' : ''
                      }`}
                    />
                  )}
                />
                {guestToken && (
                  <>
                    <Controller
                      name='purchaseIn'
                      control={control}
                      rules={{
                        required: 'I am planning to purchase is required',
                      }}
                      render={({ field }) => (
                        <FormControl fullWidth sx={{ mt: 3 }} className='textFieldDark'>
                          <InputLabel id='demo-simple-select-label'>
                            I am planning to purchase
                          </InputLabel>
                          <Select
                            {...field}
                            fullWidth
                            MenuProps={{
                              sx: {
                                '&.MuiPopover-root': {
                                  '.MuiBackdrop-root.MuiModal-backdrop': {
                                    background: 'none',
                                    backdropFilter: 'none',
                                  },
                                  '.MuiPaper-root': {
                                    background:
                                      'linear-gradient(163deg, rgba(255, 255, 255, 0.44) 0%, rgba(255, 255, 255, 0.00) 73.14%)',
                                    borderRadius: '10px',
                                    padding: '1px',
                                    backgroundColor: '#002723',
                                  },
                                  '& ul': {
                                    backgroundColor: '#002723',
                                    backgroundImage:
                                      'linear-gradient(114deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 250, 250, 0.00) 113.45%)',
                                    color: '#FFF',
                                    borderRadius: '10px',
                                  },
                                },
                              },
                            }}
                            value={values?.purchaseIn ? values?.purchaseIn : ''}
                            label='I am planning to purchase'
                          >
                            <MenuItem value='Within 7 Days'>Within 7 Days</MenuItem>
                            <MenuItem value='7-15 Days'>7-15 Days</MenuItem>
                            <MenuItem value='After 15 Days'>After 15 Days</MenuItem>
                            <MenuItem value='After 30 Days'>After 30 Days</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                    <Controller
                      name='purchaseMode'
                      control={control}
                      rules={{
                        required: 'Preferred mode of purchase is required',
                      }}
                      render={({ field }) => (
                        <FormControl fullWidth sx={{ mt: 3 }} className='textFieldDark'>
                          <InputLabel id='demo-simple-select-label'>
                            My preferred mode of purchase would be
                          </InputLabel>
                          <Select
                            {...field}
                            fullWidth
                            MenuProps={{
                              sx: {
                                '&.MuiPopover-root': {
                                  '.MuiBackdrop-root.MuiModal-backdrop': {
                                    background: 'none',
                                    backdropFilter: 'none',
                                  },
                                  '.MuiPaper-root': {
                                    background:
                                      'linear-gradient(163deg, rgba(255, 255, 255, 0.44) 0%, rgba(255, 255, 255, 0.00) 73.14%)',
                                    borderRadius: '10px',
                                    padding: '1px',
                                    backgroundColor: '#002723',
                                  },
                                  '& ul': {
                                    backgroundColor: '#002723',
                                    backgroundImage:
                                      'linear-gradient(114deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 250, 250, 0.00) 113.45%)',
                                    color: '#FFF',
                                    borderRadius: '10px',
                                  },
                                },
                              },
                            }}
                            value={values?.purchaseMode ? values?.purchaseMode : ''}
                            label='My preferred mode of purchase would be'
                          >
                            <MenuItem value='Finance with evfin'>Finance with evfin</MenuItem>
                            <MenuItem value='Pay full amount in cash'>
                              Pay full amount in cash
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </>
                )}
              </Grid>
              <Grid item md={4}></Grid>
              <Grid
                item
                md={7}
                xs={12}
                maxWidth='375px'
                display={mobile ? 'flex' : 'block'}
                justifyContent='center'
                flexDirection={'column'}
              >
                <Typography my={2} component={Typography} variant='caption' align={mobile ? 'center' : 'left'}>
                  By continuing to use our website, you agree to our{' '}
                  <Typography
                    component={MuiLink}
                    variant='caption'
                    href='https://evfin.co/privacy.php'
                    target='_blank'
                    whiteSpace='nowrap'
                  >
                    Privacy Policy
                  </Typography>{' '}
                  and
                  <Typography
                    component={MuiLink}
                    variant='caption'
                    href='https://evfin.co/tnc.php'
                    target='_blank'
                    sx={{ marginLeft: '4px' }}
                    whiteSpace='nowrap'
                  >
                    Terms & Conditions
                  </Typography>
                </Typography>
                <Button
                  disabled={
                    !!errors.dateAndTime ||
                    selectedDealer === 0 ||
                    testRideDate.date === null ||
                    testRideDate.showValue === false ||
                    (guestToken && !values.purchaseMode) ||
                    (guestToken && !values.purchaseIn) ||
                    !values.mobileNumber ||
                    values.mobileNumber?.length != 10 ||
                    !values.name
                  }
                  onClick={scheduleTestRide}
                  fullWidth
                  type='submit'
                  sx={{ mb: 5 }}
                >
                  {isLoading ? (
                    <Box mx='auto' mt={1}>
                      <CircularProgress size={20} color='success' />
                    </Box>
                  ) : (
                    'Confirm Test Ride'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Box
            top='100px'
            left={mobile ? '0px' : '3.75rem'}
            position={mobile ? 'absolute' : 'fixed'}
            width={mobile ? '90%' : 'calc(40% - 3.75rem)'}
            mx={2}
            display='flex'
            justifyContent={mobile ? 'center' : 'flex-start'}
          >
            <TestRideCard
              brandLogo={vehicleDetails.brandLogo}
              modelName={vehicleDetails.displayName}
              modelDescription={vehicleDetails.displaySubText}
              onEditClick={handleEditVehicle}
            />
          </Box>
          <CustomModal padding={4} open={open} setOpen={setOpen}>
            <PincodeModal
              isLoading={isLoading}
              onSubmit={onPincodeSubmit}
              title={'Pincode / City'}
              description={
                'Provide your pincode / city where you are planning to purchase your vehicle'
              }
            />
          </CustomModal>

          <CustomModal padding={4} open={isOtpModalOpen} setOpen={setIsOtpModalOpen}>
            <OtpModal
              onSubmit={handleOtpSubmit}
              handlePhoneNumberChange={handlePhoneNumberChange}
            />
          </CustomModal>

          <CustomModal
            padding={4}
            open={isUnavailabilityPincodeOpen}
            setOpen={setIsUnavailabilityPincodeOpen}
          >
            <UnavailablePincodeModal
              setOpen={setIsUnavailabilityPincodeOpen}
              setSuccessModalOpen={setIsSuccessModalOpen}
              isPincodeLoading={isPincodeLoading}
              onPincodeSubmit={onPincodeSubmit}
              title={'Sorry, currently we are not in your city.'}
              description={`Explore nearby city pincodes or leave your details, we'll notify you upon launch.`}
              addressLocation='top'
            />
          </CustomModal>

          <CustomModal padding={0} open={isSuccessModalOpen} setOpen={setIsSuccessModalOpen}>
            <SuccessModal />
          </CustomModal>
        </>
      </TestRideLeftSection>
    </>
  )
}

export default ScheduleRide
