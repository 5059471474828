import React from 'react'
import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material'
import closeIcon from 'assets/images/close icon.svg'

interface DataInterface {
  description: string
  productId: number
  productKey: string
  productName: string
  upsellOrder: number
  upsellText: string
}

interface OffersModelInterface {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  data: DataInterface[]
}

export default function OffersModel({ open, setOpen, data }: OffersModelInterface) {
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          p: 5.25,
          background: '#00655E',
          color: '#fff',
          borderRadius: 5,
          maxHeight: 'none',
          maxWidth: 'none',
          overflow: 'visible',
        },
      }}
    >
      <Box>
        <Box position='relative'>
          <Box position='absolute' top={'-6.5rem'} right={'-2.25rem'} onClick={() => setOpen(false)} >
            <img src={closeIcon} alt='close' style={{cursor:'pointer'}} />
          </Box>
        </Box>
        <Typography variant='h2' textAlign='center' mb={4.5}>
          {data.length} {data.length > 1 ? "offers" : "offer"} available for you
        </Typography>
        <Grid container justifyContent='center' spacing={4} maxWidth='45rem'>
          {data.map((offer, index) => (
            <Grid item xs={6} key={index}>
              <Card
                sx={{
                  background: '#00221F',
                  borderRadius: 2.5,
                  border: '1px solid rgba(81, 179, 167, 0.20)',
                  backdropFilter: 'blur(20px)',
                  color: '#fff',
                  px: 5,
                  py: 4,
                }}
              >
                <Typography variant='body'>{offer.upsellText}</Typography>
                {/* <Typography variant='body' color='#00A99D'>
                  Learn more
                </Typography> */}
                <Box display='flex' alignItems='center' justifyContent='start' mt={2.5}>
                  {/* <Button
                    sx={{
                      fontFamily: "'IBM Plex Sans', sans-serif",
                      height: '2.3rem !important',
                      px: 1.25,
                    }}
                  >
                    Check Eligibility
                  </Button> */}
                  <Typography fontWeight='500' >
                    {offer.productName}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Dialog>
  )
}
