import React from 'react'
import { Box, Typography, Skeleton } from '@mui/material'
import { getPriceFormat } from 'utils/function'
import EditSvg from 'assets/images/edit.svg'
import DisabledEditSvg from 'assets/images/disabledEdit.svg'
import useCard from './useCard'

interface Props {
  isExpired?: boolean
}

const SanctionCard = ({ isExpired = false }: Props) => {
  const { vehicleData, onEditClick, isLoading }: any = useCard()
  return (
    <Box display='flex' justifyContent='space-between'>
      {isLoading ? (
        <Skeleton variant='rounded' sx={{ my: 2 }} width={'100%'} height={40} />
      ) : (
        <>
          <Box>
            <Box display='flex' mb={1} justifyContent='space-between'>
              <Typography
                variant='bodyStrong'
                sx={{
                  width: '250px',
                  whiteSpace: 'nowrap', // Prevent text from wrapping
                  overflow: 'hidden', // Hide overflowing text
                  textOverflow: 'ellipsis',
                  background: isExpired
                    ? 'linear-gradient(133deg, gray 32.37%, #b1b1b1 121.86%)'
                    : 'linear-gradient(133deg, #00B6A7 32.37%, #A4D53A 121.86%)' /* Adjust the colors and direction as needed */,
                  '-webkit-background-clip': 'text' /* Apply the gradient to the text itself */,
                  color: 'transparent',
                }}
                fontWeight={700}
              >
                {vehicleData?.vehicle_pricing_details?.displayName}
              </Typography>
            </Box>
            <Box display='flex' justifyContent='space-between'>
              <Typography color={isExpired ? '#b1b1b1' : 'text'} variant='caption'>
                {`Avg on road price in ${vehicleData?.vehicle_pricing_details?.cityName}`}
              </Typography>
              <Typography color={isExpired ? '#b1b1b1' : 'text'} variant='caption'>
                {`₹ ${getPriceFormat(vehicleData?.vehicle_pricing_details?.onRoadPrice)}`}
              </Typography>
            </Box>
          </Box>
          <img
            src={isExpired ? DisabledEditSvg : EditSvg}
            onClick={() => (isExpired ? {} : onEditClick())}
            alt='edit'
            style={{
              width: '14px',
              height: '14px',
              cursor: isExpired ? 'default' : 'pointer',
            }}
          />
        </>
      )}
    </Box>
  )
}

export default SanctionCard
