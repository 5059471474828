import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import useMobileDevice from 'hooks/useMobileDevice'
import LeftSection from 'components/molecules/LeftSection'
import RollSection from './rollSection'
import TimerSection from './timerSection'
import DealerSection from './dealerSection'
import AccordianSection from './accordianSection'
import ExpiredSection from './expiredSection'
import { Skeleton, useMediaQuery } from '@mui/material'
import VehicleChoiceSection from './vehicleChoiceSection'
import OfferSection from './offerSection'
import vehicleService from 'service/vechicleService'
import { theme } from 'muiTheme/customTheme'
import VoucherRegenerateModal from './VoucherRegenerateModal'
import loanService from 'service/loanService'
import { useNavigate } from 'react-router-dom'
import { routes } from 'constants/routes'
import offerService from 'service/offerService'
import Footer from 'components/organisms/footer'
import Heading from 'components/atoms/Heading'
import VehicleCard from 'components/molecules/Cards/VehicleCard'

const LoanSanction = () => {
  const mobile = useMobileDevice()
  const navigate = useNavigate()
  const [loanDetails, setLoanDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingDealer, setLoadingDealer] = useState(false)
  const [isVoucherModalOpen, setIsVoucherModalOpen] = useState(false)

  const getLoanSanctionDetails = () => {
    vehicleService
      .getLoanSanction()
      .then((result) => {
        setLoanDetails(result?.data?.data)
        setLoadingDealer(false)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getLoanSanctionDetails()
    localStorage.removeItem('selectedVehicle')
  }, [])

  function handleCreateNewApplication() {
    loanService.createNewApplication().then(() => {
      navigate(routes.PINCODE)
    })
  }

  const handleRegenrateOffer = () => {
    offerService.getGenerateOffer().then(() => {
      navigate(routes.CONGRATS)
    })
  }

  return (
    <>
      <Heading />
      <LeftSection>
        <Grid
          item
          xs={mobile ? 0 : 7}
          width='100%'
          height='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          bgcolor='white'
        >
          {loading ? (
            <>
              <Skeleton variant='rounded' sx={{ my: 2, mt: 10 }} width={'100px'} height={'90px'} />
              <Skeleton variant='rounded' sx={{ my: 2 }} width={'320px'} height={'90px'} />
              <Skeleton variant='rounded' sx={{ my: 2 }} width={'340px'} height={'208px'} />
              <Box display='flex' mt={5} width={'340px'} justifyContent='space-between'>
                <Skeleton variant='rounded' sx={{ my: 2 }} width={'100px'} height={'90px'} />
                <Skeleton variant='rounded' sx={{ my: 2 }} width={'100px'} height={'90px'} />
                <Skeleton variant='rounded' sx={{ my: 2 }} width={'100px'} height={'90px'} />
              </Box>
              <Skeleton variant='rounded' sx={{ my: 2 }} width={'240px'} height={'40px'} />
              <Box display='flex' mt={5} mb={2} width={'440px'} justifyContent='space-between'>
                <Skeleton variant='rounded' sx={{ my: 2 }} width={'150px'} height={'90px'} />
                <Skeleton variant='rounded' sx={{ my: 2 }} width={'260px'} height={'90px'} />
              </Box>
            </>
          ) : (
            <Box
              bgcolor='#FFF'
              borderRadius={mobile ? '20px 20px 0 0' : 0}
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              width='100%'
            >
              {!loanDetails?.isExpired && (
                <Box
                  left='5rem'
                  bottom='2rem'
                  position='fixed'
                  display={mobile ? 'none' : 'block'}
                >
                  <VehicleCard />
                </Box>
              )}
              {loanDetails?.isExpired ? (
                <ExpiredSection
                  loanDetails={loanDetails}
                  mobile={mobile}
                  setIsVoucherModalOpen={setIsVoucherModalOpen}
                />
              ) : (
                <RollSection loanDetails={loanDetails} mobile={mobile} />
              )}
              <Box
                width='80%'
                borderTop='1px solid #00A99D'
                display='flex'
                flexDirection='column'
                alignItems='center'
                my={3.5}
                py={3.5}
                mx={6}
              >
                {loanDetails?.isExpired ? (
                  <VehicleChoiceSection isExpired={true} mobile={mobile} />
                ) : (
                  loanDetails && (
                    <>
                      <TimerSection time={loanDetails?.offerDetails?.offerExpiryDate} />
                      <VehicleChoiceSection isExpired={false} mobile={mobile} />
                      {loanDetails?.dealers?.length > 0 && (
                        <DealerSection
                          setLoadingDealer={setLoadingDealer}
                          loadingDealer={loadingDealer}
                          getLoanSanctionDetails={getLoanSanctionDetails}
                          dealers={loanDetails?.dealers || []}
                          selectedDealer={loanDetails?.selectedDealer || null}
                          mobile={mobile}
                          isDealerSelected={loanDetails?.isDealerSelected || false}
                        />
                      )}
                    </>
                  )
                )}
              </Box>
            </Box>
          )}
          {loading ? (
            <Box display='flex' mt={3} mb={8} width={'80%'} justifyContent='space-between'>
              <Skeleton variant='rounded' sx={{ my: 2 }} width={'30%'} height={'300px'} />
              <Skeleton variant='rounded' sx={{ my: 2 }} width={'30%'} height={'300px'} />
              <Skeleton variant='rounded' sx={{ my: 2 }} width={'30%'} height={'300px'} />
            </Box>
          ) : (
            loanDetails?.isExpired == false && <OfferSection />
          )}
          <AccordianSection />
          <Box width='100%' bgcolor='black'>
            <Footer mobile={mobile} sx={{ width: '89%' }} />
          </Box>
          <VoucherRegenerateModal
            open={isVoucherModalOpen}
            setOpen={setIsVoucherModalOpen}
            mobile={mobile}
            handleCreateNewApplication={handleCreateNewApplication}
            handleRegenrateOffer={handleRegenrateOffer}
          />
        </Grid>
      </LeftSection>
    </>
  )
}

export default LoanSanction
