import React, { useEffect, useState } from 'react'
import AuthService from 'service/authService'
import vehicleService from '../../service/vechicleService'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { routes } from 'constants/routes'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const usePincode = (setOpen?: React.Dispatch<React.SetStateAction<boolean>>) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ mode: 'onChange' })
  const [name, setName] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const values = getValues()
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const addressData = JSON.parse(localStorage.getItem('addressData'))
  const isPincodeNumberValid = (Number) => {
    return /^\d{6}$/.test(Number)
  }

  useEffect(() => {
    if (addressData?.postalCode) {
      setValue('pinCode', addressData?.postalCode)
    }
  }, [])

  const appFlow = async (data) => {
    try {
      const response = await vehicleService.getVehicleDetails(data)
      if (response.data.data.availability) {
        navigate(routes.FINANCE)
      } else {
        setModalOpen(true)
        ReactGA.event({
          category: 'Pincode Page',
          action: 'Pincode Unavailable',
          label: 'Unavailable flow',
        })
        ReactGA.set({ timestamp: new Date().toISOString() })
        setName(response?.data?.data?.userName || null)
        if (!currentPath.includes('pincode')) navigate(routes.PINCODE)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onPincodeSubmit = async (data) => {
    ReactGA.event({
      category: 'Pincode Page',
      action: 'Enter Pincode',
      label: 'Pincode Updated',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    setIsLoading(true)
    try {
      const response = await AuthService.sendPincode(data.pinCode)
      localStorage.setItem('addressData', JSON.stringify(response?.data?.data))
      setIsLoading(false)
      appFlow(response.data.data)
      setOpen && setOpen(false)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }
  return {
    control,
    handleSubmit,
    formState: { errors },
    isPincodeNumberValid,
    onPincodeSubmit,
    isLoading,
    modalOpen,
    setModalOpen,
    values,
    name,
    successModalOpen,
    setSuccessModalOpen,
  }
}

export default usePincode
