import React from 'react'
import { Box, Card, Typography } from '@mui/material'
import DoneSvg from 'assets/images/Done.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { theme } from 'muiTheme/customTheme'
import EditSvg from 'assets/images/editWhite.svg'

interface Props {
  state: number
  data: {
    address: string
    dealerId: number
    dealerName: string
  }
  setIsEditable?: any
  setCheckedDealer: any
}

const ScheduleDealerCard = ({ state, data, setCheckedDealer, setIsEditable }: Props) => {
  return (
    <>
      <Box
        sx={{
          // background: state != 0 ? '#00A99D padding-box' : 'linear-gradient(104deg, rgba(255, 255, 255, 0.44) 0%, rgba(255, 255, 255, 0.00) 73.14%) border-box',
          // maxWidth: '375px',
          borderRadius: '10px',
          // borderWidth:'1px',
          // borderStyle: 'solid',
          background: state != 0 ? '#00A99D' : 'linear-gradient(104deg, rgba(255, 255, 255, 0.44) 0%, rgba(255, 255, 255, 0.00) 73.14%)',
        }}
        p='1px'
      >
        <Card
          sx={{
            // maxWidth: '375px',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            textOverflow: 'ellipsis',
            color: 'transparent',
            backgroundImage:
              'linear-gradient(108deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 250, 250, 0.00) 83.15%)',
            backgroundColor: '#002723',
            cursor: 'pointer',
            boxShadow: '0px 4px 43px 0px rgba(0, 0, 0, 0.25)',
            // borderWidth:'1px',
            // borderStyle: 'solid',
            // borderImage: state != 0 ? '#00A99D 1' :'linear-gradient(104deg, rgba(255, 255, 255, 0.44) 0%, rgba(255, 255, 255, 0.00) 73.14%) 1',
          }}
          onClick={() => (state == 0 ? setCheckedDealer(data?.dealerId) : {})}
        >
          <Box sx={{ padding: '20px 25px' }}>
            <Box display='flex' pb={1} justifyContent='space-between'>
              <Typography variant='bodyStrong' color='white'>
                {data?.dealerName}
              </Typography>
              {state != 0 && (
                <img
                  src={DoneSvg}
                  // onClick={() => (state == 1 ? {} : setIsEditable(true))}
                  alt='selected'
                  style={{
                    width: '14px',
                    height: '14px',
                    // cursor: state == 2 ? 'pointer' : 'auto',
                  }}
                />
              )}
            </Box>
            <Typography color='#E5F6F5' variant='caption' sx={{ opacity: 0.8 }}>
              {data?.address}
            </Typography>
          </Box>
        </Card>
      </Box>
      {state != 0 && state!=1 &&   
        <Box display='flex' alignItems='center' justifyContent='end' mt={2}>
          <Typography color='rgba(255, 255, 255, 0.80)' variant='body'>
            Change Dealer
          </Typography>
          <img
            src={EditSvg}
            onClick={() => (state == 1 || state == 0 ? {} : setIsEditable(true))}
            alt='selected'
            style={{
              width: '14px',
              height: '14px',
              marginLeft: '10px',
              // cursor: state == 2 ? 'pointer' : 'auto',
            }}
          />
        </Box>
      }
    </>
  )
}

export default ScheduleDealerCard
