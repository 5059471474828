import request from 'utils/request'
import {
  GET_VEHICLE,
  SAVE_VEHICLE,
  GET_LOAN_PREFERENCE,
  GET_LOAN_SANCTION,
  SAVE_LOAN_PREFERENCE,
  VEHICLE_OFFERS,
  SAVE_DEALER,
  ELIGIBLE_DEALER,
  GET_VEHICLE_PRICING,
  GET_OFFER_PREFERENCE,
  UPDATE_PREFERENCE,
  RECOMMENDED_VEHICLES,
} from 'constants/apiConstants'

const getVehicleDetails = (addressData) => {
  return request.get(`${GET_VEHICLE}/${addressData?.city}`)
}

const saveVehicleDetails = (apiData: any) => {
  return request.post(SAVE_VEHICLE, apiData)
}

const getLoanPreference = () => {
  return request.get(GET_LOAN_PREFERENCE)
}
const getLoanSanction = () => {
  return request.get(GET_LOAN_SANCTION)
}

const saveLoanPreference = (apiData) => {
  return request.post(SAVE_LOAN_PREFERENCE, apiData)
}

const getVehicleOffers = () => {
  return request.get(VEHICLE_OFFERS)
}

const saveDealer = (dealerId) => {
  return request.post(`${SAVE_DEALER}${dealerId}`)
}
const getEligibleDealer = () => {
  return request.get(ELIGIBLE_DEALER)
}
const getVehiclePricing = () => {
  return request.get(GET_VEHICLE_PRICING)
}

const getOfferPreference = () => {
  return request.get(GET_OFFER_PREFERENCE)
}

const updatePreference = (apiData, id) => {
  return request.put(`${UPDATE_PREFERENCE}/${id}`, apiData)
}

const getRecommendedVehicles = () => {
  return request.get(RECOMMENDED_VEHICLES)
}

const vehicleService = {
  getVehicleDetails,
  saveVehicleDetails,
  getLoanPreference,
  getLoanSanction,
  saveLoanPreference,
  getVehicleOffers,
  saveDealer,
  getEligibleDealer,
  getVehiclePricing,
  getOfferPreference,
  updatePreference,
  getRecommendedVehicles,
}

export default vehicleService
