import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import EditIcon from 'assets/images/edit.svg'
import GlobeGif from 'assets/images/globe.gif'
import { CircularProgress, TextField } from '@mui/material'
import CustomSelect from 'components/molecules/CustomSelect'
import PincodeModal from 'components/molecules/PincodeModal'
import CustomModal from '../Modal'
import useUnavailabilityPincodeModal from './useUnavailabilityPincodeModal'
import MuiLink from '@mui/material/Link'

interface UnavailablePincodeModalInterface {
  title?: string
  description?: string
  setSuccessModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  isPincodeLoading: boolean
  onPincodeSubmit: () => void
}

export default function UnavailablePincodeModal({
  title = `We're not live here yet, but we can help!`,
  description = `Leave your contact details and we'll get back to you.`,
  setSuccessModalOpen,
  setOpen,
  isPincodeLoading,
  onPincodeSubmit,
  addressLocation = 'bottom',
}) {
  const {
    addressData,
    unavailableAddressData,
    isLoading,
    mobile,
    isPincodeModalOpen,
    brandOptions,
    modelOptions,
    showBrands,
    showModels,
    selectedBrand,
    userName,
    selectedModel,
    canEditCity,
    setIsPincodeModalOpen,
    setShowBrands,
    setShowModels,
    handleBrandChange,
    handleModelChange,
    handleSelectVariant,
    handleVariantSelected,
    onVehicleSubmit,
    setName,
  } = useUnavailabilityPincodeModal(setSuccessModalOpen, setOpen)

  return (
    <>
      <Box
        sx={{
          borderRadius: 5,
          px: 4,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          overflowY: 'auto',
        }}
      >
        <img
          src={GlobeGif}
          alt='scooter'
          style={{
            margin: mobile ? 'auto' : ' 0 -10px',
            width: '4rem',
            height: '4rem',
            marginTop: '1.4rem',
          }}
        />

        <Typography
          variant='h2'
          textAlign={mobile ? 'center' : 'left'}
          sx={{ my: 2, pt: mobile ? 0 : 2 }}
        >
          {title}
        </Typography>
        <Typography
          textAlign={mobile ? 'center' : 'left'}
          color='text.secondary'
        >
          {description}
        </Typography>
        {addressLocation === 'top' && (
          <Box
            display='flex'
            alignItems='center'
            mt={1}
            justifyContent={mobile? 'center' : 'flex-start'}
            style={{ cursor: 'pointer' }}
            sx={{ mb: mobile ? 3 : 7 }}
          >
            Location
            <Typography ml={1} textAlign='center' color='text.secondary' fontWeight='600'>
              {unavailableAddressData?.city || addressData?.city}
            </Typography>
            <img
              onClick={() => setIsPincodeModalOpen(true)}
              src={EditIcon}
              alt='edit'
              style={{ marginLeft: '5px', fontWeight: 'bold' }}
            />
          </Box>
        )}
        <TextField
          onChange={(e) => setName(e.target.value)}
          value={userName}
          fullWidth
          label='Your Name'
          sx={{ my: 1.5 }}
        />

        <CustomSelect
          label='Brand'
          drawerTitle='Pick a brand'
          buttonName='Select Model'
          options={brandOptions}
          open={showBrands}
          setOpen={setShowBrands}
          handleClose={() => setShowBrands(false)}
          handleClick={handleSelectVariant}
          onChange={(option) => handleBrandChange(option)}
        />
        <CustomSelect
          label='Variant'
          drawerTitle='Select a variant'
          disabled={!selectedBrand}
          buttonName='Done'
          value={selectedModel}
          open={showModels}
          setOpen={setShowModels}
          handleClose={() => setShowModels(false)}
          handleClick={handleVariantSelected}
          options={modelOptions}
          onChange={(option) => handleModelChange(option)}
        />
        <Button
          disabled={
            typeof selectedModel.losVehicleId != 'number' ||
            typeof selectedModel.vehicleId != 'number' ||
            userName == null ||
            userName == ''
          }
          sx={{ my: 3 }}
          onClick={onVehicleSubmit}
        >
          {isLoading ? (
            <Box mx='auto' mt={1}>
              <CircularProgress size={20} color='success' />
            </Box>
          ) : (
            'Next'
          )}
        </Button>
        {addressLocation === 'bottom' && (
          <Box
            display='flex'
            alignItems='center'
            m={mobile ? '8px 0px 20px 0px' : '10px 0px'}
            justifyContent={'center'}
            style={{ cursor: 'pointer' }}
          >
            Location
            <Typography ml={1} textAlign='center' color='text.secondary' fontWeight='600'>
              {unavailableAddressData?.city || addressData?.city}
            </Typography>
            <img
              onClick={() => setIsPincodeModalOpen(true)}
              src={EditIcon}
              alt='edit'
              style={{ marginLeft: '5px', fontWeight: 'bold' }}
            />
          </Box>
        )}
        <Typography my={2} variant='caption' align={mobile ? 'center' : 'left'}>
          By continuing to use our website, you agree to our{' '}
          <Typography
            component={MuiLink}
            variant='caption'
            href='https://evfin.co/privacy.php'
            target='_blank'
            whiteSpace='nowrap'
          >
            Privacy Policy
          </Typography>{' '}
          and
          <Typography
            component={MuiLink}
            variant='caption'
            href='https://evfin.co/tnc.php'
            target='_blank'
            sx={{ marginLeft: '4px' }}
            whiteSpace='nowrap'
          >
            Terms & Conditions
          </Typography>
        </Typography>
      </Box>

      <CustomModal padding={4} open={isPincodeModalOpen} setOpen={setIsPincodeModalOpen}>
        <PincodeModal isLoading={isPincodeLoading} onSubmit={(data) => {
          onPincodeSubmit(data)
          setIsPincodeModalOpen(false)
        }} />
      </CustomModal>
    </>
  )
}
