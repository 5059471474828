import React from 'react'
import { Box, Typography, TextField, Button } from '@mui/material'
import { Controller } from 'react-hook-form'
import GlobeGif from 'assets/images/globe.gif'
import { useForm } from 'react-hook-form'
import useMobileDevice from 'hooks/useMobileDevice'
import CircularProgress from '@mui/material/CircularProgress'

interface PincodeModalInterface {
  title?: string
  description?: string
  onSubmit: (data) => void,
  isLoading: boolean
}

export default function PincodeModal({onSubmit, isLoading, title = 'Pincode', description = 'Provide your pincode of your city where you are planning to purchase your vehicle'}: PincodeModalInterface) {
  const {
    control,
    handleSubmit,
  } = useForm({ mode: 'onChange' })

  const mobile = useMobileDevice()

  return (
    <Box px='40px' mt={mobile ? 6 : 0} mb={mobile ? 4 : 0}>
      <Box display='flex' justifyContent={mobile ? 'center' : 'start'}>
        <img style={{ width: '59px' }} src={GlobeGif} alt='globe' />
      </Box>
      <Typography variant='h2' textAlign={mobile ? 'center' : 'start'} color='text.primary'>
        Your
      </Typography>
      <Typography variant='h2' textAlign={mobile ? 'center' : 'start'} color='text.primary'>
        {title}
      </Typography>
      <Box pt='10px'>
        <Typography variant='body' color='text.secondary'>
          {description}
        </Typography>
      </Box>
      <Box pt={mobile ? '20px' : '30px'} pb={mobile ? '20px' : '0px'}>
        <Controller
          name='pinCode'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              value={field?.value ? field?.value : ''}
              style={{ borderRadius: '10px' }}
              label='Enter pincode'
              type='number'
              inputProps={{
                pattern: '[0-9]*'
              }}
            />
          )}
        />
      </Box>
      <Button
        onClick={handleSubmit(onSubmit)}
        style={{ width: '100%', marginTop: mobile ? '10px' : '20px', marginBottom: '20px' }}
      >
        {isLoading ? (
          <Box mx='auto' mt={1}>
            <CircularProgress size={20} color='success' />
          </Box>
        ) : (
          'Done'
        )}
      </Button>
    </Box>
  )
}