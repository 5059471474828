import { useEffect, useState } from 'react'
import AuthService from 'service/authService'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { routes } from 'constants/routes'
import ReactGA from 'react-ga4'
import useMobileDevice from 'hooks/useMobileDevice'

const useLogin = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({ mode: 'onChange', defaultValues: {mobile_number: ''} })

  const [isLoading, setIsLoading] = useState(false)
  const values = watch()
  const navigate = useNavigate()
  const mobile = useMobileDevice()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if(token !== null) {
      navigate(routes.HOME)
    }
  },[])

  const isMobileNumberValid = (mobileNumber) => {
    return /^\d{10}$/.test(mobileNumber)
  }

  const onLoginSubmit = async (data) => {
    ReactGA.event({
      category: 'Login Page',
      action: 'User Login',
      label: 'Mobile No Submit',
    })

    ReactGA.set({ timestamp: new Date().toISOString() })
    setIsLoading(true)
    try {
      const response = await AuthService.sendOtp({ mobile: data?.mobile_number })
      setIsLoading(false)
      localStorage.clear()
      localStorage.setItem('responseIdentifier', response.data.data.responseIdentifier)
      localStorage.setItem('mobileNumber', data.mobile_number)
      localStorage.setItem('count', '0')
      navigate(routes.VERIFYOTP)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }
  return {
    control,
    mobile,
    handleSubmit,
    formState: { errors },
    isMobileNumberValid,
    onLoginSubmit,
    isLoading,
    values,
  }
}

export default useLogin
