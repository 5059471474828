import request from 'utils/request'
import { CREATE_NEW_APPLICATION } from 'constants/apiConstants'

const createNewApplication = () => {
  return request.put(CREATE_NEW_APPLICATION)
}

const loanService = { 
  createNewApplication
}

export default loanService
