import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import useMobileDevice from 'hooks/useMobileDevice'
import { SxProps, Theme } from '@mui/material'

interface PropsInterface {
  totalNoOfSteppers: number
  currentStepper: number
  sx?: SxProps<Theme>
}

export default function Stepper({ totalNoOfSteppers, currentStepper, sx }: PropsInterface) {
  const [remainingPercentage, setRemainingPercentage] = useState<string>('0%')
  const [currentPercentage, setCurrentPercentage] = useState<string>('0%')

  const mobile = useMobileDevice()

  useEffect(() => {
    const currentPercent = (currentStepper / totalNoOfSteppers) * 100
    const remainingPercent = 100 - currentPercent
    setCurrentPercentage(currentPercent + '%')
    setRemainingPercentage(remainingPercent + '%')
  }, [currentStepper])

  return (
    <Box width='100%' display='flex' sx={{ ...sx }}>
      <Box
        width={currentPercentage}
        sx={{ borderRadius: mobile && currentPercentage !== '100%' ? '0 1.5px 1.5px 0' : 0 }}
        height={mobile ? '3px' : '6px'}
        bgcolor='#00A99D'
      ></Box>
      <Box
        width={remainingPercentage}
        height={mobile ? '3px' : '6px'}
        bgcolor='#00A99D'
        sx={{ opacity: '0.2' }}
      ></Box>
    </Box>
  )
}
