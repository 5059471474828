import {
  ACTIVE_OFFER,
  CLAIM_OFFER,
  GENERATE_OFFERS,
  OTHER_OFFER,
  UPDATE_PRODUCT,
  PLAN_DETAILS,
} from 'constants/apiConstants'
import request from 'utils/request'

interface ClaimOffer {
  emi: number
  tenure: number
}

const getGenerateOffer = () => {
  return request.get(GENERATE_OFFERS)
}

const getActiveOffer = () => {
  return request.get(ACTIVE_OFFER)
}

const getOtherOffer = () => {
  return request.get(OTHER_OFFER)
}

const getUpdateProduct = (productId) => {
  return request.get(UPDATE_PRODUCT + `?productId=${productId}`)
}

const postClaimOffer = (preferenceId: number, data: ClaimOffer) => {
  return request.post(CLAIM_OFFER + `/${preferenceId}`, data)
}

const getPlanDetails = (productId) => {
  return request.get(PLAN_DETAILS + `?productId=${productId}`)
}

const offerService = {
  getGenerateOffer,
  getActiveOffer,
  getOtherOffer,
  getUpdateProduct,
  postClaimOffer,
  getPlanDetails,
}

export default offerService
