const prependUrl = process.env.REACT_APP_ENV === 'production' ? '/apply' : ''

export const routes = {
  DIRECT: `${prependUrl}/`,
  HOME: `${prependUrl}/home`,
  MODAL: `${prependUrl}/modal`,
  VERIFYOTP: `${prependUrl}/verify-otp`,
  FAQ: `${prependUrl}/faq`,
  LOGIN: `${prependUrl}/login`,
  PINCODE: `${prependUrl}/pincode`,
  FINANCE: `${prependUrl}/finance`,
  DETAILS: `${prependUrl}/details`,
  SANCTION: `${prependUrl}/sanction`,
  PREFERENCE: `${prependUrl}/preference`,
  CONGRATS: `${prependUrl}/congrats`,
  SOMETHING_WRONG: `${prependUrl}/something-went-wrong`,
  PLAN_OPTION: `${prependUrl}/plan-option`,
  NOT_ELIGIBLE1: `${prependUrl}/loan-not-eligible`,
  PLAN_DETAILS: `${prependUrl}/plan-details`,
  NOT_ELIGIBLE: 'not-eligible',
  RIDE_CONFIRMATION: 'ride-confirmation',
  SCHEDULE_RIDE: `${prependUrl}/schedule`,
  TEST_RIDE_VEHICLE_SELECTION: `${prependUrl}/test-ride-vehicle-selection`,
  TEST_RIDE_CONFIRMATION: `${prependUrl}/test-ride-confirmation/:id`,
}

export const routesName = {
  DIRECT: 'Login',
  HOME: 'Home',
  MODAL: 'Modals',
  NOT_ELIGIBLE: 'Not Eligible',
  RIDE_CONFIRMATION: 'Ride Confirmation',
  VERIFYOTP: 'Verify OTP',
  FAQ: 'Faq',
  LOGIN: 'Login',
  PINCODE: 'Pincode',
  FINANCE: 'Finance',
  DETAILS: 'User details',
  SANCTION: 'Sanction',
  PREFERENCE: 'Preference',
  CONGRATS: 'Congrats',
  SOMETHING_WRONG: 'Something went wrong',
  PLAN_OPTION: 'Plan option',
  NOT_ELIGIBLE1: 'Eligible',
  PLAN_DETAILS: 'Plan details',
  SCHEDULE_RIDE: 'Schedule Ride',
  TEST_RIDE_VEHICLE_SELECTION: 'Vehicle selection',
  TEST_RIDE_CONFIRMATION: 'Confirmation'
}
