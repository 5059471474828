import React from 'react'
import { Card, Typography } from '@mui/material'
import Scooter from 'assets/images/scooter.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { theme } from 'muiTheme/customTheme'
import vehicleService from 'service/vechicleService'
import { useNavigate } from 'react-router-dom'
import { routes } from 'constants/routes'
import ReactGA from 'react-ga4'

interface Props {
  data: any
}

const OfferCard = ({ data }: Props) => {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const addressData = JSON.parse(localStorage.getItem('addressData'))
  const navigate = useNavigate()
  const onVehicleSubmit = async () => {
    ReactGA.event({
      category: 'Sanction Page',
      action: 'Scooter Updated',
      label: 'Scooter Updated',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    const data1 = {
      availability: true,
      city: data?.cityName,
      customerName: null,
      loanApplicationId: null,
      losVehicleId: data.losVehicleId,
      pinCode: addressData.postalCode,
      selectedFor: 'LOAN_APP',
      vehicleId: data.vehicleId,
    }
    try {
      const response = await vehicleService.saveVehicleDetails(data1)
      navigate(routes.PREFERENCE)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <Card
      sx={{
        minWidth: '150px',
        overflow:'visible',
        padding: mobile ? '15px' : '24px 20px',
        borderRadius: '10px',
        margin: '5px',
      }}
    >
      <Typography
        variant='h3'
        style={{
          overflow: 'hidden',
          // whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          height: '45px',
          fontSize: '14px',
        }}
        color='#505D68'
        textAlign='center'
        fontWeight={500}
      >
        {data?.displayName}
      </Typography>
      <img
        src={data?.image || Scooter}
        alt='Scooter'
        style={{
          width: '84px',
          height: '84px',
          marginTop: '15px',
          marginBottom: '25px',
          border: '1px solid rgba(8, 44, 41, 0.10)',
          borderRadius: '90px',
          boxShadow: ' 0px 8px 24px 0px rgba(8, 44, 41, 0.05)',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
        }}
      />
      {/* DATA NOT AVAILABLE -> COMMENTING BELOW CODE */}

      {/* <Typography variant='caption' fontSize='10px'>
        Top Speed
      </Typography>
      <Typography variant='caption' mt={1} mb={2} color='#00221F' fontWeight={500}>
        90km/h
      </Typography>
      <Typography variant='caption' fontSize='10px'>
        True Range
      </Typography>
      <Typography variant='caption' mt={1} mb={3} color='#00221F' fontWeight={500}>
        120km
      </Typography> */}
      <Typography
        fontSize='10px'
        color='#00A99D'
        textTransform='uppercase'
        textAlign='center'
        fontWeight={600}
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
        }}
        onClick={() => onVehicleSubmit()}
      >
        Switch Now
      </Typography>
    </Card>
  )
}

export default OfferCard
