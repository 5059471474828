import React from 'react'
import Layout from 'components/organisms/Layout'
import { Box, Typography, CircularProgress } from '@mui/material'
import useVerifyOtp from './useVerifyOtp'
import OtpGif from '../../../src/assets/images/otp.gif'
import { routes } from 'constants/routes'
import CustomButton from 'components/atoms/Button'
import OTPInput from 'react-otp-input'
import { Link } from 'react-router-dom'
import Heading from 'components/atoms/Heading'

const VerifyOtp = () => {
  const {
    handleSubmit,
    otp,
    phoneNumber,
    mobile,
    handleChange,
    numberToShow,
    onLoginSubmit,
    onResendOtp,
    isLoading,
    isResend,
  } = useVerifyOtp()

  return (
    <Layout
      icon={OtpGif}
      title={
        <>
          Verify <div>with OTP!</div>
        </>
      }
      description={
        <Typography variant='body'>
          We have sent the Verification code to {numberToShow()}.
          <Box>
            <Link style={{ color: '#00B6A7' }} to={routes.LOGIN}>
              Change phone number?
            </Link>
          </Box>
        </Typography>
      }
    >
      <Heading />
      <Box display='flex' maxWidth='100%' style={{ fontSize: '10px' }}>
        <OTPInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          skipDefaultStyles
          inputType='number'
          shouldAutoFocus={true}
          renderInput={(props) => <input {...props} className='customInput' />}
        />
      </Box>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        gap={mobile ? '1rem' : '4.2rem'}
      >
        <Typography variant='caption'>
          Didnt get an SMS code.
          <Typography
            onClick={onResendOtp}
            variant='caption'
            color='#00B6A7'
            ml={1}
            sx={{ cursor: 'pointer' }}
          >
            {isResend}
          </Typography>
        </Typography>
        <CustomButton
          disabled={otp?.length != 6}
          onClick={handleSubmit(onLoginSubmit)}
          sx={{ width: '108px' }}
        >
          {isLoading ? (
            <Box mx='auto' mt={1}>
              <CircularProgress size={20} color='success' />
            </Box>
          ) : (
            'Verify'
          )}
        </CustomButton>
      </Box>
    </Layout>
  )
}

export default VerifyOtp
