import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { routes } from '../constants/routes'
import PerfectScooter from 'containers/perfectScooter'
import VerifyOtp from 'containers/verifyOtp'
import LandingPage from 'containers/landingPage'
import PageNotFound from 'containers/pages/404'
import LoginPage from 'containers/loginPage'
import PincodePage from 'containers/pincodePage'
import Finance from 'containers/finance'
import Details from 'containers/details'
import LoanSanction from 'containers/sanction'
import Preference from '../containers/preference'
import Congrats from 'containers/congrats'
import LoanNotEligible from 'containers/loanNotEligible'
import PlanOption from 'containers/planOption'
import SomethingWentWrong from 'containers/pages/500'
import ScheduleRide from 'containers/scheduleRide'
import TestRideVehicleSelection from 'containers/testRideVehicleSelection'
import TestRideConfirmation from 'containers/testRideConfirmation.tsx'
import HomePage from 'containers/homePage.tsx'
import AppLogo from 'components/atoms/AppLogo'

const AppRoutes = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<Navigate to={routes.HOME} />} />
          <Route path={routes.HOME} element={<HomePage />} />
          <Route path={'/apply'} element={<Navigate to={routes.HOME} />} />
          <Route path={routes.LOGIN} element={<LoginPage />} />
          <Route path={routes.MODAL} element={<PerfectScooter />} />
          <Route path={routes.VERIFYOTP} element={<VerifyOtp />} />
          <Route path={routes.FAQ} element={<LandingPage />} />
          <Route path={routes.PINCODE} element={<PincodePage />} />
          <Route path={routes.FINANCE} element={<Finance />} />
          <Route path={routes.DETAILS} element={<Details />} />
          <Route path={routes.SANCTION} element={<LoanSanction />} />
          <Route path={routes.PREFERENCE} element={<Preference />} />
          <Route path={routes.CONGRATS} element={<Congrats />} />
          <Route path={routes.PLAN_OPTION} element={<PlanOption />} />
          <Route path={routes.SCHEDULE_RIDE} element={<ScheduleRide />} />
          <Route path={routes.NOT_ELIGIBLE1} element={<LoanNotEligible />} />
          <Route path={routes.SOMETHING_WRONG} element={<SomethingWentWrong />} />
          <Route path={routes.TEST_RIDE_VEHICLE_SELECTION} element={<TestRideVehicleSelection />} />
          <Route path={routes.TEST_RIDE_CONFIRMATION} element={<TestRideConfirmation />} />
          <Route path={'*'} element={<PageNotFound />} />
          {/* <Route path={'/'} element={<Navigate to={routesUrls.AGENT_LOGIN} />} />
          <Route path={routesUrls.AGENT_LOGIN} element={<LoginAgent />} />
          <Route path={`${routesUrls.APPLICANT_DETAILS}/:id`} element={<ApplicationDetails />} />
          <Route element={<RestrictedRoutes />}>
            <Route path={routesUrls.DASHBOARD} element={<Dashboard />} />
            <Route path={routesUrls.CREATE_QR_CODE} element={<QrcodeForm />} />
            <Route path={routesUrls.GENERATE_QR_CODE} element={<GeneratedQrCode />} />
            <Route path={`${routesUrls.SHOP_DETAILS}/:id`} element={<ShopDetails />} />
          </Route> */}
        </Routes>
        <AppLogo />
      </BrowserRouter>
    </div>
  )
}

export default AppRoutes
