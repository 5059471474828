import { routes } from 'constants/routes'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import testRideService from 'service/testRideService'
import vehicleService from 'service/vechicleService'
import ReactGA from 'react-ga4'

interface TimeSlotsInterface {
  start_time: string
  end_time: string
  start_time_hour: number
  start_time_minutes: number
  end_time_hour: number
  end_time_minutes: number
  slot_time: string
}

interface TestRideTimings {
  test_ride_slot_settings: {
    available_slots: TimeSlotsInterface[]
    non_working_days: number[]
  }
  mobile: string
  display_name: string
}

const useScheduleRide = (isSuccessModalOpen) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({ mode: 'onSubmit' })
  const navigate = useNavigate()
  const values = watch()

  const addressData = JSON.parse(localStorage.getItem('addressData'))
  const [open, setOpen] = useState(false)
  const [dealerDetails, setDealerDetails] = useState(null)
  const [loadingDealer, setLoadingDealer] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false)
  const [testRideScheduleId, setTestRideScheduleId] = useState(null)
  const [testRideDate, setTestRideDate] = useState({
    showValue: false,
    value: '',
    selectedTime: null,
    date: dayjs(new Date()).format(),
    selectedSlot: {
      start_time: '',
      end_time: '',
      start_time_hour: 0,
      start_time_minutes: 0,
      end_time_hour: 0,
      end_time_minutes: 0,
    },
  })
  const [showCalendarType, setShowCalendarType] = useState('none')
  const [testRideTimings, setTestRideTimings] = useState<TestRideTimings>({
    test_ride_slot_settings: {
      available_slots: [],
      non_working_days: [],
    },
    mobile: '',
    display_name: '',
  })
  const [selectedDealer, setSelectedDealer] = useState(
    parseInt(localStorage.getItem('selectedDealerId')) || 0,
  )
  const [vehicleDetails, setVehicleDetails] = useState({
    brandLogo: '',
    displayName: '',
    displaySubText: '',
    id: 0,
    vehicleId: 0,
    image: ''
  })

  const selectedDealerId = parseInt(localStorage.getItem('selectedDealerId'))
  const guestToken = localStorage.getItem('guestToken')
  const [isSuccessModal, setIsSuccessModal] = useState(false)
  const mobileRef = useRef(null)
  const prependUrl = process.env.REACT_APP_ENV === 'production' ? '/apply' : ''

  useEffect(() => {
    getLoanSanctionDetails()
  }, [])

  useEffect(() => {
    if (isSuccessModalOpen) {
      setOpen(false)
      setIsSuccessModal(true)
    }
    if(isSuccessModal && !isSuccessModalOpen) {
      const testRide = localStorage.getItem('test_ride')
      navigate(routes[testRide])
    }
  }, [isSuccessModalOpen])

  useEffect(() => {
    if(selectedDealer !== 0) {
      window.scroll({
        top: document.body.offsetHeight,
        left: 0,
        behavior: 'smooth'
      })
    }
  }, [selectedDealer])

  const getLoanSanctionDetails = async () => {
    try {
      setLoadingDealer(true)
      const vehicleInfo = JSON.parse(localStorage.getItem('selectedVehicle'))
      if (typeof vehicleInfo?.id === 'number') {
        setVehicleDetails(vehicleInfo)
        const dealerDetails = await testRideService.getTestRideDealers(vehicleInfo.id)
        setDealerDetails(dealerDetails.data.data)
        setLoadingDealer(false)
      } else {
        const vehicleDetails = await vehicleService.getVehiclePricing()
        const id = vehicleDetails.data.data.cust_vehicle_details.id
        setVehicleDetails({
          ...vehicleDetails.data.data.vehicle_pricing_details,
          id: id,
        })
        const dealerDetails = await testRideService.getTestRideDealers(id)
        setDealerDetails(dealerDetails.data.data)
        setLoadingDealer(false)
      }
      const response = await testRideService.getTestRideTimings()
      const data = response.data.data
      data.test_ride_slot_settings.available_slots.forEach((slot) => {
        const [startTimeHour, startTimeMinutes] = slot.start_time.split(':')
        const [endTimeHour, endTimeMinutes] = slot.end_time.split(':')
        const slotTime = `${
          parseInt(startTimeHour) > 12 ? parseInt(startTimeHour) - 12 : startTimeHour
        }:${startTimeMinutes} ${parseInt(startTimeHour) > 12 ? 'PM' : 'AM'} - ${
          parseInt(endTimeHour) > 12 ? parseInt(endTimeHour) - 12 : endTimeHour
        }:${endTimeMinutes} ${parseInt(endTimeHour) > 12 ? 'PM' : 'AM'}`
        ;(slot.slot_time = slotTime), (slot.start_time_hour = parseInt(startTimeHour))
        slot.start_time_minutes = parseInt(startTimeMinutes)
        slot.end_time_hour = parseInt(endTimeHour)
        slot.end_time_minutes = parseInt(endTimeMinutes)
      })
      setValue('mobileNumber', data.mobile || '')
      setValue('name', data.display_name || '')
      setTestRideTimings(data)
    } catch (error) {
      console.log(error)
    }
  }

  function handleEditVehicle() {
    navigate(routes.TEST_RIDE_VEHICLE_SELECTION)
  }

  async function scheduleTestRide() {
    ReactGA.event({
      category: 'Schedule Ride Page',
      action: 'Schedule Ride',
      label: 'Schedule Ride',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    setIsLoading(true)
    try {
      const startDate = new Date(testRideDate.date)
      startDate.setHours(testRideDate.selectedSlot.start_time_hour)
      startDate.setMinutes(testRideDate.selectedSlot.start_time_minutes)
      const endDate = new Date(testRideDate.date)
      endDate.setHours(testRideDate.selectedSlot.end_time_hour)
      endDate.setMinutes(testRideDate.selectedSlot.end_time_minutes)
      const startDateTime = startDate.toISOString().split('T')
      const endDateTime = endDate.toISOString().split('T')
      // console.log(startDate, endDate, testRideDate.selectedSlot.start_time_hour)
      const details = {
        custVehicleId: vehicleDetails.id,
        dateFormat: 'yyyy-MM-dd HH:mm:ss',
        dealerId: selectedDealer,
        preferredSlotDate: new Date(testRideDate.date).toISOString().split('T')[0],
        preferredSlotStartTime: `${startDateTime[0]} ${startDateTime[1].substring(0, 8)}`,
        preferredSlotEndTime: `${endDateTime[0]} ${endDateTime[1].substring(0, 8)}`,
        scheduleType: 'AT_HOME',
        personalDetails: {
          mobile: values.mobileNumber,
          name: values.name,
          purchaseIn: values.purchaseIn,
          purchaseMode: values.purchaseMode,
        },
      }
      const res = await testRideService.saveTestRide(details)
      const scheduleId = res.data.data.testRideScheduleId
      setIsLoading(false)
      if (res.data.data.otpResponseIdentifier) {
        localStorage.setItem('mobileNumber', values.mobileNumber)
        localStorage.setItem('responseIdentifier', res.data.data.otpResponseIdentifier)
        setTestRideScheduleId(scheduleId)
        setIsOtpModalOpen(true)
      } else {
        navigate(`${prependUrl}/test-ride-confirmation/${scheduleId}`)
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  function handleOtpSubmit() {
    navigate(`${prependUrl}/test-ride-confirmation/${testRideScheduleId}`)
  }

  function handlePhoneNumberChange() {
    setIsOtpModalOpen(false)
    setTimeout(() => {
      mobileRef.current.focus();
    }, 100);
  }

  return {
    control,
    addressData,
    loadingDealer,
    dealerDetails,
    vehicleDetails,
    selectedDealerId,
    isEditable,
    open,
    selectedDealer,
    isLoading,
    testRideDate,
    showCalendarType,
    testRideTimings,
    errors,
    values,
    guestToken,
    isOtpModalOpen,
    mobileRef,
    handleOtpSubmit,
    setIsOtpModalOpen,
    setShowCalendarType,
    handleEditVehicle,
    setSelectedDealer,
    setIsEditable,
    handleSubmit,
    scheduleTestRide,
    setTestRideDate,
    setOpen,
    handlePhoneNumberChange,
  }
}

export default useScheduleRide
