import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import BikeShadow from 'assets/images/BikeShadow.svg'
import BlueBike from 'assets/images/BlueBike.svg'
import useMobileDevice from 'hooks/useMobileDevice'

interface TestRideLeftSectionInterface {
  children: JSX.Element,
  pref?: boolean,
  image?: string
}

export default function TestRideLeftSection({ children, pref=false, image }: TestRideLeftSectionInterface) {
  const mobile = useMobileDevice()

  return (
    <Grid container className='hide-scrollbar'>
      <Grid
        bgcolor='#00221F'
        item
        xs={mobile ? 12 : 5}
        position='relative'
        display={'flex'}
        flexDirection='column'
        alignItems={mobile ? 'center' : 'flex-start'}
      >
        <Box top={0} position={mobile ? 'initial' : 'fixed'} width={mobile ? '100%' : '41.666667%'}>
          <Box width='100%' position='relative'>
            <Box height={mobile ? '60vh' : '80vh'} minHeight={mobile ? '590px' : '0'} width='100%' display='flex'>
              <img
                src={BikeShadow}
                style={{ margin: '0 auto', objectFit: 'fill', width: '100%', maxHeight: '100%' }}
              />
            </Box>
            <Box
              top={mobile ? '420px' : '150px'}
              left='50%'
              width='100%'
              display='flex'
              justifyContent='center'
              sx={{ transform: mobile ? 'translate(-50%, -50%)' : 'translate(-50%)' }}
              position='absolute'
            >
              <img src={image ? image : BlueBike} style={{ width: mobile ? 'auto' : '60%', marginTop: pref ? '50px' : '0', maxWidth: mobile ? '300px' : 'auto' }} />
            </Box>
          </Box>
        </Box>
      </Grid>
      {children}
    </Grid>
  )
}
