import React, { useEffect, useState } from 'react'
import AuthService from 'service/authService'
import { useForm } from 'react-hook-form'
import ReactGA from 'react-ga4'
import useMobileDevice from 'hooks/useMobileDevice'

const useOtpModal = ({onSubmit}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' })

  const [isLoading, setIsLoading] = useState(false)
  const [isResend, setIsResend] = useState('resend?')
  const initialCount = parseInt(localStorage.getItem('count')) || 0
  const [count, setCount] = useState(initialCount)
  const [otp, setOtp] = React.useState('')

  const phoneNumber = localStorage.getItem('mobileNumber')

  const mobile = useMobileDevice()

  const handleChange = (newValue) => {
    setOtp(newValue)
  }

  const numberToShow = () => {
    const replacement = 'xxx'
    const result = phoneNumber.substring(0, phoneNumber.length - 4) + replacement
    const last = phoneNumber.charAt(phoneNumber.length - 1)
    const number = result.concat(last)
    return number
  }

  const onLoginSubmit = async () => {
    ReactGA.event({
      category: 'Verify OTP Page',
      action: 'User Verify',
      label: 'OTP Submit',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    setIsLoading(true)
    try {
      const apiData = {
        mobile: phoneNumber,
        otp: otp,
        requestIdentifier: localStorage.getItem('responseIdentifier'),
      }
      const response = await AuthService.verifyGuestOtp(apiData)
      localStorage.setItem('token', response.headers.authorization)
      setIsLoading(false)
      onSubmit()
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  const onResendOtp = async () => {
    ReactGA.event({
      category: 'Verify OTP Page',
      action: 'Resend OTP',
      label: 'OTP Resent',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })

    if (isResend == 'resend?') {
      setIsResend('')
      setOtp('')
      try {
        const apiData = {
          mobile: phoneNumber,
        }
        const response = await AuthService.resendOtp(apiData)
        if (response.data.code == 200) {
          setIsResend('OTP Sent')
          setCount(30)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    let interval

    if (count > 0) {
      interval = setInterval(() => {
        setCount((prevCount) => {
          const newCount = prevCount - 1
          // Store the updated count in localStorage
          localStorage.setItem('count', JSON.stringify(newCount - 1))
          return newCount
        })
      }, 1000) // Decrease the count every second
      setIsResend(`OTP Sent! Try again after ${count} ${count === 1 ? 'second.' : 'seconds.'}`)
    } else if (count === 0) {
      setIsResend('resend?')
    }

    return () => {
      clearInterval(interval)
    }
  }, [count])

  return {
    control,
    handleSubmit,
    formState: { errors },
    otp,
    phoneNumber,
    mobile,
    handleChange,
    numberToShow,
    onLoginSubmit,
    onResendOtp,
    isLoading,
    isResend,
  }
}

export default useOtpModal
