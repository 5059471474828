import React from 'react'
import { Box, Typography, Card, Skeleton } from '@mui/material'
import EditIcon from 'assets/images/edit.svg'
import useCard from './useCard'
import { getPriceFormat } from 'utils/function'

const VehicleCard = () => {
  const { vehicleData, onEditClick, isLoading }: any = useCard()

  return (
    <Box mt={14} display='flex' justifyContent='center'>
      <Card sx={{ borderRadius: '10px'}}>
        {isLoading ? (
          <Skeleton variant='rounded' sx={{ my: 2 }} width={'100%'} height={60} />
        ) : (
          <Box py={3} px={4}>
            <Box display='flex' justifyContent='space-between' alignItems='start' gap={3}>
              <Box>
                <Typography
                  fontSize='14px'
                  fontWeight='600'
                  textAlign='left'
                  className='gradientText'
                  maxWidth='17.5rem'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                >
                  {vehicleData?.vehicle_pricing_details?.displayName}
                </Typography>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  mt={1}
                  gap={4}
                >
                  <Typography
                    component={Typography}
                    color='text'
                    variant='caption'
                    textAlign='center'
                    whiteSpace='nowrap'
                  >
                    {`Avg on road price in ${vehicleData?.vehicle_pricing_details?.cityName}`}
                  </Typography>
                  <Typography
                    component={Typography}
                    color='text'
                    variant='caption'
                    textAlign='center'
                    whiteSpace='nowrap'
                  >
                    {`₹ ${getPriceFormat(vehicleData?.vehicle_pricing_details?.onRoadPrice)}`}
                  </Typography>
                </Box>
              </Box>
              <img
                src={EditIcon}
                onClick={onEditClick}
                alt='edit'
                style={{ marginLeft: '5px', fontWeight: 'bold', cursor: 'pointer' }}
              />
            </Box>
          </Box>
        )}
      </Card>
    </Box>
  )
}

export default VehicleCard
