import useMobileDevice from 'hooks/useMobileDevice'
import { useNavigate } from 'react-router'
import { routes } from 'constants/routes'
import { getPriceFormat } from 'utils/function'
import { useEffect, useState } from 'react'
import vehicleService from 'service/vechicleService'
import { toast } from 'react-hot-toast'

interface OffersDataInterface {
  description: string
  productId: number
  productKey: string
  productName: string
  upsellOrder: number
  upsellText: string
}

const useVechicleSelection = (selectedVechicle) => {  
  const [offersData, setOffersData] = useState<OffersDataInterface[]>([]);
  const [isLoading, setIsLoading] = useState(false)
  const [isOffersModelOpen, setIsOffersModelOpen] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const mobile = useMobileDevice()
  const navigate = useNavigate()
  const addressData = JSON.parse(localStorage.getItem('addressData'))
  
  const onContinue = () => {
    navigate(routes.PREFERENCE)
  }

  useEffect(() => {
    setIsLoading(true)
    if(selectedVechicle?.model) {
      vehicleService.getVehicleOffers()
      .then(res => {
        setOffersData(res.data.data)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
    }
  },[selectedVechicle])

  return {
    mobile,
    offersData,
    addressData,
    isOffersModelOpen,
    currentSlide,
    setIsOffersModelOpen,
    setCurrentSlide,
    getPriceFormat,
    onContinue,
  }
}

export default useVechicleSelection
