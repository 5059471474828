import request from 'utils/request'
import {
  SEND_OTP,
  VERIFY_OTP,
  SIGN_UP,
  RESEND_OTP,
  SEND_PINCODE,
  SAVE_PROFILE,
  GET_PROFILE,
  REDIRECT_PAGE,
  GUEST_TOKEN,
  GUEST_OTP,
} from 'constants/apiConstants'
import {
  VerifyOtpType,
  SignUpType,
  MobileType,
  PincodeType,
  ProfileType,
} from 'customTypes/authTypes'

const sendOtp = (apiData: MobileType) => {
  return request.post(SEND_OTP, apiData)
}

const verifyOtp = async (apiData: VerifyOtpType) => {
  return request.post(VERIFY_OTP, apiData)
}

const signUp = async (apiData: SignUpType) => {
  return await request.post(SIGN_UP, apiData)
}

const resendOtp = async (apiData: MobileType) => {
  return await request.post(RESEND_OTP, apiData)
}

const sendPincode = async (apiData: PincodeType) => {
  return await request.get(`${SEND_PINCODE}${apiData}`)
}

const saveProfile = async (apiData: ProfileType) => {
  return await request.post(SAVE_PROFILE, apiData)
}

const getProfile = async () => {
  return await request.get(GET_PROFILE)
}

const getRedirectPage = async () => {
  return await request.get(REDIRECT_PAGE)
}

const getGuestToken = () => {
  return request.get(GUEST_TOKEN)
}

const verifyGuestOtp = (apiData: VerifyOtpType) => {
  return request.post(GUEST_OTP, apiData)
}

const AuthService = {
  sendOtp,
  resendOtp,
  verifyOtp,
  signUp,
  sendPincode,
  saveProfile,
  getProfile,
  getRedirectPage,
  getGuestToken,
  verifyGuestOtp,
}

export default AuthService
