import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ExpiredGif from 'assets/images/expired.gif'
import { Button } from '@mui/material'
import LoanCard from './loanCard'

interface Props {
  mobile: boolean
  loanDetails: any
  setIsVoucherModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ExpiredSection({ mobile, loanDetails, setIsVoucherModalOpen  }: Props) {
  return (
    <Box mx={6} width='320px' display='flex' flexDirection='column' pt={mobile ? 3 : 10}>
      <img
        src={ExpiredGif}
        alt='details'
        style={{ margin: '0 auto', width: '4rem', height: '4rem', marginTop: '1.4rem' }}
      />
      <Typography variant='h2' textAlign='center' sx={{ my: 1.5 }}>
        Your Loan Voucher Has Expired
      </Typography>
      <Typography variant='body' textAlign='center' sx={{ mb:8 }} color='text.secondary'>
        You can regenerate your loan sanction voucher with just a few easy steps.
      </Typography>
      <LoanCard loanDetails={loanDetails} />
      <Button sx={{ mt: 6, mb: 1 }} onClick={() => {setIsVoucherModalOpen(true)}}>Regenerate Voucher</Button>
    </Box>
  )
}
