import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer'
import useMobileDevice from 'hooks/useMobileDevice'
import {
  Typography,
  Card,
  Box,
  Slider,
  LinearProgress,
  Button,
  CircularProgress,
} from '@mui/material'
import closeIcon from 'assets/images/close icon.svg'
import EditGif from 'assets/images/edit.gif'
import VehicleSvg from 'assets/images/vehicle.svg'
import LoanSvg from 'assets/images/loan.svg'
import PaymentSvg from 'assets/images/payment.svg'
import InfoSvg from 'assets/images/Info.svg'
import { getPriceFormat } from 'utils/function'
import useEditLoan from './useEditLoan'
import vehicleService from 'service/vechicleService'

interface PropsInterface {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose?: () => void
  loadData: () => void
}

const EditLoanDrawer = ({ open, setOpen, handleClose, loadData }: PropsInterface) => {
  const mobile = useMobileDevice()
  const { loanData, onUpdate, isLoading, payment, setPayment, loanAmount }: any = useEditLoan({
    setOpen,
    loadData,
  })

  const [vehicleName, setVehicleName] = useState('')

  const paymentMarks = [
    {
      value: parseInt(loanData?.minDownPayment),
      label: `₹ ${loanData?.minDownPayment?.toFixed()}`,
    },

    {
      value: parseInt(loanData?.maxDownPayment),
      label: `₹ ${loanData?.maxDownPayment?.toFixed()}`,
    },
  ]

  useEffect(() => {
    vehicleService
      .getVehiclePricing()
      .then((res) => {
        setVehicleName(res?.data?.data?.vehicle_pricing_details?.displayName)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  return (
    <Drawer
      open={open}
      anchor={mobile ? 'bottom' : 'right'}
      onClose={handleClose}
      sx={{
        mt: mobile ? 16.25 : 0,
        zIndex: 1400,
      }}
      PaperProps={{
        sx: {
          background: 'linear-gradient(155deg, #082C29 -11.66%, #000 52.77%)',
          backdropFilter: 'blur(30px)',
          borderRadius: mobile ? '1.25rem 1.25rem 0 0' : '1.25rem 0 0 1.25rem',
          width: mobile ? '100%' : '450px',
        },
      }}
    >
      <Box display='flex' justifyContent='end' alignItems='center'>
        <img
          src={closeIcon}
          alt='close icon'
          onClick={handleClose}
          style={{
            width: '2rem',
            height: '2rem',
            marginRight: mobile ? '1rem' : '1rem',
            marginTop: '1rem',
            cursor: 'pointer',
          }}
        />
      </Box>
      <Box display='flex' justifyContent='center'>
        <Card
          sx={{
            mt: 2,
            width: '90%',
            marginRight: mobile ? '1rem' : '1rem',
            marginLeft: '1rem',
            display: 'flex',
            borderRadius: '20px',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <img
            src={EditGif}
            alt='scooter'
            style={{ margin: 'auto', width: '4rem', height: '4rem', marginTop: '1.4rem' }}
          />
          <Typography textAlign='center' mt={2} color='text.primary' variant='h2'>
            Edit Your Eligible Loan Amount
          </Typography>
          <Box sx={{ width: 300, mt: 5, mx: 'auto' }}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography gutterBottom variant='bodyStrong' color='text.secondary'>
                Down payment
              </Typography>
              <Typography gutterBottom variant='bodyStrong' color='#00A99D'>
                {`₹ ${payment}`}
              </Typography>
            </Box>
            <Slider
              sx={{ mx: 'auto', maxWidth: 300 }}
              //aria-label='Always visible'
              //getAriaValueText={valuetext}
              step={1000}
              min={parseInt(loanData?.minDownPayment, 10)}
              max={parseInt(loanData?.maxDownPayment, 10)}
              onChange={(e: any) => setPayment(e.target.value)}
              value={payment}
              valueLabelDisplay='off'
            />
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography gutterBottom variant='bodyStrong' color='#00655E'>
                {`₹ ${loanData?.minDownPayment?.toFixed()}`}
              </Typography>
              <Typography gutterBottom variant='bodyStrong' color='#00655E'>
                {`₹ ${loanData?.maxDownPayment?.toFixed()}`}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              borderRadius: '20px 0px 0px 20px',
              background: '#E5F6F5',
              mb: 5,
              display: 'flex',
              pb: 3,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              mt: 2,
            }}
          >
            <Typography py={2} variant='bodyStrong' color='#00655E'>
              Quick Breakup
            </Typography>
            <Box display='flex' width='80%' justifyContent='space-between' sx={{ px: '20px' }}>
              <Box display='flex' alignItems='center'>
                <img src={VehicleSvg} width='15px' height='16px' />
                <Typography color='#00221F' sx={{ pl: '5px' }} variant='caption'>
                  {`Price of your ${vehicleName}`}
                </Typography>
              </Box>
              <Box>
                <Typography color='#00221F' variant='caption' fontWeight='500'>
                  {`₹ ${getPriceFormat(loanData?.onRoadPrice)}`}
                </Typography>
              </Box>
            </Box>

            <LinearProgress
              sx={{
                backgroundColor: '#00221F',
                width: '300px',
                mt: 1,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#00655b',
                },
              }}
              variant='determinate'
              value={(loanData?.onRoadPrice / loanData?.onRoadPrice) * 100}
            />

            <Box
              display='flex'
              width='80%'
              justifyContent='space-between'
              sx={{ px: '20px', pt: '15px' }}
            >
              <Box display='flex' alignItems='center'>
                <img src={LoanSvg} width='15px' height='16px' />
                <Typography color='#00221F' sx={{ pl: '5px' }} variant='caption'>
                  Loan you are looking for
                </Typography>
              </Box>
              <Box>
                <Typography color='#00221F' variant='caption' fontWeight='500'>
                  {`₹ ${getPriceFormat(loanAmount)}`}
                </Typography>
              </Box>
            </Box>

            <LinearProgress
              sx={{
                backgroundColor: 'lightgrey',
                mt: 1,

                width: '300px',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#00A99D',
                },
              }}
              variant='determinate'
              value={(loanAmount / loanData?.onRoadPrice) * 100}
            />

            <Box
              display='flex'
              width='80%'
              justifyContent='space-between'
              sx={{ px: '20px', pt: '15px' }}
            >
              <Box display='flex' alignItems='center'>
                <img src={PaymentSvg} width='15px' height='16px' />
                <Typography color='#00221F' sx={{ pl: '5px' }} variant='caption'>
                  Down Payment
                </Typography>
              </Box>
              <Box>
                <Typography color='#00221F' variant='caption' fontWeight='500'>
                  {`₹ ${getPriceFormat(payment)}`}
                </Typography>
              </Box>
            </Box>

            <LinearProgress
              sx={{
                backgroundColor: 'lightgrey',
                width: '300px',
                mt: 1,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#B0D456',
                },
              }}
              variant='determinate'
              value={(payment / loanData?.onRoadPrice) * 100}
            />
          </Box>
          <Box display='flex' alignItems='start' px={4}>
            <img src={InfoSvg} width='15px' height='16px' />
            <Typography color='#00655E' textAlign='center' variant='caption'>
              Changing the loan amount would impact in changing the monthly EMI & Down payment
            </Typography>
          </Box>
          <Button sx={{ mx: 4, my: 4 }} onClick={onUpdate}>
            {isLoading ? (
              <Box mx='auto' mt={1}>
                <CircularProgress size={20} color='success' />
              </Box>
            ) : (
              'Update loan amount'
            )}
          </Button>
        </Card>
      </Box>
    </Drawer>
  )
}

export default EditLoanDrawer
