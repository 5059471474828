import { routes } from 'constants/routes'
import { ProfileType } from 'customTypes/authTypes'
import useMobileDevice from 'hooks/useMobileDevice'
import { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import AuthService from 'service/authService'
import ReactGA from 'react-ga4'

const useDetails = () => {
  const addressData = JSON.parse(localStorage.getItem('addressData'))
  const canEditCity = JSON.parse(localStorage.getItem('canEditCity'))
  const isAlphaWithSpace = (value) => /^[A-Za-z\s]+$/.test(value)
  const isValidPan = (value) => /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(value)
  const [open, setOpen] = useState(false)
  const [profileData, setProfileData] = useState({ panVerified: false, editableFields: [] })
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isDisabled, setDisabled] = useState(true)
  const mobile = useMobileDevice()
  const navigate = useNavigate()
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({ mode: 'onSubmit' })
  const values = watch()
  const getProfileDetails = () => {
    AuthService.getProfile()
      .then((result) => {
        const { data } = result.data
        if (!data?.canEdit) {
          navigate(routes.CONGRATS)
        }
        setProfileData(data)
        if (data?.name) {
          setValue('name', data?.name)
          setValue('owned', data?.owned?.toString())
          setValue('fatherName', data?.fatherName)
          setValue('dob', new Date(data?.dob).toISOString().slice(0, 10))
          setValue('address', data?.address)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getProfileDetails()
  }, [])

  const onSubmit: SubmitHandler<ProfileType> = async (data) => {
    ReactGA.event({
      category: 'Personal Details Page',
      action: 'Personal details updated',
      label: 'Personal details submitted',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    const formdata = {
      ...data,
      dateFormat: 'yyyy-MM-dd',
      owned: values.owned == 'false' ? false : true,
      pan: values.pan.toUpperCase(),
    }
    setIsLoading(true)
    try {
      await AuthService.saveProfile(formdata).then(() => {
        getProfileDetails()
        navigate(routes.CONGRATS)
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  const checkObjectValues = (obj) => {
    for (const key in obj) {
      if (!obj[key]) {
        setDisabled(true)
        break
      }
      setDisabled(false)
    }
  }
 
  useEffect(() => {
    checkObjectValues(values)
  }, [values])

  return {
    mobile,
    addressData,
    open,
    setOpen,
    control,
    errors,
    isAlphaWithSpace,
    isValidPan,
    onSubmit,
    handleSubmit,
    isLoading,
    profileData,
    values,
    canEditCity,
    isDisabled,
    loading,
  }
}

export default useDetails
