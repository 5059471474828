import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const navigate = useNavigate()
  return (
    <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
      <Box>
        <Typography variant='h5' color='white' textAlign='center'>
          404
        </Typography>
        <Typography variant='h4' color='white' textAlign='center'>
          Page Not Found
        </Typography>
        <Typography variant='body1' py={2} color='white' textAlign='center'>
          {`Sorry, we couldn't find the page you're looking for`}
        </Typography>
        <Box mt={2} display='flex' justifyContent='center'>
          <Button variant='contained' onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PageNotFound
