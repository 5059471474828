import React from 'react'
import BackgroundImage from 'assets/images/background.png'
import MobileImage from 'assets/images/mobile.svg'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Scooter from 'assets/images/scooter.gif'
import ScooterImage from 'assets/images/scooter.svg'
import EditIcon from 'assets/images/edit.svg'
import useFinance from './useFinance'
import CustomSelect from 'components/molecules/CustomSelect'
import VechicleSelection from 'containers/vechicleSelection'
import CustomModal from 'components/molecules/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import Stepper from 'components/molecules/Stepper'
import Heading from 'components/atoms/Heading'
import LeftSection from 'components/molecules/LeftSection'
import Footer from 'components/organisms/footer'
import PincodeModal from 'components/molecules/PincodeModal'
import usePincode from 'containers/pincodePage/usePincode'

export default function Finance() {
  const name = null
  const flag = false
  const {
    isLoading,
    mobile,
    addressData,
    open,
    brandOptions,
    modelOptions,
    showVechicleSelection,
    showBrands,
    showModels,
    selectedVechicle,
    selectedBrand,
    setOpen,
    setShowBrands,
    setShowModels,
    setShowVechicleSelection,
    handleBrandChange,
    handleModelChange,
    onVehicleSubmit,
    handleVariantSelected,
    handleSelectVariant,
    selectedModel,
    isPageLoading,
    canEditCity,
  } = useFinance({ name, flag })

  const {
    onPincodeSubmit,
    isLoading: isPincodeLoading
  } = usePincode(setOpen)

  return (
    <>
      <Heading />
      {mobile && (
        <Box>
          <img
            src={BackgroundImage}
            width='100%'
            style={{ objectFit: 'contain', position: 'fixed', top: '0', zIndex: '-1' }}
          />
          <Box mt={12}>
            <Stepper currentStepper={1} totalNoOfSteppers={3} />
            <Box display='flex' justifyContent='center' mt={6}>
              <img
                src={MobileImage}
                alt='mobile'
                style={{ marginBottom: '-2rem', zIndex: '-1', width: '12.5rem' }}
              />
              <img src={ScooterImage} alt='scooter' style={{ margin: '0 0 -8rem -5.5rem' }} />
            </Box>
          </Box>
          {isPageLoading ? (
            <Box
              pt={20}
              sx={{
                background: 'white',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CircularProgress color='success' />
            </Box>
          ) : (
            <Box>
              <Card
                variant='outlined'
                sx={{ borderRadius: 5, px: 6.5, display: 'flex', flexDirection: 'column' }}
              >
                <img
                  src={Scooter}
                  alt='scooter'
                  style={{ margin: 'auto', width: '4rem', height: '4rem', marginTop: '1.4rem' }}
                />
                <Typography variant='h2' textAlign='center' sx={{ my: 1.5 }}>
                  Which scooter are you buying?
                </Typography>
                <Box display='flex' mb='1.75rem' justifyContent='center'>
                  <Typography textAlign='center' color='text.secondary'>
                    Showing brands at
                  </Typography>
                  <Box display='flex' alignItems='center' ml='5px' style={{ cursor: 'pointer' }}>
                    <Typography textAlign='center' color='text.secondary' fontWeight='600'>
                      {addressData?.city}
                    </Typography>
                    {canEditCity && (
                      <img
                        src={EditIcon}
                        alt='edit'
                        onClick={() => setOpen(true)}
                        style={{ marginLeft: '5px', fontWeight: 'bold' }}
                      />
                    )}
                  </Box>
                </Box>
                <CustomSelect
                  label='Brand'
                  drawerTitle='Select a Brand'
                  buttonName='Select a Variant'
                  options={brandOptions}
                  open={showBrands}
                  setOpen={setShowBrands}
                  handleClose={() => setShowBrands(false)}
                  handleClick={handleSelectVariant}
                  onChange={(option) => handleBrandChange(option)}
                />
                <CustomSelect
                  label='Variant'
                  drawerTitle='Select a Variant'
                  buttonName='Done'
                  open={showModels}
                  disabled={!selectedBrand}
                  value={selectedModel}
                  setOpen={setShowModels}
                  handleClose={() => setShowModels(false)}
                  options={modelOptions}
                  handleClick={handleVariantSelected}
                  onChange={(option) => handleModelChange(option)}
                />
                <Button
                  sx={{ mt: 2, mb: 5 }}
                  onClick={onVehicleSubmit}
                  disabled={selectedModel?.vehicleId === '' ? true : false}
                >
                  {isLoading ? (
                    <Box mx='auto' mt={1}>
                      <CircularProgress size={20} color='success' />
                    </Box>
                  ) : (
                    'Continue'
                  )}
                </Button>
              </Card>
              <Footer mobile={mobile} />
            </Box>
          )}
          {/* <Box position='relative'>
            <Box
              position='absolute'
              bgcolor='#EFEFEF'
              width='40px'
              height='40px'
              display='flex'
              alignItems='center'
              justifyContent='center'
              borderRadius='20px'
              top='50%'
              left='50%'
              sx={{ translate: '-50% -50%' }}
            >
              <Typography variant='caption'>OR</Typography>
            </Box>
          </Box>
          <Box my={5}>
            <Typography
              textTransform='uppercase'
              color='#00B6A7'
              fontWeight='600'
              textAlign='center'
            >
              Help me decide a right one
            </Typography>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <Typography
                component={Typography}
                variant='caption'
                color='#D4D4D4E5'
                textAlign='center'
              >
                By continuing you agree to our{' '}
              </Typography>
              <Typography
                component={Typography}
                variant='caption'
                color='#51B3A7'
                textAlign='center'
                sx={{ textDecoration: 'underline' }}
              >
                {' '}
                privacy policy
              </Typography>
            </Box>
          </Box> */}
        </Box>
      )}
      {!mobile && (
        <LeftSection>
          <Grid
            item
            xs={7}
            width='100%'
            minHeight='100vh'
            bgcolor='#FFF'
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
          >
            <Stepper currentStepper={1} totalNoOfSteppers={3} />
            {isPageLoading ? (
              <Box
                width='100%'
                minHeight='100vh'
                display='flex'
                flexDirection='column'
                paddingTop='100px'
                justifyContent='center'
                alignItems='center'
              >
                <CircularProgress color='success' />
              </Box>
            ) : (
              <Box display='flex' flexDirection='column' alignItems='center'>
                <Box width='320px' display='flex' flexDirection='column' paddingTop='100px'>
                  <img
                    src={Scooter}
                    alt='scooter'
                    style={{ margin: '0 auto', width: '4rem', height: '4rem', marginTop: '1.4rem' }}
                  />
                  <Typography variant='h2' textAlign='center' sx={{ my: 1.5 }}>
                    Which scooter are you buying?
                  </Typography>
                  <Box display='flex' mb='1.75rem' justifyContent='center'>
                    <Typography textAlign='center' color='text.secondary'>
                      Showing brands at
                    </Typography>
                    <Box display='flex' alignItems='center' ml='5px' style={{ cursor: 'pointer' }}>
                      <Typography textAlign='center' color='text.secondary' fontWeight='600'>
                        {addressData?.city}
                      </Typography>
                      {canEditCity && (
                        <img
                          src={EditIcon}
                          alt='edit'
                          onClick={() => setOpen(true)}
                          style={{ marginLeft: '5px', fontWeight: 'bold' }}
                        />
                      )}
                    </Box>
                  </Box>
                  <CustomSelect
                    label='Brand'
                    drawerTitle='Select a Brand'
                    buttonName='Select a Variant'
                    options={brandOptions}
                    open={showBrands}
                    setOpen={setShowBrands}
                    handleClose={() => setShowBrands(false)}
                    handleClick={handleSelectVariant}
                    onChange={(option) => handleBrandChange(option)}
                  />
                  <CustomSelect
                    label='Variant'
                    drawerTitle='Select a Variant'
                    buttonName='Done'
                    open={showModels}
                    value={selectedModel}
                    disabled={!selectedBrand}
                    setOpen={setShowModels}
                    handleClose={() => setShowModels(false)}
                    options={modelOptions}
                    handleClick={handleVariantSelected}
                    onChange={(option) => handleModelChange(option)}
                  />
                  <Button
                    sx={{ mt: 2, mb: 5 }}
                    onClick={onVehicleSubmit}
                    disabled={selectedModel?.vehicleId === '' ? true : false}
                  >
                    {isLoading ? (
                      <Box mx='auto' mt={1}>
                        <CircularProgress size={20} color='success' />
                      </Box>
                    ) : (
                      'Continue'
                    )}
                  </Button>
                </Box>
              </Box>
            )}
            <Footer mobile={mobile} />
          </Grid>
        </LeftSection>
      )}
      <VechicleSelection
        open={showVechicleSelection}
        setOpen={setShowVechicleSelection}
        selectedVechicle={selectedVechicle}
      />
      <CustomModal padding={4} open={open} setOpen={setOpen}>
        <PincodeModal isLoading={isPincodeLoading} onSubmit={onPincodeSubmit} />
      </CustomModal>
    </>
  )
}
