import { apiRoutes } from "constants/apiRedirectConstant"
import { routes } from "constants/routes"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import testRideService from "service/testRideService"
import vehicleService from "service/vechicleService"
import ReactGA from 'react-ga4'

interface UpsellProduct {
  description: string,
  upsellText: string,
  productName: string,
}

interface TestRideDetailsInterface {
  dealerDto: {
    dealerName: string,
    address: string,
  }
  vehiclePricingDetails: {
    displayName: string
    brand: string
    image: string
  }
  upsellProducts: UpsellProduct[]
  slotStartDateTime: Date
  slotEndDateTime: Date,
  testRideConfirmationBanner: 'UPSELL_PRODUCT' | 'REFER_FRIEND' | 'CHECK_ELIGIBILITY'
}



const useTestRideConfirmation = () => {
  const [testRideDetails, setTestRideDetails] = useState<TestRideDetailsInterface>({
    dealerDto: {
      dealerName: '',
      address: '',
    },
    vehiclePricingDetails: {
      displayName: '',
      brand: '',
      image: ''
    },
    upsellProducts: [],
    slotStartDateTime: new Date(),
    slotEndDateTime: new Date(),
    testRideConfirmationBanner: 'UPSELL_PRODUCT'
  })
  const [currentSlide, setCurrentSlide] = useState(0)

  const {id} = useParams()
  const navigate = useNavigate()

  // var gapi = window.gapi

  useEffect(() => {
    testRideService.getTestRideDetails(id)
    .then(res => {
      setTestRideDetails(res.data.data)
    })
    .catch(err => {
      console.log(err)
    })
  },[])

  function handleCheckEligibility() {
    ReactGA.event({
      category: 'Test ride confirmation Page',
      action: 'Check Eligibility',
      label: 'Check Eligibiity',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    vehicleService.getRecommendedVehicles()
    .then(res => {
      const screenName = res.data.data.loan_application.screenName
      if(screenName) {
        navigate(apiRoutes[screenName])
      } else {
        navigate(routes.LOGIN)
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  function handleAddToCalendar() {
    console.log('Hello')
  }


  return {
    testRideDetails,
    currentSlide,
    setCurrentSlide,
    handleCheckEligibility,
    handleAddToCalendar
  }

}

export default useTestRideConfirmation