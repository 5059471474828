import useMobileDevice from 'hooks/useMobileDevice'

const useNotEligible = () => {
  const mobile = useMobileDevice()
  return {
    mobile,
  }
}

export default useNotEligible
