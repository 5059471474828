import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import closeIcon from 'assets/images/close icon.svg'
import GiftIcon from 'assets/images/gift.gif'

interface VoucherRegenerateModelInterface {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  mobile: boolean
  handleCreateNewApplication: () => void
  handleRegenrateOffer: () => void
}

export default function VoucherRegenerateModal({
  open,
  setOpen,
  mobile,
  handleCreateNewApplication,
  handleRegenrateOffer,
}: VoucherRegenerateModelInterface) {
  return (
    <>
      {mobile ? (
        <Drawer
          anchor='bottom'
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            mt: 16.25,
            zIndex: 1400,
          }}
          PaperProps={{
            sx: {
              background: 'linear-gradient(155deg, #082C29 -11.66%, #000 52.77%)',
              backdropFilter: 'blur(30px)',
              borderRadius: '1.25rem 1.25rem 0 0',
              width: '100%',
            },
          }}
        >
          <Box display='flex' justifyContent='end' alignItems='center'>
            <img
              src={closeIcon}
              alt='close icon'
              onClick={() => setOpen(false)}
              style={{
                width: '2rem',
                height: '2rem',
                marginRight: '1rem',
                marginTop: '1.5rem',
                cursor: 'pointer',
              }}
            />
          </Box>
          <Card
            sx={{
              borderRadius: '1.25rem',
              background: '#FFF',
              mt: 2,
              mb: 5,
              mx: 2,
              px: 4,
            }}
          >
            <Box display='flex'>
              <img
                src={GiftIcon}
                alt='gift'
                style={{ width: '60px', height: '60px', margin: '4rem auto 1.5rem auto' }}
              />
            </Box>
            <Typography variant='h2' textAlign='center'>
              How would you like to regenerate the voucher?
            </Typography>
            <Box display='flex' alignItems='center' mt={5}>
              <Typography
                variant='caption'
                textTransform='uppercase'
                fontWeight='600'
                color='#00A99D'
                sx={{ cursor: 'pointer' }}
                onClick={handleRegenrateOffer}
              >
                Regenerate with the same information
              </Typography>
            </Box>
            <Button fullWidth sx={{ mt: 2.5, mb: 15.25 }} onClick={handleCreateNewApplication}>
              Create New Application
            </Button>
          </Card>
        </Drawer>
      ) : (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            sx: {
              px: 8,
              m: 0,
              color: '#fff',
              borderRadius: 5,
              maxHeight: 'none',
              maxWidth: '320px',
              overflow: 'visible',
            },
          }}
        >
          <Box>
            <Box position='relative'>
              <Box position='absolute' top={'-3.5rem'} right={'-3.25rem'}>
                <img
                  src={closeIcon}
                  alt='close'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpen(false)}
                />
              </Box>
            </Box>
            <Box display='flex' mt={5.5}>
              <img
                src={GiftIcon}
                alt='gift'
                style={{ width: '60px', height: '60px', margin: 'auto' }}
              />
            </Box>
            <Typography variant='h2' mt={2.5} textAlign='center' color='#082F2F'>
              How would you like to regenerate the voucher?
            </Typography>
            <Box display='flex' alignItems='center' mt={5} justifyContent='center'>
              <Typography
                textAlign='center'
                variant='caption'
                textTransform='uppercase'
                fontWeight='600'
                color='#00A99D'
                sx={{ cursor: 'pointer' }}
                onClick={handleRegenrateOffer}
              >
                Regenerate with the same information
              </Typography>
            </Box>
            <Button fullWidth sx={{ mt: 2.5, mb: 7.5 }} onClick={handleCreateNewApplication}>
              Create New Application
            </Button>
          </Box>
        </Dialog>
      )}
    </>
  )
}
