export const ECOFIN = [
  {
    summary: 'What is eco.fin? What are the benefits of eco.fin?',
    details: 'eco.fin is a classic financing product, which offers up to 100% on road financing.',
  },
  {
    summary: 'What is the minimum loan tenure for eco.fin?',
    details: 'The minimum loan tenure is a period of 12 months.',
  },
  {
    summary: 'What documents are required to apply for a eco.fin loan?',
    details: 'PAN Card and Aadhar Card (This can be shared via digilocker).'
  },
  {
    summary: 'What is the minimum down payment required for availing eco.fin?',
    details: 'There is no minimum down payment. You can avail up to 100% on road financing.'
  },
  {
    summary: 'What are the processing charges for eco.fin?',
    details: 'Processing Fees – 3% of the loan amount + GST Documentation Charges – 999/-'
  },
  {
    summary: 'What will the EMI schedule be?',
    details: 'Your EMI will be deducted on a monthly basis depending on your loan repayment cycle/disbursement date.'
  },
  {
    summary: 'How can I change my EMI schedule?',
    details: 'Your EMI schedule will be 47 months to ensure all EMI payments are completed before the buyback evaluation process starts in the 47th month.'
  },
  {
    summary: 'What is the loan repayment process?',
    details: 'Your EMI will be deducted on a monthly basis depending on your loan repayment cycle/disbursement date.'
  },
  {
    summary: 'What are the foreclosure charges?',
    details: 'Foreclosure charges are 2% of the remaining principal outstanding.'
  },
  {
    summary: 'What are the processing fees and charges levied for eco.fin?',
    details: 'Processing Fees – 3% of the loan amount + GST Documentation Charges – 999/-'
  },
  {
    summary: 'What is the processing time for getting a loan?',
    details: 'After e-signing/signing of the loan, the document loan processing will be completed within 24 hours.'
  },
  {
    summary: 'What documents are required to apply for a eco.fin loan?',
    details: 'PAN Card and Aadhar Card (This can be shared via digilocker).'
  },
  {
    summary: 'What is the minimum down payment required for availing eco.fin?',
    details: 'There is no minimum down payment. You can avail up to 100% on road financing.'
  },
  {
    summary: 'What are the processing charges for eco.fin?',
    details: 'Processing Fees – 3% of the loan amount + GST Documentation Charges – 999/-'
  },
  {
    summary: 'What will the EMI schedule be?',
    details: 'Your EMI will be deducted on a monthly basis depending on your loan repayment cycle/disbursement date.'
  },
  {
    summary: 'How can I change my EMI schedule?',
    details: 'Your EMI schedule will be 47 months to ensure all EMI payments are completed before the buyback evaluation process starts in the 47th month.'
  },
  {
    summary: 'What is the loan repayment process?',
    details: 'Your EMI will be deducted on a monthly basis depending on your loan repayment cycle/disbursement date.'
  },
  {
    summary: 'What are the foreclosure charges?',
    details: 'Foreclosure charges are 2% of the remaining principal outstanding.'
  },
  {
    summary: 'What are the processing fees and charges levied for eco.fin?',
    details: 'Processing Fees – 3% of the loan amount + GST Documentation Charges – 999/-'
  },
  {
    summary: 'What is the processing time for getting a loan?',
    details: 'After e-signing/signing of the loan, the document loan processing will be completed within 24 hours.'
  },
]

export const SMARTFIN = [
  {
    summary: 'What is smart.fin? What are the benefits of smart.fin?',
    details:
      'Smart.fin is a first of its kind product in the industry, which allows users to upgrade to the latest EV technology after 4 years. Not only does it reduce your total cost of ownership, but also allows you to upgrade to a future electric two-wheeler at INR 50,000* less in cost.',
  },
  {
    summary: 'What is the minimum loan tenure for smart.fin?',
    details: 'The minimum loan tenure is a period of 48 Months.',
  },
  {
    summary: 'What documents are required to apply for a smart.fin loan?',
    details: 'PAN Card and Aadhar Card (This can be shared via digilocker).'
  },
  {
    summary: 'What is the minimum down payment required for availing smart.fin?',
    details: 'INR 15,000. Minimum down payment will vary based on the loan amount.',
  },
  {
    summary: 'At the end of 4 years/48 months, what will the process be for buyback and upgrade?',
    details:
      'Customer Initiated - At the end of 4 years, you need to reach out to an ev.fin executive/helpline number who will guide you with the buyback and upgrade process. Evfin initiated - In the 47th month, an evfin executive will reach out to you with the next steps for valuation, buyback, and upgrade.',
  },
  {
    summary: 'What will be the criteria for valuation of my EV?',
    details:
      'Vehicle evaluation will be done by a 3rd party to ensure fairness of valuation. This will be on the basis of vehicle usage, battery life etc.',
  },
  {
    summary: 'Can I upgrade to the latest 2-wheeler from a different city?',
    details:
      'No, as of today, you can only upgrade to the latest electric two-wheeler from the same city as the EV purchase.',
  },
  {
    summary: 'Where can I avail a smart.fin loan?',
    details:
      'We are currently present in Maharashtra, Tamil Nadu, Gujarat, Karnataka, and Kerala as of today.',
  },
  {
    summary: 'Which OEMs are eligible for smart.fin?',
    details: 'For smart.fin, we currently have a partnership with Ola, Ather, TVS, and Vida.',
  },
  {
    summary: 'What are the processing fees and charges levied for smart.fin?',
    details: 'Processing Fees – 3% of the loan amount + GST Documentation Charges – 999/-',
  },
  {
    summary: 'What is the processing time for getting a loan?',
    details:
      'After e-signing/signing of the loan, the document loan processing will be completed within 24 hours.',
  },
  {
    summary: 'What will the EMI schedule be?',
    details:
      'Your EMI will be deducted on a monthly basis depending on your loan repayment cycle/disbursement date. Your EMI schedule will be 47 months to ensure all EMI payments are completed before the buyback evaluation process starts in the 47th month',
  },
  {
    summary: 'What are the foreclosure charges?',
    details: 'Foreclosure charges are 2% of the remaining principal outstanding.',
  },
]

export const RIDEFIN = [
  {
    summary: 'What is ride.fin? What are the benefits of ride.fin?',
    details:
      'Ride.fin is a first of its kind product in the industry which has the lowest Total Cost of Ownership (TCO). At the end of 4 years, you have the choice to return your vehicle at no extra cost or own the vehicle by paying the remaining amount.',
  },
  {
    summary: 'What is the minimum loan tenure for ride.fin?',
    details: 'The minimum loan tenure is a period of 48 Months.',
  },
  {
    summary: 'What documents are required to apply for a ride.fin loan?',
    details: 'PAN Card and Aadhar Card (This can be shared via digilocker).',
  },
  {
    summary: 'What is the minimum down payment required for availing ride.fin?',
    details: 'INR 35,000. The minimum down payment will vary based on the loan amount.',
  },
  {
    summary: 'At the end of 4 years, what is the process if I want to continue to use/own my EV?',
    details:
      'You need to pay a bullet EMI which depends on your loan amount and vehicle type. If you want to own the vehicle, and not make a one-time ownership payment, you can request for a top up loan and continue to pay similar EMIs for the next 1 year.',
  },
  {
    summary: 'At the end of 4 years, how do I return my vehicle? Are there any charges for the same?',
    details: 'Customer Initiated - At the end of 4 years, you need to reach out to an ev.fin executive/helpline number who will guide you with the buyback and upgrade process. Evfin initiated - In the 47th month, an evfin executive will reach out to you with the next steps for valuation, buyback, and upgrade.'
  },
  {
    summary: 'What will be the criteria for valuation of my EV?',
    details: 'Vehicle evaluation will be done by a 3rd party to ensure fairness of valuation. This will be on the basis of vehicle usage, battery life etc.'
  },
  {
    summary: 'Where can I avail a ride.fin loan?',
    details: 'We are currently present in Maharashtra, Tamil Nadu, Gujarat, Karnataka, and Kerala as of today.'
  },
  {
    summary: 'What are the processing fees and documentation charges levied for ride.fin?',
    details: 'Processing Fees – 3% of the loan amount + GST Documentation Charges – 999/-'
  },
  {
    summary: 'What is the processing time for getting a loan?',
    details: 'After e-signing/signing of the loan, the document loan processing will be completed within 24 hours.'
  },
  {
    summary: 'What will the EMI schedule be?',
    details: 'For the first 47 months, you will have to pay EMIs based on your loan amount. At the end of the 48th month, you will have to pay a bullet EMI if you wish to continue ownership of the vehicle.'
  },
  {
    summary: 'What is the loan repayment process?',
    details: 'Your EMI will be deducted on a monthly basis depending on your loan repayment cycle/disbursement date. Your EMI schedule will be 47 months to ensure all EMI payments are completed before the buyback evaluation process starts in the 47th month.'
  },
  {
    summary: 'What are the foreclosure charges?',
    details: 'Foreclosure charges are 2% of the remaining principal outstanding.'
  }
]

export const ZIPFIN = [
  {
    summary: 'What is zip.fin? What are the benefits of zip.fin?',
    details: 'Zipfin lets the buyer avail upto 100% onroad financing while enjoying interest rates as low as 5.99%',
  },
  {
    summary: 'What is the minimum loan tenure for zip.fin?',
    details: 'The minimum loan tenure is a period of 6 months.',
  },
  {
    summary: 'What documents are required to apply for a zip.fin loan?',
    details: 'PAN Card and Aadhar Card (This can be shared via digilocker).'
  },
  {
    summary: 'What is the minimum down payment required for availing zip.fin?',
    details: 'The minimum down payment would depend on the customers credit score.'
  },
  {
    summary: 'What are the processing fees and charges levied for zip.fin?',
    details: 'Processing Fees – 3% of the loan amount + GST Documentation Charges – 999/-'
  },
  {
    summary: 'What is the processing time for getting a loan?',
    details: 'After e-signing/signing of the loan, the document loan processing will be completed within 24 hours.'
  },
  {
    summary: 'What will the EMI schedule be?',
    details: 'Your EMI will be deducted on a monthly basis depending on your loan repayment cycle/disbursement date.'
  },
  {
    summary: 'How can I change my EMI schedule?',
    details: 'Your EMI will be deducted on a monthly basis depending on your loan repayment cycle/disbursement date. Your EMI schedule will be 47 months to ensure all EMI payments are completed before the buyback evaluation process starts in the 47th month.'
  },
  {
    summary: 'What is the loan repayment process?',
    details: 'Your EMI will be deducted on a monthly basis depending on your loan repayment cycle/disbursement date.'
  },
  {
    summary: 'What are the foreclosure charges?',
    details: 'Foreclosure charges are 2% of the remaining principal outstanding.'
  },
]
