import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import lightLogo from 'assets/images/logo-light.svg'
import wrongGif from 'assets/images/wrong.gif'
import AuthService from 'service/authService'
import { apiRoutes } from 'constants/apiRedirectConstant'
import Heading from 'components/atoms/Heading'

const SomethingWentWrong = () => {
  const navigate = useNavigate()

  const tryAgain = () => {
    AuthService.getRedirectPage().then((res) => {
      if (res?.data?.data?.screenName) {
        navigate(apiRoutes[res?.data?.data?.screenName])
        localStorage.setItem('canEditCity', res?.data?.data?.canEditCity)
        localStorage.setItem(
          'addressData',
          JSON.stringify({
            city: res?.data?.data?.city,
            state: 'Maharashtra',
            country: 'India',
            postalCode: res?.data?.data?.pincode,
          }),
        )
      }
    })
  }

  return (
    <Box
      sx={{ background: 'white', height: '100vh' }}
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Heading />
      <img
        src={lightLogo}
        alt='details'
        style={{
          position: 'absolute',
          top: '1rem',
          width: '80px',
          height: '40px',
        }}
      />
      <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
        <img
          src={wrongGif}
          alt='scooter'
          style={{ margin: 'auto', width: '4rem', height: '4rem', marginBottom: '1rem' }}
        />
        <Typography variant='h2' textAlign='center'>
          Something
        </Typography>
        <Typography variant='h2' textAlign='center'>
          went wrong
        </Typography>
        <Box py={2} display='flex' flexDirection='column'>
          <Typography variant='bodyStrong' color='text.secondary' textAlign='center'>
            We ran into an issue. If you keep seeing this,
          </Typography>
          <Typography variant='bodyStrong' color='text.secondary' textAlign='center'>
            please reach us at
          </Typography>
          <Typography variant='bodyStrong' color='text.secondary' textAlign='center'>
            help@evfin.com
          </Typography>
        </Box>

        <Box mt={2} display='flex' justifyContent='center'>
          <Button variant='contained' sx={{ width: '260px' }} onClick={() => tryAgain()}>
            try again
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default SomethingWentWrong
