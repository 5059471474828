import React from 'react';
import { Card, CardContent } from '@mui/material';

const CustomCard = ({ children }) => {
  return (
    <Card sx={{ width: '90%', mx: 'auto', backgroundColor: '#f2f2f3' }}>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CustomCard;
