import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Carousel from 'components/molecules/Carousel'
import OfferCard from 'components/molecules/Cards/OfferCard'
import useMobileDevice from 'hooks/useMobileDevice'
import vehicleService from 'service/vechicleService'

export default function OfferSection() {
  const mobile = useMobileDevice()
  const [details, setDetails] = useState([])

  const getOffersDetails = () => {
    setDetails(null)
    vehicleService
      .getEligibleDealer()
      .then((result) => {
        setDetails(result?.data?.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getOffersDetails()
  }, [])

  return details?.length > 0 ? (
    <Box width='100%' bgcolor='#f5f5f5' borderRadius='20px 20px 0px 0px'>
      <Box
        flexDirection='row'
        gap={2}
        flexWrap='wrap'
        py={mobile ? 4 : 8}
        px={mobile ? 5 : 8}
        display='flex'
        alignItems='center'
      >
        <Typography
          pb={1}
          width='100%'
          color='#00655E'
          variant='caption'
          fontWeight={500}
          textAlign='center'
        >
          Explore similar scooters
        </Typography>
        <Box display='flex' className='hide-scrollbar' sx={{overflowX: 'auto'}}>
          {details?.map((data, i) => <OfferCard key={i} data={data} />)}
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  )
}
