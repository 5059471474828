import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CustomAccordian from 'components/molecules/CustomAccordian'
import Carousel from 'components/molecules/Carousel'
import ModelCard from 'components/molecules/Cards/ModelCard'

const LandingPage = () => {
  const data = [
    {
      summary: 'Is this thing on?',
      details:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
    },
    {
      summary: 'Is this thing on?',
      details:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
    },
    {
      summary: 'Is this thing on?',
      details:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
    },
    {
      summary: 'Is this thing on?',
      details:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
    },
    {
      summary: 'Is this thing on?',
      details:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
    },
    {
      summary: 'Is this thing on?',
      details:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
    },
  ]

  return (
    <Box mx={4}>
      <Box>
        <Box>
          <Typography variant='h2' py={4} px={8} gutterBottom>
            Welcome to Greave finance!!
          </Typography>
        </Box>

     </Box>
      <Typography variant='h4' mt={5} textAlign='center'>
        Frequently asked questions
      </Typography>
      <Typography my={4} textAlign='center'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit
        amet blandit leo lobortis eget, Suspendisse malesuada lacus ex, sit amet blandit leo
        lobortis eget.
      </Typography>
      <CustomAccordian data={data} />
      <Box mt={5}>
        <ModelCard />
      </Box>
    </Box>
  )
}

export default LandingPage
