import React from 'react'
import Layout from 'components/organisms/Layout'
import { Box, TextField } from '@mui/material'
import CustomButton from 'components/atoms/Button/index'
import { Controller } from 'react-hook-form'
import usePincode from './usePincode'
import CircularProgress from '@mui/material/CircularProgress'
import GlobeGif from '../../../src/assets/images/globe.gif'
import UnavailableModal from './unavailableModal'
import CustomModal from 'components/molecules/Modal'
import SuccessModal from './successModal'
import useFinance from 'containers/finance/useFinance'
import Heading from 'components/atoms/Heading'

const PincodePage = () => {
  const flag = true
  const {
    control,
    handleSubmit,
    formState: { errors },
    isPincodeNumberValid,
    onPincodeSubmit,
    isLoading,
    modalOpen,
    setModalOpen,
    values,
    name,
    successModalOpen,
    setSuccessModalOpen,
  } = usePincode()

  return (
    <Layout
      icon={GlobeGif}
      title={
        <>
          Your <div>Pincode </div>
        </>
      }
      description={
        'Provide your pincode of your city where you are planning to purchase your vehicle'
      }
    >
        <Heading />
      <Controller
        name='pinCode'
        control={control}
        rules={{
          required: 'PinCode is required',
          //validate: (value) => isPincodeNumberValid(value) || 'Invalid pincode / City',
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            value={field?.value ? field?.value : ''}
            label='Enter pincode'
            error={!!errors.pinCode}
            helperText={errors?.pinCode?.message?.toString()}
          />
        )}
      />
      <CustomButton
        onClick={handleSubmit(onPincodeSubmit)}
        disabled={!!errors.pinCode}
      >
        {isLoading ? (
          <Box mx='auto' mt={1}>
            <CircularProgress size={20} color='success' />
          </Box>
        ) : (
          'Next'
        )}
      </CustomButton>
      <CustomModal padding={4} open={modalOpen} setOpen={setModalOpen}>
        <UnavailableModal
          setSuccessModalOpen={setSuccessModalOpen}
          name={name}
          setModalOpen={setModalOpen}
        />
      </CustomModal>
      <CustomModal padding={0} open={successModalOpen} setOpen={setSuccessModalOpen}>
        <SuccessModal />
      </CustomModal>
    </Layout>
  )
}

export default PincodePage
