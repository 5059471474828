import { useEffect, useState } from 'react'
import vehicleService from 'service/vechicleService'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { routes } from 'constants/routes'
import AuthService from 'service/authService'

interface ModelInterface {
  vehicleId: string
  losVehicleId: string
  variant: string
  displayName: string
  displaySubText: string
  brandLogo: string
  image: string
  primaryText: string
  value: string
}

interface ModelsInterface {
  [brandName: string]: ModelInterface[]
}

const useTestRideVehicleSelection = () => {
  const addressData = JSON.parse(localStorage.getItem('addressData'))
  const navigate = useNavigate()

  const [models, setModels] = useState<ModelsInterface>({})
  const [selectedBrand, setSelectedBrand] = useState<string>('')
  const [selectedModel, setSelectedModel] = useState<ModelInterface>({
    vehicleId: '',
    losVehicleId: '',
    variant: '',
    displayName: '',
    displaySubText: '',
    brandLogo: '',
    image: '',
    primaryText: '',
    value: '',
  })
  const [brandOptions, setBrandOptions] = useState([])
  const [modelOptions, setModelOptions] = useState([])
  const [showModels, setShowModels] = useState(false)
  const [showBrands, setShowBrands] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false)
  const [isSuccessModal, setIsSuccessModal] = useState(false)
  const [isPincodeLoading, setIsPincodeLoading] = useState(false)
  const [isUnavailabilityPincodeOpen, setIsUnavailabilityPincodeOpen] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  useEffect(() => {
    vehicleService
      .getVehicleDetails(addressData)
      .then((res) => {
        const data = res.data.data.vehicleMap
        const newOptions = []
        const modifiedModels = {}
        for (const brand in data) {
          modifiedModels[brand] = []
          for (const model in data[brand]) {
            for (const variant in data[brand][model]) {
              modifiedModels[brand].push({
                vehicleId: data[brand][model][variant].vehicleId,
                losVehicleId: data[brand][model][variant].losVehicleId,
                variant: brand + ' ' + model + ' ' + data[brand][model][variant].variant,
                displayName: data[brand][model][variant].displayName,
                displaySubText: data[brand][model][variant].displaySubText,
                brandLogo: data[brand][model][variant].brandLogo,
                image: data[brand][model][variant].image,
              })
            }
          }
          newOptions.push({
            primaryText: brand,
            value: brand,
            secondaryText:
              modifiedModels[brand].length > 1
                ? `${modifiedModels[brand].length} variants available`
                : `${modifiedModels[brand].length} variant available`,
          })
        }
        setBrandOptions([...newOptions])
        setModels(modifiedModels)
        localStorage.removeItem('selectedDealerId')
      })
      .catch((error) => {
        console.error(error)
      })
  }, [addressData?.city])

  useEffect(() => {
    const newModelOptions = []
    models[selectedBrand]?.forEach((model) => {
      newModelOptions.push({
        primaryText: model.displayName,
        subText: model.displaySubText,
        value: model.vehicleId,
        vehicleId: model.vehicleId,
        losVehicleId: model.losVehicleId,
      })
    })
    setModelOptions([...newModelOptions])
  }, [selectedBrand])
  
  useEffect(() => {
    if(selectedModel.vehicleId) {
      window.scroll({
        top: document.body.offsetHeight,
        left: 0,
        behavior: 'smooth'
      })
    }
  }, [selectedModel])

  useEffect(() => {
    if (isSuccessModalOpen) {
      setIsSuccessModal(true)
    }
    if(isSuccessModal && !isSuccessModalOpen) {
      const testRide = localStorage.getItem('test_ride')
      navigate(routes[testRide])
    }
  }, [isSuccessModalOpen])

  function handleBrandChange(option) {
    setSelectedBrand(option.primaryText)
    setSelectedModel({
      vehicleId: '',
      losVehicleId: '',
      variant: '',
      displayName: '',
      displaySubText: '',
      brandLogo: '',
      image: '',
      primaryText: '',
      value: '',
    })
  }

  function handleSelectVariant() {
    setShowBrands(false)
    setShowModels(true)
  }

  function handleModelChange(option) {
    setSelectedModel(option)
  }

  function handleVariantSelected() {
    setShowModels(false)
  }

  const onVehicleSubmit = async () => {
    setIsLoading(true)
    ReactGA.event({
      category: 'Test Ride Vehicle Selection Page',
      action: 'Brand and varient form',
      label: 'Brand and varient submitted',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    const data = {
      availability: true,
      city: addressData.city,
      customerName: null,
      loanApplicationId: null,
      losVehicleId: selectedModel.losVehicleId,
      pinCode: addressData.postalCode,
      selectedFor: 'TEST_RIDE',
      vehicleId: selectedModel.vehicleId,
    }
    try {
      const response = await vehicleService.saveVehicleDetails(data)
      const selectedVehicle = {
        id: response.data.data.cust_vehicle_details.id,
        brandLogo: response.data.data.vehicle_pricing_details.brandLogo,
        displayName: response.data.data.vehicle_pricing_details.displayName,
        displaySubText: response.data.data.vehicle_pricing_details.displaySubText,
        image: response.data.data.vehicle_pricing_details.image
      }
      localStorage.setItem('selectedVehicle', JSON.stringify(selectedVehicle))
      navigate(routes.SCHEDULE_RIDE)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  async function onPincodeSubmit(data) {
    ReactGA.event({
      category: 'Pincode Modal in vechicle selection page',
      action: 'Pincode availability',
      label: 'Pincode availability',
    })
    ReactGA.set({ timestamp: new Date().toISOString() })
    setIsPincodeLoading(true)
    try {
      const response = await AuthService.sendPincode(data.pinCode)
      const availabilityResponse = await vehicleService.getVehicleDetails(response?.data?.data)
      if (availabilityResponse.data.data.availability) {
        navigate(routes.TEST_RIDE_VEHICLE_SELECTION)
        localStorage.setItem('addressData', JSON.stringify(response?.data?.data))
        setIsUnavailabilityPincodeOpen(false)
      } else {
        setIsUnavailabilityPincodeOpen(true)
        localStorage.setItem('unavailableAddressData', JSON.stringify(response?.data?.data))
      }
      setIsPincodeLoading(false)
      setIsPincodeModalOpen(false)
    } catch (error) {
      console.log(error)
      setIsPincodeLoading(false)
    }  
  }

  return {
    brandOptions,
    showBrands,
    showModels,
    selectedModel,
    modelOptions,
    addressData,
    isLoading,
    isPincodeModalOpen,
    selectedBrand,
    isSuccessModal,
    isSuccessModalOpen,
    isPincodeLoading,
    isUnavailabilityPincodeOpen,
    onPincodeSubmit,
    setIsUnavailabilityPincodeOpen,
    setIsSuccessModalOpen,
    setIsPincodeModalOpen,
    onVehicleSubmit,
    setShowBrands,
    handleSelectVariant,
    handleBrandChange,
    setShowModels,
    handleVariantSelected,
    handleModelChange,
  }
}

export default useTestRideVehicleSelection
